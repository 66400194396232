import React, { useState } from "react";
import {
  CloseButton,
  OverlayStructure,
  Spinner,
} from "components/styles/smallComponents";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { productosAPI } from "API/productos";
import { MdAddAPhoto } from "react-icons/md";
import { hideOrShowEditPhotoOverlay } from "context/generalSlice";
import {
  Btn,
  MainHeader,
  Line,
  SubContainer,
} from "pages/admin/auxiliaries/OverlayStyledComp";

const LabelImg = styled.label`
  display: flex;
  flex-direction: column;
  font-size: var(--fontMed);
  cursor: pointer;
  & > * {
    font-size: 6rem;
  }
`;
const Input = styled.input`
  display: none;
`;
const ContainerImg = styled.div`
  position: relative;
  height: 250px;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const ChangePicOverlay = () => {
  const {
    user: { loading },
    general: { openEditPhoto },
  } = useSelector((state) => state);
  const { show, _id } = openEditPhoto;
  const dispatch = useDispatch();
  const [img, setImg] = useState("");

  async function handleSubmit(e) {
    e.stopPropagation();
    productosAPI.updateImg(dispatch, img, _id, setImg);
  }

  return (
    <OverlayStructure
      show={show}
      close={() => dispatch(hideOrShowEditPhotoOverlay({ show: false }))}
    >
      <MainHeader>
        <CloseButton
          f={(e) => {
            e.stopPropagation();
            setImg("");
            dispatch(hideOrShowEditPhotoOverlay({ show: false }));
          }}
        />
        Editar/cambiar foto
      </MainHeader>
      <Line />
      <SubContainer>
        {img ? (
          <ContainerImg>
            <Img src={URL.createObjectURL(img)}></Img>
            <CloseButton
              f={(e) => {
                e.stopPropagation();
                setImg("");
              }}
            />
          </ContainerImg>
        ) : (
          <LabelImg htmlFor="uploadNewPic" onClick={(e) => e.stopPropagation()}>
            <MdAddAPhoto />
            Seleccioná la imagen.
            <Input
              as="input"
              type="file"
              id="uploadNewPic"
              value={img}
              onChange={(e) => {
                setImg(e.target.files[0]);
              }}
            ></Input>
          </LabelImg>
        )}
      </SubContainer>
      <Line />
      <SubContainer flexIt>
        <Btn
          danger
          onClick={(e) => {
            e.stopPropagation();
            setImg("");
            dispatch(hideOrShowEditPhotoOverlay({ show: false }));
          }}
        >
          Cancelar
        </Btn>
        <Btn onClick={handleSubmit}>{loading ? <Spinner /> : "Confirmar"}</Btn>
      </SubContainer>
    </OverlayStructure>
  );
};
