import { AiFillDelete, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeShowCarrito } from "context/generalSlice";
import { carritoController } from "utils/carritoControllers";
import { PromoDisplay } from "components/Single/SingleCartItem";
import { PriceComponent } from "components/Single/SingleProducto";
import {
  BtnLinked,
  CloseButton,
  ScrollStyles,
} from "components/styles/smallComponents";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";

export const GreyOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.overlay};
  display: flex;
  justify-content: end;
  transition: 0.3s;
  z-index: 500;
  opacity: ${(props) => (props.show ? "1" : "0")};
  pointer-events: ${(props) => !props.show && "none"};
`;
export const ContainerCarrito = styled.div`
  width: 500px;
  height: 100%;
  border: 5px solid black;
  background-color: ${(props) => props.theme.body};
  position: relative;
  transition: 0.3s;
  padding: 40px 0 10px 10px;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(400vw)")};
  display: flex;
  flex-direction: column;
  font-size: var(--fontBig);
  @media (max-width: 600px) {
    font-size: var(--fontMed);
  }
`;

export const ReactIcon = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: var(--fontMed);
  &:disabled {
    cursor: not-allowed;
    color: grey;
  }
`;
export const Header = styled.div`
  text-align: center;
  padding-bottom: 10px;
`;
export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  gap: 5px;
  ${ScrollStyles}
`;
export const SingleItem = styled.div`
  display: flex;
  gap: 10px;
`;
export const ImgContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: 110px;
`;
export const Center = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
`;
export const ProductoNombre = styled.div`
  font-size: var(--fontSmall);
`;
export const ReactIconDelete = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Controllers = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;
  font-size: var(--fontSmall);
`;
export const Quantity = styled.div``;
export const Precio = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
`;
export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding-top: 10px;
`;

export const CarritoOverlay = () => {
  const {
    user: { carrito },
    general: { showCarrito },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <GreyOverlay
      id="greyOverlay"
      show={showCarrito}
      onClick={(e) => {
        if (e.target?.id === "greyOverlay") {
          dispatch(changeShowCarrito());
        }
      }}
    >
      <ContainerCarrito show={showCarrito}>
        <CloseButton f={() => dispatch(changeShowCarrito())} />
        <Header>MI COMPRA</Header>
        <ContainerItems>
          {carrito.length > 0 ? (
            carrito.map((i) => (
              <SingleItem key={i._idTamaño}>
                <ImgContainer>
                  {/* Si esta en PROMO mostramos el cartelito */}
                  {i?.promocion && (
                    <>
                      <PromoDisplay>{i.descuento}% OFF</PromoDisplay>
                    </>
                  )}
                  <Img src={i.img} />
                </ImgContainer>
                <Center>
                  <ProductoNombre>{`${i.nombre} ${i.tamaño}ml`}</ProductoNombre>
                  <Controllers>
                    <ReactIcon
                      onClick={() =>
                        carritoController.addCart(i, dispatch, carrito)
                      }
                      disabled={i.qty === i.stock}
                    >
                      <AiOutlinePlus />
                    </ReactIcon>
                    <Quantity>{i.qty}</Quantity>
                    <ReactIcon
                      onClick={() =>
                        carritoController.addCart(i, dispatch, carrito, -1)
                      }
                    >
                      <AiOutlineMinus />
                    </ReactIcon>
                  </Controllers>
                  <PriceComponent {...i} />
                  {/*     <Precio>${(i.precio * i.qty).toLocaleString("es-AR")}</Precio> */}
                </Center>
                <ReactIconDelete
                  onClick={() => carritoController.deleteItemCart(i, dispatch)}
                >
                  <AiFillDelete />
                </ReactIconDelete>
              </SingleItem>
            ))
          ) : (
            <>
              <br />
              <h3>No hay productos en el carrito</h3>
              <br />
            </>
          )}
        </ContainerItems>
        <Total>
          <span>Total</span>
          <span>${carritoController.getTotal(carrito)}</span>
        </Total>
        <BtnLinked
          to={
            carrito.length === 1 //Si hay 1 solo prod directo al checkout
              ? `${FRONTEND_ENDPOINTS.CHECKOUT}#${HASH_LINKS.TOP}`
              : `${FRONTEND_ENDPOINTS.CARRITO}#${HASH_LINKS.TOP}`
          }
          onClick={() => dispatch(changeShowCarrito())}
        >
          Iniciar compra
        </BtnLinked>
      </ContainerCarrito>
    </GreyOverlay>
  );
};
