import { HASH_LINKS } from "constants";
import { BACKEND_ENDPOINTS, FRONTEND_ENDPOINTS } from "constants";
import {
  clearError,
  startAction,
  successConfirmedOrder,
  successUpdateCompra,
} from "context/userSlice";
import { dispatchNotification } from "utils/dispatchNotification";
import { axiosPOSTLogin } from "./axios";
import { errorHandler } from "./errorHandler";

export const comprasAPI = {
  getProv: function () {
    return axiosPOSTLogin.get(BACKEND_ENDPOINTS.COMPRAS + "/enum");
  },

  //ESTE TIENE Q VENIR SI O SI CON IMG POPULATED
  //creo q quedo bien
  getSingleCompra: async function (_id, dispatch) {
    //el Loading aca lo manejo inidividualmente en SingleCompra component
    try {
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.COMPRAS}/${_id}`
      );
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "GET SINGLE COMPRA");
    }
  },

  /*-------------------------1ER PASO----------------------*/
  /*----El LSTORAGE lo borro con un useEffect en App.jsx, x eso le agregué la key borrar a comprar----*/
  confirmOrder: async function (dispatch, compra, navigate) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.post(
        BACKEND_ENDPOINTS.COMPRAS,
        compra
      );

      //VUELVE LA COMPRA PERO SOLO USA LA _ID
      // Todos los comentarios q siguen son viejos, los dejo xq capaz me sirva si quiero hacer un refurbish estupido

      //CHEQUEAR SI ES MP
      //Antiguamente mandaba el MP PrefId aca, lo voy a mandar en la prox page
      /*   if (data.MP) {
      } */
      /*    dispatch(setPreference(data.preferenceId));, capaz basta con poner el preferenceId en el query y listo */
      /*---necesito mandar el preference ahora, o podria esperar a hacer el API call en confirmed?*/
      //poner navigate en la func + mandar _id back
      navigate(
        `${FRONTEND_ENDPOINTS.dynamicConfirmed(data._id)}#${HASH_LINKS.TOP}`
      );
      //SI ES WIRETRANSFER REDIRIGIR A SUCCESS,ver si uso useNavigate o setStep (ver lo de abajo, mandar id payment, ya no ongoing purchase.En sí ongoingPurcha y payment_id son similares, pero quedaría mas prolijo, p/marcar el fin de la etapa checkout y limpiar el  VER SI PUEDO HACER LO MISMO CON MP, en la misma etapa, xq si tengo q diferencia preferiría capaz hacer la limpieza del  en otro momento.)
      /*2 opciones:
      -A: seguir usando mismo URL, con steps, el tema es q total ya no usaría el , x ende condicionalmente tendria q marcarle q use la data q me manda el back.
      -B: crear nueva pag, ahi puedo pasarle a total component la data ya depurada sin problema creo.Creo q tendria q agregar la preferenceId al global state 
      */
      // HACER UN DISPATCH PARA BORRAR YA EL ONGOING PURCHASE +el ? Si hago eso tendria q mandar ya el user actualizado con compras + un id del payment completed, el cual en success va a ir al backend y reenviar la info, asi puedo carga el resumen con las fotos
      //hace falta aunq sea hacer un dispatch si quiero cambiar el estado al loading, OJAZO
      // Borrar + ongoingPurchase+Update user con compra
      //preferenceId? sig etapa

      //TECNICAMENTE ESTOY MANDANDO EL ARRAY DE COMPRAS DEL COMPRADOR Y ACTUALIZANDO ESO, ademas de vaciar las compras
      dispatch(successConfirmedOrder(data._id));
    } catch (error) {
      errorHandler(error, dispatch, "POST CREATE_COMPRA");
    }
  },

  //usé la API call asi, xq, necesito individual loading
  updateCompraR: async function (compra) {
    return axiosPOSTLogin.put(
      `${BACKEND_ENDPOINTS.COMPRAS}/${compra?._id}`,
      compra
    );
  },

  updateCompra: async function (dispatch, compra) {
    dispatch(startAction());
    try {
      const { data } = await this.updateCompraR(compra);
      dispatch(successUpdateCompra(data));
    } catch (error) {
      errorHandler(error, dispatch, "PUT UPDATE_COMPRA");
    }
  },
  /*------------------------FINAL STEP---------------------*/
  /*------------------YA CONFIRME COMPRA Y Q QUIERO PAGAR CON WIRETRANSFER O MP, HAGO UN CALL AL BACK P/MANDAR INFO-*/
  confirmedPurchaseGetFinalData: async function (
    dispatch,
    _id,
    setPurchaseToDisplay
  ) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.post(
        `${BACKEND_ENDPOINTS.COMPRAS}/confirmed/${_id}`
      );

      setPurchaseToDisplay(data);
      dispatch(clearError());
    } catch (error) {
      errorHandler(error, dispatch, "GET BANK OR MP INFO FOR PAYMENT");
    }
  },

  /*------------------------ADMIN---------------------*/

  //ADMIN ROUTE, uso local state p/actualizar la compra, con un .then
  editStatusPago: async function (compra, statusPago, dispatch) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.put(
        `${BACKEND_ENDPOINTS.COMPRAS}/${compra?._id}/statusPago`,
        statusPago
      );
      dispatch(clearError());
      dispatchNotification(dispatch, "Se actualizó exitosamente.");
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "EDIT STATUS PAGO");
    }
  },

  getSalesData: async function (dispatch) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.COMPRAS}/sales`
      );
      dispatch(clearError());
      console.log(data, 666);
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "GET SALES DATA");
    }
  },

  getMonthlySales: async function (dispatch, date) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.COMPRAS}/sales/${date}`
      );
      dispatch(clearError());
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "GET MONTHLY SALES");
    }
  },
};
