import { BACKEND_ENDPOINTS } from "constants";
import { clearError, startAction, successLogin } from "context/userSlice";
import { axiosPOSTLogin } from "./axios";
import { errorHandler } from "./errorHandler";

export const profileAPI = {
  getProfile: async function (dispatch) {
    dispatch(startAction());

    try {
      const { data } = await axiosPOSTLogin.get(BACKEND_ENDPOINTS.PROFILE);

      dispatch(successLogin(data));
    } catch (error) {
      errorHandler(error, dispatch, "GET USER PROFILE");
    }
  },
  editName: function (body) {
    return axiosPOSTLogin.put(BACKEND_ENDPOINTS.PROFILE, body);
  },
  editPwd: function (passwordsObj) {
    return axiosPOSTLogin.put(BACKEND_ENDPOINTS.PROFILE_EDIT_PWD, passwordsObj);
  },

  getAllClients: async function (dispatch) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.PROFILE}/all`
      );
      dispatch(clearError());
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "GET ALL USERS");
    }
  },
};
