import React from "react";
import styled, { css } from "styled-components";
import { AnimatedLink } from "components/styles/smallComponents";
import { WidthStyles } from "components/SmallComponents/Containers";
import { HashLink } from "react-router-hash-link";
import {
  EMAIL,
  FB_LINK,
  PHONE,
  RODRI_WEB,
  CONSUMIDOR_CABA,
  HASH_LINKS,
  FRONTEND_ENDPOINTS,
  WHATSAPP_LINK,
} from "constants";

const customFont = css`
  font-size: calc(var(--fontSmall) - 0.2rem);
`;
const Container = styled.footer`
  padding: 20px 10px;
  background-color: ${(props) => props.theme.body};
  border-top: 1px solid ${(props) => props.theme.border};
  color: ${(props) => props.theme.secondaryText};
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  ${customFont}
`;
const WidthWrapper = styled.div`
  ${WidthStyles}
  width: 100%;
  font-size: calc(var(--fontSmall) - 0.1rem);
`;
/*  intenté usar el AnimatedLink directo, pero react-router-dom no me dejaba linkear externamente*/
const Btn = styled(AnimatedLink)`
  font-size: inherit;
`;

export const FlexIt = styled.div`
  margin-top: 30px;
  border-top: 3px solid ${(props) => props.theme.border};
  padding: 40px 10px;
  display: flex;
  gap: 20px;
  justify-content: space-around;
  @media (max-width: 550px) {
    flex-direction: column;
    padding: 40px 10px;
  }
`;
export const Img = styled.img`
  width: calc(var(--fontLarge) + 3rem);
  height: 100%;
  border-radius: 10px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fontSmall);
`;
const Title = styled.h3`
  text-transform: uppercase;
  padding-bottom: 7px;
  font-size: calc(var(--fontSmall) - 0.1rem);
  & ~ * {
    ${customFont}
  }
`;
const RedesSocialesContainer = styled.div`
  font-size: calc(var(--fontLarge) + 0.5rem);
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Logo = styled.img`
  width: var(--fontLarge);
  border-radius: 50%;
`;
const Small = styled.div`
  font-size: calc(var(--fontSmall) - 0.3rem);
  text-transform: uppercase;
  cursor: pointer;
`;

export const Footer = () => {
  return (
    <>
      <WidthWrapper>
        <FlexIt>
          <Img alt="perfumeriamundial logo" src="/img/logo.ico" />
          <Column>
            <div>
              <Title>Redes sociales</Title>
              <RedesSocialesContainer>
                <a href={FB_LINK} target="_blank" rel="noopener noreferrer">
                  <Logo src="/img/fb.png" />
                </a>
                <a
                  href={WHATSAPP_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src="/img/whats.png" />
                </a>
              </RedesSocialesContainer>
            </div>
            <div>
              <Title>
                <HashLink
                  to={`${FRONTEND_ENDPOINTS.CONTACT}#${HASH_LINKS.TOP}`}
                >
                  Formulario de contacto
                </HashLink>
              </Title>
            </div>
          </Column>
          <Column>
            <div>
              <Title>Teléfono</Title>
              <div>+54 {PHONE}</div>
            </div>
            <div>
              <Title>E-mail</Title>
              <div>{EMAIL}</div>
            </div>
            <Small
              onClick={() => {
                window.open(CONSUMIDOR_CABA, "_blank");
              }}
            >
              Consumidor / CABA
            </Small>
          </Column>
        </FlexIt>
      </WidthWrapper>
      <Container id="FOOTER">
        <WidthWrapper>
          ©2022 Diseño web
          <Btn
            as="button"
            onClick={() => {
              window.open(RODRI_WEB, "_blank") ||
                window.location.replace(RODRI_WEB);
            }}
          >
            Rodrigo Hernán López
          </Btn>
        </WidthWrapper>
      </Container>
    </>
  );
};
