import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AiFillCaretDown, AiOutlineLoading } from "react-icons/ai";
import { comprasAPI } from "API/compras";
import { SingleCheckOutItem } from "../../../components/Single/SingleCheckOut";
import { BtnLinked } from "components/styles/smallComponents";
import { FRONTEND_ENDPOINTS } from "constants";
import { getPriceAndDecimalsInTheSameDiv } from "utils/mathFunctions";
import { EditCompraOverlay } from "pages/UserProfile/auxiliaries/EditCompraOverlay";
import CompraAdditionalInfo from "./CompraAdditionalInfo";
import { useRef } from "react";
import { STATUS_PAGO } from "constants";
import { errorHandler } from "API/errorHandler";
import { successUpdateCompra } from "context/userSlice";
import { dispatchNotification } from "utils/dispatchNotification";
import EditPaymentAdmin from "./EditPaymentAdmin";

export const SingleContainer = styled.tr`
  gap: 5px;
  padding: 10px 5px;
  transition: 0.2s;
  text-align: center;
  font-size: calc(var(--fontSmall) - 0.2rem);
  height: 50px;
  &:hover {
    background-color: ${(props) => props.theme.border};
  }
`;
export const AdditionalInfoContainer = styled(SingleContainer)`
  display: ${(props) => (props.show ? "table-row" : "none")};
  //p/q no tome el hover color el singleContainer
  &:hover {
    background-color: ${(props) => props.theme.body};
  }
  background-color: ${(props) => props.theme.body};
`;
export const Col = styled.td`
  padding: 0 2px;
  /* icono q rota p/abrir AdditionalInfoContainer */
  &.icon {
    cursor: pointer;
    font-size: calc(var(--fontSmall) + 0.2rem);
    transition: 0.3s;
    transform: ${(props) => (props.rotate ? "rotate(180deg)" : "rotate(0deg)")};
    color: ${(props) => (props.rotate ? "inherit" : "var(--mainBlue)")};
  }
  &.bold {
    font-weight: bold;
  }
  cursor: ${({ status }) => (status ? "pointer" : undefined)};
  color: ${({ status }) =>
    status === "pendiente"
      ? "#7e7e0a"
      : status === "aprobado"
      ? "var(--mainGreen)"
      : status === "cancelado"
      ? "crimson"
      : "inherit"};
  & > .spinner {
    animation: spin 1s infinite linear;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
export const Full = styled.td``;
export const BtnContainer = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  & > * {
    width: 40%;
  }
  @media (max-width: 500px) {
    & > * {
      width: 45%;
    }
  }
`;
export const Cancelar = styled(BtnLinked)`
  color: ${(props) => props.theme.red};
  border: 1px solid ${(props) => props.theme.red};
  font-size: inherit;

  padding: 5px;

  &:hover {
    background-color: ${(props) => props.theme.red};
    color: white;
  }
`;
export const Editar = styled(BtnLinked)`
  color: ${(props) => props.theme.text};
  border: 1px solid grey;
  font-size: inherit;
  padding: 5px;
  &:hover {
    background-color: black;
    color: white;
  }
`;
export const FlexIt = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
  & div {
    flex: 1;
  }
`;
export const UserInfo = styled.div`
  font-size: var(--fontSmall);
  text-align: left;
  padding: 10px;
`;
/*esto lo uso solo p/mostrarle al admin datos del comprador  */
export const SingleCompra = ({
  dispatch,
  prod,
  user = false,
  setMonthlySales,
}) => {
  const [show, setShow] = useState(false);
  /* single compra es prod id pero populated, ver si puedo sacarlo, aunq con la modificacion del admin creo q lo voy a dejar asi */
  const [singleCompra, setSingleCompra] = useState(null);
  const [loading, setLoading] = useState(false);
  /* ------------------HAY 3 OVERLAY ACA-------------------- */
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const [openInfoOverlay, setOpenInfoOverlay] = useState(false);
  const [openAdminPayOverlay, setOpenAdminPayOverlay] = useState(false);
  /* ----------------SCROLL DOWN WHEN OPEN AND CALL FN------------------- */
  const scrollDownRef = useRef();

  const {
    formaDePago,
    createdAt,
    total,
    statusPago,
    /* productos, */
    _id,
  } = prod;
  async function openAndCall() {
    try {
      if (!show) {
        setLoading(true);
        setSingleCompra(await comprasAPI.getSingleCompra(_id, dispatch));
      }
      setShow((v) => !v);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function cancel() {
    if (
      window.confirm(
        "¿Estás seguro que deseas cancelar la compra? Esta acción es irreversible."
      )
    ) {
      setLoading(true);
      try {
        const { data } = await comprasAPI.updateCompraR({
          ...prod,
          statusPago: STATUS_PAGO.CANCELADO,
        });
        dispatch(successUpdateCompra(data));
        setShow(!show);
        dispatchNotification(dispatch, "Se actualizó exitosamente");
      } catch (error) {
        errorHandler(error, dispatch, "CANCELAR COMPRA EN SINGLECOMPRA");
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    scrollDownRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [show]);

  return (
    <>
      {openEditOverlay && (
        <EditCompraOverlay
          openEditOverlay={openEditOverlay}
          setOpenEditOverlay={setOpenEditOverlay}
          prod={prod}
        />
      )}
      {/* overlay con info adicional p/compras canceladas o acreditadas */}
      {openInfoOverlay && (
        <CompraAdditionalInfo
          setOpenInfoOverlay={setOpenInfoOverlay}
          openInfoOverlay={openInfoOverlay}
          compra={prod}
        />
      )}
      {/* overlay con info adicional p/compras canceladas o acreditadas */}
      {openAdminPayOverlay && (
        <EditPaymentAdmin
          openOrClose={setOpenAdminPayOverlay}
          isOpen={openAdminPayOverlay}
          compra={prod}
          setMonthlySales={setMonthlySales}
        />
      )}
      <SingleContainer onClick={openAndCall} disabled={loading}>
        <Col className="icon" rotate={show ? "true" : ""}>
          {loading ? (
            <AiOutlineLoading className="spinner" />
          ) : (
            <AiFillCaretDown />
          )}
        </Col>
        <Col>{new Date(createdAt).toLocaleString()}</Col>
        <Col>{formaDePago}</Col>
        <Col>
          $
          {total &&
            getPriceAndDecimalsInTheSameDiv({
              precio: total,
            })}
        </Col>
        <Col status={statusPago} className="bold">
          {statusPago}
        </Col>
      </SingleContainer>
      <AdditionalInfoContainer show={show} ref={scrollDownRef}>
        <Full colSpan={5} show={show}>
          {user?.admin && singleCompra && (
            <FlexIt>
              <UserInfo>
                <h2>Usuario</h2>
                <div>{singleCompra.usuarioId.nombre}</div>
                <div>{singleCompra.usuarioId.emailUsuario}</div>
              </UserInfo>
              <Cancelar
                as="div"
                onClick={() => setOpenAdminPayOverlay((v) => !v)}
              >
                EDITAR PAGO
              </Cancelar>
            </FlexIt>
          )}
          {singleCompra &&
            singleCompra.productos?.map((i, ind) => {
              return (
                /* a veces prodId es una id, otras veces viene populated */
                <div key={ind}>
                  <SingleCheckOutItem
                    {...i}
                    img={i.prodId.img}
                    marca={i.prodId.marca}
                  />
                </div>
              );
            })}

          <BtnContainer>
            {statusPago === "pendiente" ? (
              <>
                <FlexIt>
                  <Cancelar as="div" bc="inherit" onClick={cancel}>
                    CANCELAR
                  </Cancelar>
                  <Editar
                    as="div"
                    bc="inherit"
                    onClick={() => setOpenEditOverlay((v) => !v)}
                  >
                    INFO +
                  </Editar>
                </FlexIt>
                <BtnLinked
                  to={`${FRONTEND_ENDPOINTS.dynamicConfirmed(_id)}#`}
                  bc="var(--mainGreen)"
                  border="var(--mainGreenHover)"
                >
                  PAGAR
                </BtnLinked>
              </>
            ) : (
              <Editar
                as="div"
                bc="inherit"
                onClick={() => setOpenInfoOverlay((v) => !v)}
              >
                INFO +
              </Editar>
            )}
          </BtnContainer>
        </Full>
      </AdditionalInfoContainer>
    </>
  );
};
