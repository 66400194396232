import { createGlobalStyle, keyframes } from "styled-components";

export const animateOpacityFadeIn = keyframes`
  0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`;
export const animateTranslateX = keyframes`
  0% {
      transform: translateX(-50%);
           opacity: 0;
    }

    100% {
      transform: translateX(0);
           opacity: 1;
    }
`;
const GlobalStyle = createGlobalStyle`
* {
box-sizing: border-box;
outline: 0;
margin: 0;
padding: 0;
border: 0;
scroll-behavior:smooth;
}
:root {
  --mainBlue:#335ef0;
  --mainBlueHover:#1a49ee;
  --mainBlack:black;
  --mainGold:#bbbb00;
  /* --mainGreen:#1f9c; */
   --mainGreen:#1f9c35; 
   --mainGrey:gray;
  --mlGreen:#00a650;
  --mainGreenHover:#187829;
  --mainPink:#af1354;
 --mainOrange:#ff8000; 
/*    --mainPink:#ffa2a2;  */
--mainFuxia:#af1354;
--mainViolet:#8b0eab;
--fontSmall:1rem;
  --fontMed:1.2rem;
  --fontBig:1.4rem;
  --fontLarge:2rem;
}
@media (max-width:640px){
  :root{
   /*  --fontSmall:.9rem; */
    /* --fontMed:2rem; */
    --fontBig:1.2rem;
    --fontLarge:1.8rem;
  }
}
a{
  text-decoration:none;
  color:inherit;
}
body{  font-family: "Inter", sans-serif;
transition:.3s;
 background-color:${(props) => props.theme.body}; 
color:${(props) => props.theme.text}; 
/* ESTO ES P/Q EL FOOTER SE VAYA AL BOTTOM EN MODO FLEX */
height:100vh;/* con 100% no alcanza */
}
#root{ 
  height: 100%;
}
`;
export default GlobalStyle;
