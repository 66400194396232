import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { productosAPI } from "API/productos";
import { Left } from "pages/SingleProdPage/auxiliaries/Left";
import { Right } from "pages/SingleProdPage/auxiliaries/Right";
import styled from "styled-components";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import { findBiggerEspSize } from "utils/randomFunctions";
import { ContainerGral1500 } from "components/SmallComponents/Containers";
import { FRONTEND_ENDPOINTS } from "constants";

export const TopPart = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
  font-size: var(--fontSmall);
  color: ${(props) => props.theme.secondaryText};
`;
const FlexIt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  & > * {
    flex: 1;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
`;
export const SingleProductPage = () => {
  const [producto, setProducto] = useState(null);
  const [prodSizeShowing, setProdSizeShowing] = useState(); //contiene tamaño, stock, precio, promocion, descuento, _id
  const dispatch = useDispatch();
  const {
    user: { carrito, loading, error, user },
  } = useSelector((state) => state);
  const { id: prodId } = useParams();

  useEffect(() => {
    productosAPI.getSingleProd(dispatch, prodId, setProducto);
  }, [dispatch, prodId]);
  useEffect(() => {
    /* esta funcion la pasé a utils xq la usa tmb la SingleProducto en Main */
    producto && setProdSizeShowing(findBiggerEspSize(producto.esp));
  }, [producto]);
  return (
    <ContainerGral1500>
      <TopPart>
        <AnimatedLink to={FRONTEND_ENDPOINTS.HOME}>Inicio</AnimatedLink>
        <span>&#10097;</span>
        <span>
          {producto?.nombre} {prodSizeShowing?.tamaño}ml
        </span>
      </TopPart>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      {/* ----------------------LOADING STATE------------------------ */}
      {loading && (
        <div>
          <Spinner fz="5rem" h="30vh" />
        </div>
      )}
      {/* --------------------IZQ Y DCHA, CONTENIDO-------------------- */}
      {producto && (
        <FlexIt>
          <Left {...producto} />
          {/* si no hay prodSizeShowing no llamo a la funcion, p/q no me tire error al deestructurar dentro de Right */}
          {prodSizeShowing && (
            <Right
              originalProd={producto}
              prodSizeShowing={prodSizeShowing}
              setProdSizeShowing={setProdSizeShowing}
              carrito={carrito}
              dispatch={dispatch}
              user={user}
            />
          )}
        </FlexIt>
      )}
      <TopPart>
        <AnimatedLink to={FRONTEND_ENDPOINTS.HOME}>Inicio</AnimatedLink>
        <span>&#10097;</span>
        <span>
          {producto?.nombre} {prodSizeShowing?.tamaño}ml
        </span>
      </TopPart>
    </ContainerGral1500>
  );
};
