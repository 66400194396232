import { axiosPOSTLogin, axiosPRELogin } from "./axios";
import { errorHandler } from "./errorHandler";
import { BACKEND_ENDPOINTS } from "constants";

export const preguntasAPI = {
  getProdQuestions: async function (prodId, page) {
    return axiosPOSTLogin.get(
      `${BACKEND_ENDPOINTS.dynamicSingleProdPREGUNTAS(prodId)}?page=${page}`
    );
  },

  createPreg: async function (prodId, objInfo, dispatch) {
    try {
      const { data } = await axiosPRELogin.post(
        BACKEND_ENDPOINTS.dynamicSingleProdPREGUNTAS(prodId),
        objInfo
      );
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "POST CREATE PREGUNTA");
      return Promise.reject("error custom");
    }
  },

  //POST LOGIN because I need to check admin
  deletePreg: async function (pregID) {
    return axiosPOSTLogin.delete(
      BACKEND_ENDPOINTS.dynamicSingleProdPREGUNTAS(pregID)
    );
  },
  //POST LOGIN because I need to check admin
  updatePreg: async function (pregID, replyInput) {
    return axiosPOSTLogin.put(
      BACKEND_ENDPOINTS.dynamicSingleProdPREGUNTAS(pregID),
      replyInput
    );
  },

  getAdminAllPregs: async function (options) {
    return axiosPOSTLogin.get(
      BACKEND_ENDPOINTS.dynamicAdminGetAllPregs(options)
    );
  },
};
