import { WHATSAPP_LINK } from "constants";
import React from "react";
import "./LogoWhatsapp.css";
export const LogoWhatsapp = () => {
  return (
    <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
      <img id="whatsappLogo" className="show" src="/img/whatsapp.png" alt="" />
    </a>
  );
};
