import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "pages/main/auxiliaries/Carousel/Carousel";
import { SingleProducto } from "components/Single/SingleProducto";

import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { navHeight } from "components/Nav/Nav";
import { productosAPI } from "API/productos";
import { Pagination } from "components/Pagination/Pagination";
import { turnSearchParamsIntoObject } from "utils/randomFunctions";
import { LogoWhatsapp } from "./auxiliaries/Whatsapp/LogoWhatsapp";
import InfoDisplay from "./auxiliaries/InfoDisplay/InfoDisplay";
import { visitsAPI } from "API/visits";
import { HASH_LINKS } from "constants";
import { WidthStyles } from "components/SmallComponents/Containers";
import Filters2point0 from "components/Filters/Filter2.0";
import { Spinner } from "components/styles/smallComponents";
import Destacados from "./auxiliaries/Destacados/Destacados";

const DobleContenedor = styled.div`
  display: flex;
  gap: 8px;
  ${WidthStyles}
  padding: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const MainSection = styled.main`
  width: 100%;
`;
const ErrorNoProds = styled.section`
  min-height: 80vh;
  font-size: var(--fontMed);
`;
const ContainerSingleItems = styled.section`
  display: grid;
  min-height: 80vh;

  //si ponía auto-fit cuando hay un solo item en los resultados se estira full-width
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  //si no ponía esto, cuando hay un solo item en los resultados se estira full-height ya q los grid children tienen align-self:stretch. Intenté corregirlos con align-self start, y funca, pero paso a tener un 2do problema, cuando hay varios items estan asimetricos en la height y queda feo
  grid-template-rows: min-content;
  gap: 10px;

  /* clave sacarle el padding creo, q desvirtua todo */
  @media (max-width: 730px) {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  /* --------------MINI CARDS EN MODO RESPONSIVE------------- */
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    gap: initial;
    margin: -10px;
  }
`;

const AdditionalInfo = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: calc(var(--fontSmall) + 0.1rem);

  padding: 20px 0px;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
  }
`;
const TotalProds = styled.div``;
const IncreaseProdsPerPage = styled.div``;
const Select = styled.select`
  font-size: inherit;
  border: 1px solid ${(props) => props.theme.border};
  padding: inherit;
  margin-left: 5px;
  cursor: pointer;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.border};
  margin: 50px 0;
  padding-top: 20px;
`;
export const Main = () => {
  const { productos, totalProductos, loading } = useSelector(
    (state) => state.user
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const scrollDownRef = useRef();
  const mount = useRef(false);
  const dispatch = useDispatch();

  //VER SI PUEDO CONVERTIR ESTO EN SOLO UN USEEFFECT??? LO PUSE EN TAREA/APUNTES 2/6/22 DONE, rechequear q ande todo bien
  useEffect(() => {
    productosAPI.getAllProds(dispatch, searchParams);
  }, [dispatch, searchParams]);

  useEffect(() => {
    visitsAPI.saveVisit();
  }, []);

  const resultsPerPage = [18, 36];

  function handleChange(e) {
    setSearchParams({
      ...turnSearchParamsIntoObject(searchParams),
      limit: e.target.value,
      page: 1,
    });
  }

  //usé esto en vez de scrollToView porque necesita hacer un offset x el navbar
  function scrollToTargetAdjusted() {
    var element = scrollDownRef.current;
    if (!element) return;

    var headerOffset = Number(navHeight.substring(0, 2));
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  //cada vez q cambio los filtros, vuelvo a scrollear p/arriba p/mostrarle al comprador el ppio (uso el mount p/evitar mostrarle los prods on FIRST LOAD)
  useEffect(() => {
    if (mount?.current) {
      scrollToTargetAdjusted();
    }
    mount.current = true;
  }, [searchParams]);

  return (
    <>
      <Carousel />
      <LogoWhatsapp />
      <InfoDisplay />
      <Destacados />
      <DobleContenedor id={HASH_LINKS.PROD}>
        <Filters2point0 />
        <MainSection ref={scrollDownRef}>
          <AdditionalInfo>
            <TotalProds>Producto(s): {totalProductos}</TotalProds>
            <Pagination disappear={true} />
            <IncreaseProdsPerPage>
              Resultados por página
              <Select
                defaultValue={searchParams.get("limit") || resultsPerPage[0]}
                onChange={handleChange}
              >
                {resultsPerPage.map((i) => (
                  <option key={i}>{i}</option>
                ))}
              </Select>
            </IncreaseProdsPerPage>
          </AdditionalInfo>

          {productos?.length > 0 ? (
            <ContainerSingleItems id="containerProductosMain">
              {productos.map((i) => (
                <SingleProducto {...i} key={i._id} />
              ))}
            </ContainerSingleItems>
          ) : (
            <ErrorNoProds>
              {loading ? (
                <Spinner fz="var(--fontLarge)" />
              ) : (
                "Ocurrió un error con nuestro servidor. Refrescá la página para que podamos cargar correctamente los productos."
              )}
            </ErrorNoProds>
          )}

          <Bottom>
            <Pagination />
          </Bottom>
        </MainSection>
      </DobleContenedor>
    </>
  );
};
