import { visitsAPI } from "API/visits";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VisitsTable from "./VisitsTable";

import styled from "styled-components";
export const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  & > button {
    padding: 10px;
    border: 1px solid ${(props) => props.theme.border};
    cursor: pointer;
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.body};
    border-radius: 3px;
    min-width: 50px;
    &:hover {
      background-color: ${(props) => props.theme.blue};
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
export default function AdminVisits() {
  const dispatch = useDispatch();

  const [visits, setVisits] = useState([]);
  const [totalVisitas, setTotalVisitas] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    visitsAPI.getVisits(dispatch, page).then((data) => {
      setVisits(data?.visits);
      setTotalVisitas(data?.totalVisits);
      setTotalPages(data?.totalPaginatedPages);
    });
  }, [dispatch, page]);

  return (
    <div>
      <h2>Total visitas: {totalVisitas}</h2>

      <SimplePagination page={page} totalPages={totalPages} setPage={setPage} />
      <VisitsTable visits={visits} />
      <SimplePagination page={page} totalPages={totalPages} setPage={setPage} />
    </div>
  );
}

const SimplePagination = ({ page, totalPages, setPage }) => {
  return (
    <PaginationSection>
      <button disabled={page === 1} onClick={() => setPage(1)}>
        1
      </button>
      <button disabled={page - 1 === 0} onClick={() => setPage((v) => v - 1)}>
        Ant
      </button>
      <button
        disabled={page === totalPages}
        onClick={() => setPage((v) => v + 1)}
      >
        Sig
      </button>
      <button
        disabled={page === totalPages}
        onClick={() => setPage(totalPages)}
      >
        {totalPages}
      </button>
    </PaginationSection>
  );
};
