import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAPI } from "API/auth";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import {
  Bottom,
  Form,
  Input,
  InputControl,
  Label,
  Submit,
  SuccessRegister,
  changeForgottenPwdInputs,
  Header,
} from "./data";
import { useParams } from "react-router-dom";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";

export const ChangeForgottenPwd = () => {
  const { error, loading } = useSelector((state) => state.user);
  const { secretToken } = useParams();
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    contraseña: "",
    confirmaContraseña: "",
  });
  //useEffect al ppio si quiero rebotar a alguien?
  const [success, setSuccess] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();
    setSuccess(false);
    authAPI.changeForgottenPwd(dispatch, input, secretToken, setSuccess);
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Header>Generá tu nueva contraseña.</Header>
        <ErrorNotification show={error}>{error}</ErrorNotification>
        {success && <SuccessRegister>{success}</SuccessRegister>}
        {changeForgottenPwdInputs?.map((i) => {
          const { type, id, label } = i;

          return (
            <InputControl key={id}>
              <Label htmlFor={id}>{label}</Label>
              <Input
                as="input"
                type={type}
                id={id}
                placeholder={label}
                name={id}
                value={input[id]}
                onChange={(e) =>
                  setInput({ ...input, [e.target.name]: e.target.value })
                }
              ></Input>
            </InputControl>
          );
        })}
        <Submit disabled={loading}>
          {loading ? <Spinner /> : "Cambiar contraseña"}
        </Submit>
        <Bottom>Elegí tu nueva contraseña.</Bottom>
        <Bottom>
          <AnimatedLink to={`${FRONTEND_ENDPOINTS.LOGIN}#${HASH_LINKS.TOP}`}>
            Volver a inicio de sesión.
          </AnimatedLink>
        </Bottom>
      </Form>
    </>
  );
};
