//PROMO y NOSTOCK son checkbox, xq necesito poder checkear y deschequear (los radio btns si son unicos no pueden deschequearse una vez chequeados)
export const promoCbox = {
  //esta es la key q uso en el backend
  name: "promocion",
  value: "Promos",
};
export const noStockCbox = {
  //esta es la key q uso en el backend
  name: "noStock",
  value: "Productos sin stock",
};
export const destacadoCbox = {
  //esta es la key q uso en el backend
  name: "destacado",
  value: "Productos destacados",
};
export const grupoRadiosNoAdmin = [
  {
    header: "Formato",
    radioUnits: [
      { name: "formato", valueANDid: "individual" },
      { name: "formato", valueANDid: "tester" },
      { name: "formato", valueANDid: "estuche" },
    ],
    type: "radio",
  },
  {
    header: "Tipo de fragancia",
    radioUnits: [
      { name: "concentracion", valueANDid: "EDT", label: "Eau de toilette" },
      { name: "concentracion", valueANDid: "EDP", label: "Eau de parfum" },
      { name: "concentracion", valueANDid: "EDC", label: "Eau de cologne" },
    ],
    type: "radio",
  },
  {
    header: "Género",
    radioUnits: [
      { name: "sexo", valueANDid: "Masculino" },
      { name: "sexo", valueANDid: "Unisex" },
      { name: "sexo", valueANDid: "Femenino" },
    ],
    type: "radio",
  },
  {
    header: "Promoción",
    radioUnits: [
      {
        name: promoCbox.name,
        valueANDid: promoCbox.value,
        label: "Productos en oferta",
      },
    ],
    //a single radio btn cant be unchecked once checked,thats why I use a CBOX
    type: "checkbox",
  },
];
//al admin le agrego el NO STOCK
export const grupoRadiosAdmin = [
  ...grupoRadiosNoAdmin,
  {
    header: "Sin stock",
    radioUnits: [
      {
        name: noStockCbox.name,
        valueANDid: noStockCbox.value,
        label: "Productos sin stock",
      },
    ],
    //a single radio btn cant be unchecked once checked,thats why I use a CBOX
    type: "checkbox",
  },
  {
    header: "Destacados",
    radioUnits: [
      {
        name: destacadoCbox.name,
        valueANDid: destacadoCbox.value,
        label: "Productos destacados",
      },
    ],
    //a single radio btn cant be unchecked once checked,thats why I use a CBOX
    type: "checkbox",
  },
];
