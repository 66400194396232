import { useDispatch, useSelector } from "react-redux";
import { carritoController } from "utils/carritoControllers";
import { AiFillDelete, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import styled from "styled-components";
import { PriceComponent } from "./SingleProducto";
import { getTotalSingleItemWithDecimals } from "utils/mathFunctions";
const SingleItem = styled.tr`
  /* originalmente toda la table eran divs, por eso usé   display: flex;
  gap: 10px;
  align-items: center; */
`;
export const MainTd = styled.td``;
const Flex1 = styled.div`
  display: flex;
  gap: 5px;
`;
const ImgContainer = styled.div`
  position: relative;
  /* flex: 0 1 150px; */
  width: 130px;
  height: 110px;
  @media (max-width: 450px) {
    width: 80px;
  }
  @media (max-width: 350px) {
    width: 70px;
  }
`;
/* creo q este export lo uso en otros archivos */
export const PromoDisplay = styled.div`
  color: white;
  background-color: var(--mainBlack);
  font-size: calc(var(--fontSmall) + 0.1rem);
  top: 0;
  position: absolute;
  content: "";
  padding: 3px;
  border-radius: 1px;
  //originalmente tenía 1000, pero se superponía con el cellphone menu
  z-index: 230;
  /* --bajo la promo display p/q se  vea al lado de los precios en modo celu */
  @media (max-width: 600px) {
    top: initial;
    bottom: 0;
    font-size: var(--fontSmall);
  }
`;
const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`;
const Total = styled.td`
  font-size: 1.3rem;
  font-weight: 600;
  width: 100px;
  text-align: center;
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`;
const Center = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 10px;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;
const ColumnaCenter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--fontSmall);
`;
const ProductoNombre = styled.div``;

const Marca = styled.div``;
const ReactIconDelete = styled.td`
  width: 35px;
  cursor: pointer;
  color: ${(props) => props.theme.red};
  font-size: var(--fontMed);
  text-align: center;
  vertical-align: middle;
  transition: 0.3s;
  &:hover {
    color: #df4564;
  }
`;
export const ReactIcon = styled.button`
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    color: grey;
  }
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 2px;
`;
export const Controllers = styled.div`
  display: flex;
  gap: 20px;
  font-size: var(--fontSmall);
  /* esto es xq no usé table, y fijé width específica */
  width: 120px;
  justify-content: center;
  @media (max-width: 700px) {
    justify-content: start;
    width: fit-content;
  }
`;
export const Quantity = styled.div``;

export const SingleCartItem = (i) => {
  const dispatch = useDispatch();
  const { carrito } = useSelector((state) => state.user);

  return (
    <SingleItem>
      <MainTd>
        <Flex1>
          <ImgContainer>
            {/* Si esta en PROMO mostramos el cartelito */}
            {i?.promocion && (
              <>
                <PromoDisplay>{i.descuento}% OFF</PromoDisplay>
              </>
            )}
            <Img src={i.img} />
          </ImgContainer>
          <Center>
            <ColumnaCenter>
              <Marca>{i.marca}</Marca>
              <ProductoNombre>{`${i.nombre} ${i.tamaño}ml`}</ProductoNombre>
              {/* con esto hago q se ponga en column el price en modo celu */}
              <PriceComponent {...i} cart={true} />
            </ColumnaCenter>
            <Controllers>
              <ReactIcon
                onClick={() =>
                  /* paso true comoto argumento p/q no abra el carrito  */
                  carritoController.addCart(i, dispatch, carrito, 1, true)
                }
                disabled={i.qty === i.stock}
              >
                <AiOutlinePlus />
              </ReactIcon>
              <Quantity>{i.qty}</Quantity>
              <ReactIcon
                onClick={() =>
                  /* paso true comoto argumento p/q no abra el carrito  */
                  carritoController.addCart(i, dispatch, carrito, -1, true)
                }
              >
                <AiOutlineMinus />
              </ReactIcon>
            </Controllers>
          </Center>
        </Flex1>
      </MainTd>
      {/* ACA TENGO Q VER SI HAGO UNA FUNCION, no tiene en cuenta la PROMO  */}
      <Total>${getTotalSingleItemWithDecimals(i)}</Total>
      <ReactIconDelete
        onClick={() => carritoController.deleteItemCart(i, dispatch)}
      >
        <AiFillDelete />
      </ReactIconDelete>
    </SingleItem>
  );
};
