import { BtnPagar } from "components/styles/smallComponents";
import styled from "styled-components";

export const MainHeader = styled.h2`
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: inherit;
  /* evitar q el close btn s edescentre */
  padding: 30px 20px 20px 50px;
  text-transform: uppercase;
`;
export const Line = styled.div`
  background-color: ${(props) => props.theme.border};
  height: 1px;
`;

export const SubContainer = styled.div`
  padding: 20px;
  display: ${(props) => (props.flexIt ? "flex" : "block")};
  justify-content: space-between;
  font-size: calc(var(--fontSmall) - 0.2rem);
  gap: 10px;
  @media (max-width: 450px) {
    padding: 10px;
    display: block;
  }
`;

export const HeaderItem = styled(SubContainer)`
  border-bottom: 1px solid ${(props) => props.theme.border};
  letter-spacing: 1px;
`;

export const Btn = styled(BtnPagar)`
  background-color: ${(props) => (props.danger ? props.theme.red : "grey")};
  border: 1px solid ${(props) => props.danger && props.theme.red};
  &:hover {
    background-color: ${(props) => props.danger && props.theme.red};
  }
  font-size: inherit;
`;
