import { useRef } from "react";
import { authAPI } from "API/auth";
import { axiosPOSTLogin } from "API/axios";
import { BACKEND_ENDPOINTS, HEADERS_NAME } from "constants";
import { useDispatch } from "react-redux";
import setHeadersAndUser from "utils/setHeadersAndUser";
import { FAILED_REFRESH } from "constants";

export const useInterceptor = () => {
  const dispatch = useDispatch();
  useRef(
    axiosPOSTLogin.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        console.log("error en el interceptor");
        const previousRequest = error?.config;
        if (
          //no agregamos 401 ya q si el user actualiza la pag, automaticamente llamamos al refresh y le otorgamos nuevos tokens. Es decir q nunca voy a llegar a esta instancia sin los tokens
          error?.response?.status === 403 &&
          !previousRequest?.[FAILED_REFRESH] &&
          error.config.url !== BACKEND_ENDPOINTS.REFRESH
        ) {
          previousRequest[FAILED_REFRESH] = true;
          try {
            const { data } = await authAPI.refreshAPI();

            if (data?.accessToken) {
              previousRequest.headers[HEADERS_NAME] = data.accessToken;
              //setheaders and user
              setHeadersAndUser(dispatch, data);
            }

            return axiosPOSTLogin(previousRequest);
          } catch (error) {
            console.log("devolvi el error");
          }
        }
        console.log(
          "devolvemos el error y no se llama a la refreshAPI. Dejamos q el error handler se ocupe"
        );
        return Promise.reject(error);
      }
    )
  );

  return undefined;
};
