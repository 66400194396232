import { ScrollStyles } from "components/styles/smallComponents";
import styled from "styled-components";

export const FlexIt = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    gap: 10px;
  }
`;

export const Main = styled.main`
  flex: 6;
  font-size: calc(var(--fontMed) - 0.3rem);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  /* Main controla tanto a Mp como WireTransfer */
  @media (max-width: 700px) {
    font-size: calc(var(--fontSmall) - 0.1rem);
  }
`;
export const Aside = styled.aside`
  flex: 4;
`;

export const HeaderAside = styled.h2`
  font-size: var(--fontMed);
  padding: 20px 0;
  letter-spacing: 2px;
  font-weight: 500;
  @media (max-width: 600px) {
    padding: 5px;
  }
`;
export const TopAside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 50vh;
  ${ScrollStyles}
`;
export const BottomAside = styled.div`
  font-size: calc(var(--fontMed) - 0.3rem);
`;
export const Subtotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: 600;
`;
export const Envio = styled(Subtotal)`
  & > span:last-child {
    color: var(--mainGreen);
  }
`;
export const Total = styled(Subtotal)`
  font-weight: bold;
  font-size: calc(var(--fontMed) - 0.1rem);
  border-top: 1px solid ${(props) => props.theme.border};
  padding-top: 20px;
`;
