import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { LSTORAGE_KEY_USER } from "constants";
import { authAPI } from "API/auth";
import { Spinner } from "components/styles/smallComponents";

export const CheckStorage = () => {
  //esto pasarlo a intro

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    async function checkStorage() {
      try {
        const userStorage = JSON.parse(localStorage.getItem(LSTORAGE_KEY_USER));

        //tengo q llamar al refresh y luego setear headers y user (o sea q tengo q enviar el user desde el back)
        if (userStorage) {
          await authAPI.refreshMyTokenOnPageRefresh(dispatch);
        }
      } catch (error) {
        console.log(error, "parsing local storage");
      } finally {
        setLoading(false);
      }
    }

    user ? setLoading(false) : checkStorage();
  }, [dispatch, user]);

  return loading ? <Spinner fz="5rem" h="30vh" /> : <Outlet />;
};
