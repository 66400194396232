import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PriceComponent } from "components/Single/SingleProducto";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaTruck } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";
import { carritoController } from "utils/carritoControllers";
import { MainButton } from "components/styles/smallComponents";
import { Link } from "react-router-dom";
import { animateOpacityFadeIn } from "components/styles/general";
import { errorHandler } from "API/errorHandler";
import { productosAPI } from "API/productos";
import { dispatchNotification } from "utils/dispatchNotification";
import { changeShowCarrito } from "context/generalSlice";
import Preguntas from "./Preguntas";
import { baseStyles, BorderContainers, Titulo, Columna } from "./styled";

const FlexItToTheBottom = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Wrapper = styled.div`
  flex: 1;
`;
const Bottom = styled.div`
  border-top: 1px solid ${(props) => props.theme.border};
  padding: 10px 0;
  display: flex;
  gap: 3px;
`;

const Precio = styled.h3`
  ${baseStyles}
`;
const Promo = styled.div`
  ${baseStyles}
  border-radius:5px;
  background-color: var(--mainPink);
  width: max-content;
  padding: 10px;
  color: white;
`;
const Cantidad = styled.div`
  ${baseStyles}
`;
const Lit = styled.div`
  ${baseStyles}
  color: ${(props) => props.theme.red};
`;
const OutOfStock = styled.div`
  ${baseStyles}
  gap:5px;
  color: ${(props) => props.theme.red};
`;
const SizeSelector = styled.div`
  ${baseStyles}
`;
const EnviosGratis = styled.div`
  ${baseStyles}
  font-weight:300;
  letter-spacing: 2px;
  color: var(--mainGreen);
  position: relative;
  z-index: 0;
  padding: 10px 10px 10px 20px;
  margin: 30px 10px 10px;
  margin-right: auto;
  /*  VER ESTO SI LA CAGA */
  width: max-content;
  @media (max-width: 450px) {
    width: 80%;
    & :nth-child(1) {
      font-size: 2rem;
    }
  }
  /* el background con skew detras de Envios Gratis */
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    -moz-transform: skew(-15deg);
    -ms-transform: skew(-15deg);
    transform: skew(-15deg);
    background-image: linear-gradient(
      to left,
      ${(props) => props.theme.body} 0%,
      ${(props) => props.theme.secondaryBody} 100%
    );
  }
`;
const Marca = styled(Link)`
  ${baseStyles}
  text-transform:uppercase;
  font-weight: 500;
  color: ${(props) => props.theme.blue};
`;
export const Controllers = styled.div`
  display: flex;
  gap: 35px;
  justify-content: space-between;
  align-items: center;
`;
export const ReactIcon = styled.button`
  cursor: pointer;
  font-size: inherit;
  &:disabled {
    cursor: not-allowed;
    color: grey;
  }
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 2px 4px;
`;
export const Quantity = styled.div``;
export const AlreadyInCartText = styled.div`
  ${baseStyles}
  display:block;
  & > b {
    cursor: pointer;
  }
  & > b:hover {
    color: ${(props) => props.theme.blue};
  }
`;
export const TamañosSection = styled.div`
  padding: 10px 0 var(--fontMed);
`;
export const TamañoProdSizeShowing = styled.div`
  ${baseStyles};

  & > span {
    font-size: calc(var(--fontSmall) - 0.2rem);
    padding: 10px;
    background-color: ${(props) => props.theme.body};
    background-color: black;
    color: white;
    border-radius: 10px;
    /*--------- OPACITY ANIMATION --------*/
    opacity: 0;
    animation: ${animateOpacityFadeIn} 1s forwards;
  }
`;
export const TamañoIndividual = styled.div`
  border: ${(props) => (props.active ? "3px solid black" : "2px dotted black")};
  color: ${(props) => props.theme.text};
  cursor: pointer;
  height: calc(var(--fontLarge) + 1.1rem);
  width: calc(var(--fontLarge) + 1.1rem);
  font-size: calc(var(--fontSmall) - 0.2rem);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 3px solid black;
  }
`;
const AvisameContainer = styled.div`
  ${baseStyles};
  flex-direction: column;
  width: 100%;
  align-items: start;
  font-weight: 300;
`;
const AvisameText = styled.div``;
const AvisameInputsForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
`;
const InputAvisame = styled.input`
  flex: 1 1 25%;
  padding: 10px;
  /* no  aplico outline al input type submit */
  &[type="text"]:focus {
    outline: 1px solid ${(props) => props.theme.blue};
  }
  &[type="submit"]:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.blue};
    color: white;
  }
  &:focus::placeholder {
    color: rgb(175, 168, 175);
  }
`;

export const Right = ({
  originalProd,
  prodSizeShowing,
  setProdSizeShowing,
  dispatch,
  carrito,
  user,
}) => {
  const { nombre, esp, marca /*_id ,img ,  descripcion */ } = originalProd;
  const { /* precio, */ tamaño, promocion, descuento, stock } = prodSizeShowing;
  const found = carrito.find((i) => i._idTamaño === prodSizeShowing._id);
  const [addQtyToCart, setAddQtyToCart] = useState(1);
  const [indLoading, setIndLoading] = useState(false);
  useEffect(() => {
    setAddQtyToCart(!stock ? 0 : stock - found?.qty <= 0 ? 0 : 1);
  }, [stock, found?.qty]);

  const [notifyBuyerWhenRestocked, setNotifyBuyerWhenRestocked] = useState({
    Nombre: user?.nombre || "",
    Mail: user?.emailUsuario || "",
    Teléfono: "",
  });

  function changeBuyerInfo(e) {
    setNotifyBuyerWhenRestocked({
      ...notifyBuyerWhenRestocked,
      [e.target.name]: e.target.value,
    });
  }

  async function emailOutOfStock(e) {
    e.preventDefault();
    /* -------------------ERROR HANDLING------------------- */
    let errorString = "";
    loopAndProcessObj(notifyBuyerWhenRestocked, (key, value) => {
      if (!value) {
        errorString += `El campo '${key}' no puede estar vacío. `;
      }
    });
    if (errorString) {
      return errorHandler({ response: { data: errorString } }, dispatch);
    }
    const final = carritoController.processMyEspObject(
      originalProd,
      prodSizeShowing
    );
    /* ---------------------------------------------------- */
    try {
      setIndLoading(true);
      await productosAPI.sendEmailLackOfStock(notifyBuyerWhenRestocked, final);
      //SHOW SUCCESS MESSAGE
      dispatchNotification(dispatch, "Se envió el mail exitosamente.");
    } catch (error) {
      errorHandler(error, dispatch, "sendEmailLackOfStock");
    } finally {
      setIndLoading(false);
    }
  }

  function loopAndProcessObj(obj, cb) {
    return Object.entries(obj).map(([key, value]) =>
      cb(key, value)
    ); /* LOOPEAR EL OBJECT.entries array y armar 3 inputs + armar backend mail */
  }

  function addCart(originalProd, prodSizeShowing) {
    //cambio el nombre de la variable _id de la especificaciónSchema ya q sino se superpondría con la _id del Producto
    const final = carritoController.processMyEspObject(
      originalProd,
      prodSizeShowing
    );
    carritoController.addCart(final, dispatch, carrito, addQtyToCart);
  }

  return (
    <Columna>
      <BorderContainers>
        <Titulo>
          {nombre} de {prodSizeShowing?.tamaño}ml
        </Titulo>
        <FlexItToTheBottom>
          <Wrapper>
            {promocion && <Promo>PROMO {descuento}%</Promo>}
            {/* --------------- MARCA ---------------------------------- */}
            <Marca to={`/?marca=${marca}`}>{marca}</Marca>
            {/* --------------- PRECIO ---------------------------------- */}
            {stock > 0 && (
              <>
                <Precio>
                  Precio: <PriceComponent {...prodSizeShowing} />
                </Precio>
                {/*--------------- STOCK -------------------------------------*/}

                <Cantidad>
                  <b>Cantidad:</b>{" "}
                  {/* SI NO HAY STOCK TENGO Q MOSTRAR OTRA COSA */}
                  <Controllers>
                    <ReactIcon
                      onClick={() => setAddQtyToCart(addQtyToCart + 1)}
                      /* no alcanza addQtyToCart === stock, tengo q controlar q el stock del carrito + addQtyToCart === stock*/
                      /* final.stock + qty >= found?.qty */
                      disabled={
                        found
                          ? stock + addQtyToCart >= found.qty
                          : addQtyToCart === stock
                      }
                    >
                      <AiOutlinePlus />
                    </ReactIcon>
                    <Quantity>{addQtyToCart}</Quantity>
                    <ReactIcon
                      onClick={() => setAddQtyToCart(addQtyToCart - 1)}
                      disabled={addQtyToCart <= 1}
                    >
                      <AiOutlineMinus />
                    </ReactIcon>
                  </Controllers>
                </Cantidad>
                {found && (
                  <AlreadyInCartText>
                    Este producto ya se encuentra en{" "}
                    <b onClick={() => dispatch(changeShowCarrito())}>
                      tu carrito.
                    </b>
                  </AlreadyInCartText>
                )}
              </>
            )}
            {stock !== 0 && stock <= 3 && (
              <Lit>
                🔥🔥<b>¡Stock limitado!</b>🔥🔥
              </Lit>
            )}

            {/* SELECTOR TAMAños */}
            <TamañosSection>
              <TamañoProdSizeShowing>
                Tamaño: <span key={tamaño}>{tamaño} ml</span>
              </TamañoProdSizeShowing>
              <SizeSelector>
                {esp.map((i) => (
                  <TamañoIndividual
                    //CREO q no es necesario tampoco aca realizar cambios
                    onClick={() => setProdSizeShowing(i)}
                    active={i?.tamaño === tamaño}
                    key={i._id}
                  >
                    {i.tamaño}ml
                  </TamañoIndividual>
                ))}
              </SizeSelector>
            </TamañosSection>
            {!stock && (
              <OutOfStock>
                Este producto no está disponible en este momento.
              </OutOfStock>
            )}
          </Wrapper>
          <Bottom>
            {stock === 0 ? (
              <AvisameContainer>
                <AvisameText>
                  Quiero que me avisen cuando esté disponible
                </AvisameText>
                <AvisameInputsForm onSubmit={emailOutOfStock}>
                  {
                    /* LOOPEAR EL OBJECT.entries array y armar 3 inputs  */
                    loopAndProcessObj(
                      notifyBuyerWhenRestocked,
                      (key, value) => (
                        <InputAvisame
                          type="text"
                          placeholder={key}
                          onChange={changeBuyerInfo}
                          name={key}
                          value={value}
                          key={key}
                        />
                      )
                    )
                  }
                  <InputAvisame
                    type="submit"
                    value={indLoading ? "Enviando mail..." : "Enviar"}
                    disabled={indLoading}
                  />
                </AvisameInputsForm>
              </AvisameContainer>
            ) : (
              <div style={{ width: "100%" }}>
                <MainButton
                  fz="1.8rem"
                  fn={() => addCart(originalProd, prodSizeShowing)}
                >
                  Comprar
                </MainButton>
                {/* ENVIOS GRATIS A TODO EL PAIS */}
                <EnviosGratis>
                  Envíos gratis a todo el país <FaTruck />
                </EnviosGratis>
                <EnviosGratis>
                  Devolución gratis por 30 días{/* <GiReturnArrow /> */}
                  <RiArrowGoBackFill />
                </EnviosGratis>
              </div>
            )}
          </Bottom>
        </FlexItToTheBottom>
      </BorderContainers>
      <Preguntas originalProdID={originalProd?._id} />
    </Columna>
  );
};
