import { OverlayStructure } from "components/styles/smallComponents";
import React from "react";
import styled from "styled-components";
import ParseDescripcion from "utils/ParseDescripcion";
import { Btn, MainHeader } from "./OverlayStyledComp";

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--fontMed);
`;
const Textarea = styled.textarea`
  padding: 10px;
`;
const Button = styled(Btn)`
  padding: 5px;
`;
const FlexIt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default function EditDescriptionOverlay({
  handleChange,
  handleSubmit,
  descripcion,
  show,
  close,
}) {
  const volver = "Volver";
  const confirmar = "Confirmar";
  return (
    <OverlayStructure show={show} close={close}>
      <Container>
        <MainHeader>Editar descripcion</MainHeader>
        <Textarea rows={4} onChange={handleChange} name="descripcion">
          {descripcion}
        </Textarea>
        <h3>Resultado que verá el cliente:</h3>
        <ParseDescripcion descripcion={descripcion} />
        {/* -------------------SECCION BOTONES---------------------------- */}
        <FlexIt>
          <Button
            danger
            type="button"
            onClick={() => close((v) => !v)}
            /*             disabled={Indloading} */
            text={volver}
          >
            {volver}
          </Button>
          <Button
            onClick={handleSubmit}
            /* disabled={Indloading} */ text={confirmar}
          >
            {confirmar}
          </Button>
        </FlexIt>
      </Container>
    </OverlayStructure>
  );
}
