import React from "react";
import styled from "styled-components";

const HeaderTable = styled.tr`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.body};
`;
const Col = styled.th`
  font-size: calc(var(--fontSmall) - 0.2em);
  padding: 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
export default function HeaderTableCompras() {
  return (
    <thead>
      <HeaderTable>
        <Col></Col>
        <Col>Fecha</Col>
        <Col>Forma de pago</Col>
        <Col>Total</Col>
        <Col>Pago</Col>
      </HeaderTable>
    </thead>
  );
}
