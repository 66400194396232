import { useEffect, useState } from "react";
import styled from "styled-components";
import { FaTruck, FaCreditCard } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";
import { MdAttachMoney } from "react-icons/md";
import { ContainerGral1500 } from "components/SmallComponents/Containers";

const SHOW_CAROUSEL_CELLPHONE = 820;

export const ColouredBox = styled.div`
  background-color: ${(props) => props.theme.secondaryBody};
  display: flex;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
  padding: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media (max-width: ${SHOW_CAROUSEL_CELLPHONE}px) {
    /* esto es p/ q el primer item se muestre, sino el space-around pone el item del medio al ppio */
    justify-content: start;
    padding: 10px 0px var(--fontMed);
  }
`;
const Controller = styled.div`
  position: absolute;

  left: 0;
  right: 0;
  top: calc(100% - 25px);
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  gap: 15px;

  @media (min-width: ${SHOW_CAROUSEL_CELLPHONE}px) {
    display: none;
  }
`;
const Ball = styled.div`
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.border};
  /* avoid shrinking */
  min-width: var(--fontSmall);
  height: var(--fontSmall);
  cursor: pointer;
  background-color: ${(props) => (props.active ? props.theme.text : "inherit")};
`;
export const Item = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:nth-child(-n + 3) {
    border-right: 1px solid ${(props) => props.theme.border};
  }

  @media (max-width: ${SHOW_CAROUSEL_CELLPHONE}px) {
    transform: ${(props) => `translateX(-${100 * props.pos}%)`};
    padding: 10px;
    flex-shrink: 0;
  }
`;
export const ReactIcon = styled.div`
  font-size: 3rem;
  color: grey;
  @media (max-width: 450px) {
    font-size: var(--fontLarge);
  }
`;
export const Text = styled.div`
  & > div {
    font-size: var(--fontSmall);
    color: ${(props) => props.theme.secondaryText};
  }
  @media (max-width: 1000px) {
    font-weight: 300;
  }
  @media (max-width: 450px) {
    & > div {
      font-size: calc(var(--fontSmall) - 0.1rem);
    }
  }
`;
export const Title = styled.h2`
  text-transform: uppercase;
  @media (max-width: 1500px) {
    font-weight: 500;
  }
  font-size: var(--fontSmall);
`;
export default function InfoDisplay() {
  const [pos, setPos] = useState(0);

  useEffect(() => {
    const idInterval = setInterval(() => {
      pos === 3 ? setPos(0) : setPos((v) => v + 1);
    }, 5000);
    return () => {
      idInterval && clearInterval(idInterval);
    };
  }, [pos]);

  return (
    <ContainerGral1500>
      <ColouredBox>
        <Item pos={pos}>
          {" "}
          <ReactIcon>
            <FaTruck />
          </ReactIcon>
          <Text>
            <Title>Envíos gratis</Title>
            <div>Envíos gratis a todo el país</div>
          </Text>
        </Item>
        <Item pos={pos}>
          <ReactIcon>
            <MdAttachMoney />
          </ReactIcon>
          <Text>
            <Title>TRANSFERENCIA BANCARIA</Title>
            <div>Transferencia o depósito bancario</div>
          </Text>
        </Item>
        <Item pos={pos}>
          <ReactIcon>
            <FaCreditCard />
          </ReactIcon>
          <Text>
            <Title>MERCADO PAGO</Title>
            <div>Tarjeta de crédito/débito, pago fácil</div>
          </Text>
        </Item>
        <Item pos={pos}>
          <ReactIcon>
            <RiArrowGoBackFill />
          </ReactIcon>
          <Text>
            <Title>DEVOLUCIÓN GRATIS</Title>
            <div>Devolución gratis por 30 días</div>
          </Text>
        </Item>
        <Controller>
          <Ball onClick={() => setPos(0)} active={pos === 0} />
          <Ball onClick={() => setPos(1)} active={pos === 1} />
          <Ball onClick={() => setPos(2)} active={pos === 2} />
          <Ball onClick={() => setPos(3)} active={pos === 3} />
        </Controller>
      </ColouredBox>
    </ContainerGral1500>
  );
}
