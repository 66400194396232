import React from "react";
import { FcSalesPerformance } from "react-icons/fc";
import {
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiFillApple,
  AiOutlineLogout,
  AiOutlineClose,
  AiOutlineQuestion,
} from "react-icons/ai";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FRONTEND_ENDPOINTS } from "constants";
import { authAPI } from "API/auth";
import { useDispatch } from "react-redux";

const Aside = styled.aside`
  background-color: #2d2c2c;
  height: 100vh;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 130px;
  transition: 0.3s;
  transform: ${(props) => !props.show && "translateX(-200%)"};
`;

const AdminActions = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  letter-spacing: 1px;
`;
const SubMenu = styled.div`
  position: absolute;
  list-style: none;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  transform: translateX(-100%);
  z-index: -1;
  opacity: 0;
`;
const SingleActionBtn = styled.li`
  width: 100%;
  color: white;
  filter: brightness(0.8);
  cursor: pointer;
  svg {
    font-size: 2rem;
  }

  &:hover {
    filter: brightness(1);
    background-color: ${(props) => props.theme.overlay};
  }
  &:hover ${SubMenu} {
    transform: translateX(0);
    opacity: 1;
  }
  position: relative;
`;
const LinkIt = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 30px 10px;
  /*  width: 100%;
  height: 100%; */
`;

const MoreActions = styled(Link)`
  color: ${(props) => props.theme.text};
  border: 1px solid ${(props) => props.theme.border};
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.body};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.body};
  }
`;
export const SideBar = ({ showSideBar, setShowSideBar, asideBarRef }) => {
  const dispatch = useDispatch();

  return (
    <Aside ref={asideBarRef} show={showSideBar}>
      <AdminActions>
        <SingleActionBtn onClick={() => setShowSideBar((v) => !v)}>
          <LinkIt as="div">
            <AiOutlineClose />
            CERRAR
          </LinkIt>
        </SingleActionBtn>
        <SingleActionBtn>
          <LinkIt to={FRONTEND_ENDPOINTS.ADMIN_PREGUNTAS}>
            <AiOutlineQuestion />
            PREGUNTAS
          </LinkIt>
        </SingleActionBtn>
        <SingleActionBtn>
          <LinkIt to={FRONTEND_ENDPOINTS.ADMIN_SALES}>
            <FcSalesPerformance />
            VENTAS
          </LinkIt>
          {/*           <SubMenu>
            <MoreActions>RESUMEN</MoreActions>
          </SubMenu> */}
        </SingleActionBtn>
        <SingleActionBtn>
          <LinkIt to={FRONTEND_ENDPOINTS.ADMIN}>
            <AiOutlineShoppingCart />
            PRODUCTOS{" "}
          </LinkIt>
          <SubMenu>
            <MoreActions to={FRONTEND_ENDPOINTS.ADMIN_CREATE_PROD}>
              CREAR
            </MoreActions>
            <MoreActions to={FRONTEND_ENDPOINTS.ADMIN}>EDITAR</MoreActions>
          </SubMenu>
        </SingleActionBtn>
        <SingleActionBtn>
          <LinkIt to={FRONTEND_ENDPOINTS.ADMIN_CUSTOMERS}>
            <AiOutlineUser />
            CLIENTES{" "}
          </LinkIt>
          <SubMenu>
            <MoreActions to={FRONTEND_ENDPOINTS.ADMIN_CUSTOMERS}>
              REGISTRADOS
            </MoreActions>
            <MoreActions to={FRONTEND_ENDPOINTS.ADMIN_VISITS}>
              VISITAS
            </MoreActions>
          </SubMenu>
        </SingleActionBtn>
        <SingleActionBtn>
          <LinkIt to={FRONTEND_ENDPOINTS.ADMIN_MARCAS}>
            <AiFillApple />
            MARCAS
          </LinkIt>
        </SingleActionBtn>

        {/* -----------------LOGOUT ADMIN ------------------------ */}
        <SingleActionBtn onClick={() => authAPI.logout(dispatch)}>
          <LinkIt as="div">
            <AiOutlineLogout /> LOGOUT
          </LinkIt>
        </SingleActionBtn>
      </AdminActions>
    </Aside>
  );
};
