import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "context/store";
import GlobalStyle from "components/styles/general";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "components/styles/theme";

const Index = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Theme />
      </BrowserRouter>
    </Provider>
  );
};
const Theme = () => {
  const { letThereBeLight } = useSelector((state) => state.general);
  return (
    <ThemeProvider theme={letThereBeLight ? darkTheme : lightTheme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  );
};
ReactDOM.render(<Index />, document.getElementById("root"));
