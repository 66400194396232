import styled, { css } from "styled-components";

export const baseStyles = css`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: calc(var(--fontSmall) - 0.5rem) 0;
  margin: 0 10px;
`;

export const Columna = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fontSmall);
`;

export const BorderContainers = styled.div`
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
  background-color: ${(props) => props.theme.secondaryBody};
  display: flex;
  flex-direction: column;
  gap: var(--fontSmall);
  font-size: var(--fontSmall);
`;

export const Titulo = styled.h1`
  font-size: var(--fontMed);
  padding: 20px 10px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const FlexIt = styled.div`
  display: flex;
  gap: 5px;
  & > * {
    flex: 1;
  }
  @media (max-width: 520px) {
    flex-direction: ${(props) => !props.dontfold && "column"};
  }
`;

export const Form = styled.form`
  width: 100%;
`;
