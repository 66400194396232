import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  baseStyles,
  BorderContainers,
  Titulo,
  Columna,
  FlexIt,
  Form,
} from "./styled";
import { BsArrowReturnRight } from "react-icons/bs";
import { Line } from "pages/admin/auxiliaries/OverlayStyledComp";
import { BtnPagar, Spinner } from "components/styles/smallComponents";
import { useSelector } from "react-redux";
import { dispatchNotification } from "utils/dispatchNotification";
import { useDispatch } from "react-redux";
import { InputControl, Label, Input } from "pages/auth/data";
import { preguntasAPI } from "API/preguntas";
import { AiFillDelete } from "react-icons/ai";
import AdminReplyForm from "./AdminReplyForm";

const SinglePreguntaContainer = styled.div`
  width: 100%;
`;

const PregValue = styled.div`
  &::first-letter {
    text-transform: capitalize;
  }
  font-weight: bold;
  color: ${(props) => props.theme.secondaryText};
  padding-bottom: 5px;
`;
const FetchMoreQuestions = styled(PregValue)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const PregCreatedAt = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.grey};
`;
const SubHeader = styled.h3``;
const PregReply = styled.div`
  font-weight: 300;
`;
const PreguntasContainer = styled(Columna)`
  ${baseStyles}
  flex-direction: column;
  align-items: flex-start;
`;

const SubmitPregBtn = styled(BtnPagar)``;

const Textarea = styled(Input)`
  padding: 10px;
  width: 100%;
  font-size: inherit;
  border: 1px solid ${(props) => props.theme.border};
`;

const submitText = "Publicar";

const Preguntas = ({ originalProdID }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [preguntas, setPreguntas] = useState([]);
  const [indLoading, setIndLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [total, setTotal] = useState(0);
  const initialState = {
    value: "",
    email: user?.email || "",
    cellphone: "",
  };
  const [preguntaInput, setPreguntaInput] = useState(initialState);

  const handleUpdatePreg = (newPreg) => {
    setPreguntas((v) =>
      v.map((singlePreg) =>
        singlePreg._id === newPreg._id ? newPreg : singlePreg
      )
    );
  };

  //fetch questions on firstload + when user clicks fetch MORE questions
  useEffect(() => {
    //hacer un custom hook

    preguntasAPI
      .getProdQuestions(originalProdID, currentPage)
      .then(({ data: obj }) => {
        setPreguntas((pregArr) => [...pregArr, ...obj.preguntas]);
        setTotal(obj.total);
        setLimit(obj.limit);
      })
      .catch(() => {});
  }, [originalProdID, currentPage]);

  const handleChange = (e) => {
    setPreguntaInput({
      ...preguntaInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = (pregID) => {
    if (window.confirm(`Estás seguro que querés eliminar esta pregunta?`)) {
      preguntasAPI
        .deletePreg(pregID)
        .then(() =>
          setPreguntas((v) => v.filter((preg) => preg._id !== pregID))
        )
        .catch(() => {
          alert("error");
        });
    }
  };

  const handleSubmitPreg = (e) => {
    e.preventDefault();
    setIndLoading(true);
    preguntasAPI
      .createPreg(originalProdID, preguntaInput, dispatch)
      .then((newPreg) => {
        dispatchNotification(
          dispatch,
          "Te notificaremos a la brevedad la respuesta por mail."
        );
        setPreguntaInput(initialState);
        setPreguntas((v) => [newPreg, ...v]);
      })
      .catch(() => {})
      .finally(() => setIndLoading(false));
  };

  const textAreaRef = useRef();

  const handleTextAreaFocusScroll = () => {
    if (textAreaRef?.current) {
      textAreaRef?.current.focus();
      textAreaRef?.current.scrollIntoView();
    }
  };

  return (
    <BorderContainers>
      <Titulo>PREGUNTAS</Titulo>
      <PreguntasContainer>
        <Form onSubmit={handleSubmitPreg} style={{ width: "100%" }}>
          <Textarea
            ref={textAreaRef}
            required
            as="textarea"
            placeholder="Dejá una pregunta..."
            rows={4}
            value={preguntaInput.value}
            name="value"
            onChange={handleChange}
          ></Textarea>
          <FlexIt>
            <InputControl>
              <Label>Email *</Label>
              <Input
                as="input"
                type="email"
                placeholder="Email"
                required
                value={preguntaInput.email}
                onChange={handleChange}
                name="email"
              />{" "}
            </InputControl>
            <InputControl>
              <Label>Teléfono</Label>
              <Input
                as="input"
                placeholder="Teléfono"
                name="cellphone"
                value={preguntaInput.cellphone}
                onChange={handleChange}
              />{" "}
            </InputControl>
          </FlexIt>

          <SubmitPregBtn text={submitText} disabled={indLoading}>
            {indLoading ? <Spinner /> : submitText}
          </SubmitPregBtn>
        </Form>
        <Line style={{ width: "100%" }} />
        <SubHeader>Últimas preguntas realizadas</SubHeader>
        {preguntas.map((singlePreg) => {
          return (
            <SinglePreguntaContainer key={singlePreg._id}>
              <PregCreatedAt>
                {new Date(singlePreg.createdAt).toLocaleString()}
              </PregCreatedAt>
              <FlexIt dontfold>
                <PregValue>{singlePreg.value} </PregValue>
                {user?.admin && (
                  <AiFillDelete
                    style={{
                      flexBasis: "auto",
                      flexGrow: 0,
                    }}
                    onClick={() => handleDelete(singlePreg._id)}
                  />
                )}
              </FlexIt>
              {singlePreg.reply ? (
                <PregReply>
                  {" "}
                  <BsArrowReturnRight style={{ marginRight: "5px" }} />
                  {singlePreg.reply}
                </PregReply>
              ) : user?.admin ? (
                <>
                  <Line />
                  <FlexIt>
                    <InputControl>
                      <PregValue>Celular</PregValue>
                      <PregReply>{singlePreg?.cellphone || "---"}</PregReply>
                    </InputControl>
                    <InputControl>
                      <PregValue>Email</PregValue>
                      <PregReply>{singlePreg?.email}</PregReply>
                    </InputControl>
                  </FlexIt>
                  <AdminReplyForm
                    pregID={singlePreg._id}
                    handleUpdatePreg={handleUpdatePreg}
                  />
                </>
              ) : null}
            </SinglePreguntaContainer>
          );
        })}
        {currentPage * limit < total && (
          <FetchMoreQuestions onClick={() => setCurrentPage((v) => v + 1)}>
            Ver más preguntas
          </FetchMoreQuestions>
        )}
        {preguntas.length === 0 && (
          <FetchMoreQuestions onClick={handleTextAreaFocusScroll}>
            Aún no hay preguntas para el vendedor. Sé el primero en preguntar.
          </FetchMoreQuestions>
        )}
      </PreguntasContainer>
    </BorderContainers>
  );
};

export default Preguntas;
