import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { productosAPI } from "API/productos";
import {
  Col,
  ReactIcon,
  SingleRow,
  Input,
  RandomDiv,
  Promo,
  LittleInfo,
  PrecioOriginal,
  CheckBoxGroupEdits,
  /*   CheckBoxGroupEdits, */
} from "components/Admin/MiscelaneousComponents";
import { ImCross } from "react-icons/im";
import { GiPresent } from "react-icons/gi";
import { AiFillDelete, AiFillEdit, AiFillSave } from "react-icons/ai";
import { Spinner } from "components/styles/smallComponents";

import { showOrCloseNotification } from "context/generalSlice";
import { getPriceAndDecimalsInTheSameDiv } from "utils/mathFunctions";

function SingleTamañoAdmin({
  singleEspProd,
  prodId,
  isGroupEditing,
  /* ojo q esto es solo el parent Prod, no todo el array de prods */
  groupEdit,
  //mandle el change event handler desde el parentProd ya q la logica p/el setGroupEdit queda toda en el MAINPRODUCTDISPLAY.jsx,  va a ser + facil p/Ts dsp
  handleCboxChange,
}) {
  const { precio, stock, tamaño, _id, promocion, descuento } = singleEspProd;
  const [inputs, setInputs] = useState(singleEspProd);

  useEffect(() => {
    setInputs(singleEspProd);
  }, [singleEspProd]);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleEditInputChange(e) {
    if (e.target.name === "promocion") {
      //si estoy cortando la PROMO x default tmb saco el descuento
      //al final decidí sacar esto xq en el back me hago cargo directamente, y encima si apreto chequear y deschequear varias veces y finalmente me arrepiento, es molesto q no se mantenga el valor de desc q habia originalmente en el input
      /*       if (!e.target.checked) {
        return setInputs({
          ...inputs,
          [e.target.name]: e.target.checked,
          descuento: 0,
        });
      } */
      return setInputs({ ...inputs, [e.target.name]: e.target.checked });
    }
    //OJO Q ACA LO SETEO EN MODO NUMBER, esto es porque dsp cuando hago json.stringify en saveEdit, si el data type es string le agrega doble comillas y falla la comparacion
    return setInputs({ ...inputs, [e.target.name]: +e.target.value });
  }

  async function saveEdit() {
    let hasItChanged = false;
    for (const key in inputs) {
      if (JSON.stringify(singleEspProd[key]) !== JSON.stringify(inputs[key])) {
        hasItChanged = true;
      }
    }
    if (hasItChanged) {
      if (inputs.descuento > 100 || inputs.descuento < 0) {
        return dispatch(
          showOrCloseNotification({
            show: true,
            message: `El porcentaje de descuento debe estar entre los valores 0 y 100. ${inputs.descuento} no es un valor válido.`,
          })
        );
      }
      setLoading(true);
      await productosAPI.updateProd(dispatch, { esp: [inputs] }, prodId);
      setLoading(false);
      return setIsEditing(false);
    }
    setIsEditing(false);
  }

  async function deleteTamaño() {
    if (window.confirm("Estás seguro que querés eliminar el tamaño?")) {
      setLoading(true);
      await productosAPI.deleteTamaño(dispatch, _id);
      setLoading(false);
    }
  }

  return (
    <SingleRow isEditing={isEditing}>
      <Col className="multiedit" isGroupEditing={isGroupEditing}>
        {/* trabajar aca dsp la logica avanzada del multiedit */}
        <CheckBoxGroupEdits
          checked={
            groupEdit?.esp.find((i) => i._idTamaño === _id)?.checked || false
          }
          onChange={(e) => handleCboxChange(prodId, _id, e)}
        />
      </Col>
      {/* ----------------------1. PROMO COLUMN--------------------- */}
      <Col>
        <RandomDiv>
          {isEditing ? (
            <>
              <Input
                type="checkbox"
                value={inputs.promocion}
                checked={inputs.promocion}
                style={{ height: "2rem" }}
                onChange={handleEditInputChange}
                name="promocion"
              ></Input>
              {inputs.promocion && (
                <Input
                  type="number"
                  value={inputs.descuento}
                  onChange={handleEditInputChange}
                  name="descuento"
                  style={{ width: "60px", textAlign: "center" }}
                ></Input>
              )}
            </>
          ) : (
            <>
              {promocion ? (
                <Promo>
                  <GiPresent
                    style={{
                      color: "var(--mainFuxia)",
                      fontSize: "var(--fontMed)",
                    }}
                  />
                  <LittleInfo>{inputs.descuento}%</LittleInfo>
                </Promo>
              ) : (
                /* HTML p/ el "-" */
                <span>&#9866;</span>
              )}
            </>
          )}
        </RandomDiv>
      </Col>

      {/* ----------------------2. TAMAñO COLUMN--------------------- */}
      <Col>
        {isEditing ? (
          <Input
            type="number"
            value={inputs.tamaño}
            onChange={handleEditInputChange}
            name="tamaño"
          ></Input>
        ) : (
          `${tamaño} ml`
        )}
      </Col>

      {/* ----------------------3. PRECIO COLUMN--------------------- */}
      <Col className="precio">
        {isEditing ? (
          <Input
            type="number"
            value={inputs.precio}
            onChange={handleEditInputChange}
            name="precio"
          ></Input>
        ) : (
          <>
            <PrecioOriginal tachar={promocion}>
              {getPriceAndDecimalsInTheSameDiv({ precio }, true)}
            </PrecioOriginal>
            {promocion &&
              getPriceAndDecimalsInTheSameDiv({ precio, descuento, promocion })}
          </>
        )}
      </Col>

      {/* ----------------------4. STOCK COLUMN--------------------- */}
      <Col className="stock">
        {isEditing ? (
          <Input
            type="number"
            value={inputs.stock}
            onChange={handleEditInputChange}
            name="stock"
          ></Input>
        ) : stock ? (
          stock
        ) : (
          <ImCross style={{ color: "crimson" }} />
        )}
      </Col>

      {/* ----------------------5. EDIT/DELETE COLUMN--------------------- */}
      <Col className="actions">
        <div className="acciones">
          {loading ? (
            <ReactIcon>
              <Spinner fz="1.5rem" />
            </ReactIcon>
          ) : (
            <ReactIcon onClick={deleteTamaño}>
              <AiFillDelete />
            </ReactIcon>
          )}

          {loading ? (
            <ReactIcon>
              <Spinner fz="1.5rem" />
            </ReactIcon>
          ) : isEditing ? (
            <ReactIcon as="button" className="save" onClick={saveEdit}>
              <AiFillSave />
            </ReactIcon>
          ) : (
            <ReactIcon onClick={() => setIsEditing(!isEditing)}>
              <AiFillEdit />
            </ReactIcon>
          )}
        </div>
      </Col>
    </SingleRow>
  );
}

export default SingleTamañoAdmin;
