import { useEffect, useState } from "react";
import { comprasAPI } from "API/compras";
import styled from "styled-components";
import { BtnPagar } from "components/styles/smallComponents";
import { useSelector, useDispatch } from "react-redux";
import { addDatosCompra } from "context/userSlice";
import { rest, rows } from "./addressData";
import AddressInputLine from "components/SmallComponents/AddressInputLine";
import { validateAddressStep } from "utils/formValidation";
import { animateTranslateX } from "components/styles/general";

export const AnimateTranslateX = styled.div`
  /*--------- TRANSFORMX ANIMATION --------*/
  animation: ${animateTranslateX} 0.5s forwards;
`;
export const Row = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 600px) {
    display: block;
  }
`;
export const Label = styled.label`
  display: flex;
  flex: 1;
`;
export const Input = styled.input`
  font-size: inherit;
  padding: 10px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.border};
`;
export const InputControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const SelectProv = styled.select`
  cursor: pointer;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.border};
`;
export const OptionProv = styled.option``;
const SubmitBtn = styled(BtnPagar)`
  font-size: calc(var(--fontMed) + 0.1rem);
`;

export const AddressStep = () => {
  const {
    user: { compra },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [prov, setProv] = useState([]);

  const { datosEnvio } = compra;
  //x alguna razon spreading en un nuevo objeto no funciona al iniciar el state, averiguar
  const [inputsEnvio, setInputsEnvio] = useState(datosEnvio);
  const [errors, setErrors] = useState({});

  function changeInput({ target }) {
    setInputsEnvio({ ...inputsEnvio, [target.name]: target.value });
  }

  useEffect(() => {
    async function getProvEnums() {
      try {
        const { data } = await comprasAPI.getProv();
        setProv(data);
      } catch (error) {
        console.log(error);
      }
    }
    getProvEnums();
  }, []);

  function handleClick() {
    //TENGO Q HACER VALIDATION AHORA FORZADAMENTE

    const [validated, errorObj] = validateAddressStep(inputsEnvio);

    if (!validated) {
      return setErrors(errorObj);
    }
    dispatch(
      addDatosCompra({
        datosEnvio: inputsEnvio,
        step: compra.step + 1,
        borrar: false,
      })
    );
  }
  return (
    <AnimateTranslateX>
      {/* 1ST ROW ----------- NOMBRE Y APELLIDO -------------------*/}
      {/* 2ND ROW ----------- CALLE Y NUMERO -------------------*/}
      {rows.map((row, i) => {
        return (
          <Row key={i}>
            {row.map((i) => {
              return (
                <AddressInputLine
                  {...i}
                  key={i.id}
                  changeInput={changeInput}
                  inputsEnvio={inputsEnvio}
                  errors={errors}
                />
              );
            })}
          </Row>
        );
      })}

      {rest.map((i) => {
        const { id } = i;

        return (
          <AddressInputLine
            key={id}
            {...i}
            changeInput={changeInput}
            inputsEnvio={inputsEnvio}
            prov={prov}
            errors={errors}
          />
        );
      })}

      <InputControl>
        <SubmitBtn onClick={handleClick}>Siguiente</SubmitBtn>
      </InputControl>
    </AnimateTranslateX>
  );
};
