import { showOrCloseNotification } from "context/generalSlice";

export const dispatchNotification = (dispatch, msg) => {
  //SHOW SUCCESS MESSAGE
  dispatch(
    showOrCloseNotification({
      show: true,
      message: msg,
    })
  );
  setTimeout(() => dispatch(showOrCloseNotification({ show: false })), 2500);
  return;
};
