import { comprasAPI } from "API/compras";
import HeaderTableCompras from "pages/UserProfile/auxiliaries/HeaderTable";
import { SingleCompra } from "pages/UserProfile/auxiliaries/SingleCompra";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import styled from "styled-components";
export const DateSelectorContainer = styled.div`
  font-size: 1.3rem;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 80px;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.border};
`;
export const DateInput = styled.input`
  padding: 10px;
  font-size: inherit;
`;

export default function SalesPerMonth() {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 7));
  const [monthlySales, setMonthlySales] = useState([]);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    async function get() {
      setMonthlySales(await comprasAPI.getMonthlySales(dispatch, date));
    }
    get();
  }, [date, dispatch]);

  return (
    <div>
      <DateSelectorContainer>
        <b>VENTAS POR MES</b>
        <DateInput
          type="month"
          value={date}
          onChange={({ target }) => setDate(target.value)}
        ></DateInput>
      </DateSelectorContainer>
      <table style={{ width: "100%" }}>
        <HeaderTableCompras />
        <tbody>
          {monthlySales?.length > 0 &&
            monthlySales.map((i) => (
              <SingleCompra
                key={i?._id}
                prod={i}
                dispatch={dispatch}
                user={user}
                setMonthlySales={setMonthlySales}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
