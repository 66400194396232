import styled from "styled-components";
import { BtnLinked } from "components/styles/smallComponents";
import { carritoController } from "utils/carritoControllers";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";

export const Container = styled.div``;
export const TotalProd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: var(--fontBig);
  padding: 10px;
`;

const LinkMe = styled(BtnLinked)`
  font-size: calc(var(--fontBig) - 0.2rem);
`;
export const Total = ({ carrito }) => {
  return (
    <Container>
      <TotalProd>
        <span>Total</span>
        <span>${carritoController.getTotal(carrito)}</span>
      </TotalProd>

      <LinkMe
        to={`${FRONTEND_ENDPOINTS.CHECKOUT}#${HASH_LINKS.TOP}`}
        bc={"var(--mainGreen)"}
        border={"var(--mainGreenHover)"}
      >
        Ir a pagar
      </LinkMe>
    </Container>
  );
};
