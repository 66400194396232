import styled from "styled-components";
import { navHeight } from "components/Nav/Nav";
import { useState } from "react";
import BulkActionsModal from "./GroupEditActionPickerMiniModal";
import BulkEditOverlay from "./BulkEditOverlay";
const BulkDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: sticky;
  width: max-content;
  border: 1px solid ${(props) => props.theme.border};
  top: calc(5px + ${navHeight});
  bottom: 0;
  z-index: 5;

  background-color: ${(props) => props.theme.body};
  cursor: pointer;

  //en modo celu se superpone con filter section, lo tengo q mover a la dcha
  margin-left: auto;
  //separar el boton del borde dcho
  margin-right: 3px;
`;

const ActivateOrNotCboxMultiEdit = styled.div`
  padding: 10px 5px;
  &:hover {
    background-color: ${(props) => props.theme.border};
  }
`;

function BulkEditBtnAndOverlay({
  isGroupEditing,
  setIsGroupEditing,
  groupEdit,
}) {
  const [showBulkEditOverlay, setShowBulkEditOverlay] = useState(false);
  const [bulkActionType, setBulkActionType] = useState("");
  const openOrCloseBulkEditOverlay = () => {
    setShowBulkEditOverlay((v) => !v);
  };
  //
  return (
    <>
      <BulkEditOverlay
        show={showBulkEditOverlay} //
        close={openOrCloseBulkEditOverlay} //
        bulkData={groupEdit}
        type={bulkActionType} //
      />

      <BulkDiv>
        <ActivateOrNotCboxMultiEdit
          onClick={() => setIsGroupEditing((v) => !v)}
        >
          EDITAR MASIVAMENTE{" "}
        </ActivateOrNotCboxMultiEdit>
        {isGroupEditing && (
          <BulkActionsModal
            setBulkActionType={setBulkActionType}
            openOrCloseBulkEditOverlay={openOrCloseBulkEditOverlay}
          />
        )}
      </BulkDiv>
    </>
  );
}

export default BulkEditBtnAndOverlay;
