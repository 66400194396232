import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { productosAPI } from "API/productos";
import { confirmAction, showOrCloseNotification } from "context/generalSlice";
import {
  BtnPagar,
  CloseButton,
  OverlayStructure,
  Spinner,
} from "components/styles/smallComponents";

export const SubContainer = styled.div`
  padding: 20px;
  display: ${(props) => (props.flexIt ? "flex" : "block")};
  justify-content: space-between;
  font-size: 1.3rem;
  gap: 10px;
`;
export const Btn = styled(BtnPagar)`
  background-color: ${(props) => (props.danger ? "crimson" : "grey")};
  border: 1px solid ${(props) => props.danger && "crimson"};
  &:hover {
    background-color: ${(props) => props.danger && "crimson"};
  }
`;
export const Header = styled.h2`
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 30px 20px 20px 50px;
  text-transform: uppercase;
`;
export const Line = styled.div`
  background-color: ${(props) => props.theme.border};
  height: 1px;
`;
export const ConfirmAction = () => {
  const {
    general: { confirmá },
    user: { loading },
  } = useSelector((state) => state);
  const { message, _id } = confirmá;
  const dispatch = useDispatch();
  async function fulfillAction() {
    await productosAPI.deleteFullProd(dispatch, _id);
    dispatch(confirmAction({ show: false }));
    dispatch(
      showOrCloseNotification({
        show: true,
        message: "Producto eliminado exitosamente.",
      })
    );
  }
  return (
    <OverlayStructure show={confirmá}>
      <Header>
        <CloseButton f={() => dispatch(confirmAction({ show: false }))} />
        {message}
      </Header>
      <Line />
      <SubContainer>
        ¿Estás seguro que querés realizar esta acción?
      </SubContainer>
      <Line />
      <SubContainer flexIt>
        <Btn onClick={() => dispatch(confirmAction({ show: false }))}>
          Cancelar
        </Btn>
        <Btn danger onClick={fulfillAction}>
          {loading ? <Spinner fz="1.5rem" /> : "Confirmar"}
        </Btn>
      </SubContainer>
    </OverlayStructure>
  );
};
