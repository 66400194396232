/* VER TMB CARRITO CONTROLLERS Q TENGO UNA FUNCION Q SACA EL TOTAL DEL CARRITO */

/* SOLO DECIMALES */
export function getDecimals({ precio, descuento, promocion }, fullPrice) {
  //fullPrice means that I wanna ignore that the prod is in discount, and show the decimals of the full price.
  const num = fullPrice
    ? precio
    : promocion
    ? getPriceWithDiscount({ precio, descuento })
    : precio;

  if (num === Math.floor(num)) {
    return "00";
  }
  //agregué el roundUp xq si viene un numero tipo 16000.5555555556 quiero q vaya a 0.56
  const decimalPortion = roundUpIn5With2Decimals(num - Math.floor(num));
  const floatToInt = decimalPortion * 100;
  return floatToInt - (floatToInt - Math.floor(floatToInt));
  /*Js anda mal, si multiplicas 0.56 * 100, te tira "56.0000001", en vez de tirar "56" 
  return roundUpIn5With2Decimals(num - Math.floor(num)) * 100; */
}
/* SOLO EL PRECIO EN NROS ENTEROS */
export function getPriceWithoutDecimals(object) {
  return Math.floor(getPriceWithDiscount(object));
}
/*Regla de 3 simple p/ obtener el precio con descuento*/
export function getPriceWithDiscount({ precio, descuento }) {
  const pr = precio * 10;
  let numVal2 = descuento / 100;
  return roundUpIn5With2Decimals((pr - pr * numVal2) / 10);
}
/*----------------ROUND UP------------------------------*/
// no recuerdo xq usé esta funcion en vez de .toFixed o .toLocaleString() con las options
//rechequear dsp cual era el problema
export function roundUpIn5With2Decimals(num) {
  return Math.round(num * 100 + Number.EPSILON) / 100;
}
/*------------------PRECIO * CANTIDAD Q COMPRA EL USUARIO----------------------------*/
export function getTotalSingleItemWithDecimals(object) {
  const priceAfterDiscount = object.promocion
    ? getPriceWithDiscount(object)
    : object.precio;
  const final = (priceAfterDiscount * 1000 * object.qty) / 1000;
  return roundUpIn5With2Decimals(final);
}
/* PRECIO Y DECIMALES TODO JUNTO */
export function getPriceAndDecimalsInTheSameDiv(
  { precio, descuento, promocion },
  ignoreDisc
) {
  const num = ignoreDisc
    ? precio
    : promocion
    ? getPriceWithDiscount({ precio, descuento })
    : precio;
  return roundUpIn5With2Decimals(num).toLocaleString("es-AR");
}
