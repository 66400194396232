/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ContainerFormasPago,
  Img,
} from "pages/checkout/auxiliaries/steps/PaymentDetails";
import { CgScreen } from "react-icons/cg";
import { BottomTextConfirmed, TextConfirmed } from "./styleAndComponents";
import { Spinner } from "components/styles/smallComponents";
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.border};
  font-weight: 600;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 10px;
`;
export const ReactIcon = styled.div`
  font-size: 5rem;
  padding: 30px 0;

  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
`;

export const Title = styled.div``;
export const Bottom = styled.div`
  border-top: 1px solid ${(props) => props.theme.border};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MPBtn = styled.div`
  background-color: ${(props) => props.theme.body};
`;

const PROD_MP_PUBLIC_KEY = "APP_USR-5a9d2ee2-9f81-475c-9b84-b281b1405360";

export const PaymentMP = ({ preferenceId }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!preferenceId) {
      //VER SI LO REDIRIGO
      return;
    }
    const mp = new MercadoPago(PROD_MP_PUBLIC_KEY, {
      locale: "es-AR",
    });

    // Inicializa el checkout
    mp.checkout({
      preference: {
        id: preferenceId,
      },
      render: {
        container: ".cho-container", // Indica el nombre de la clase donde se mostrará el botón de pago
        label: "Pagar con Mercado Pago", // Cambia el texto del botón de pago (opcional)
      },
    });

    //loading a false
    setLoading(false);
  }, [preferenceId]);

  return (
    <>
      <ContainerFormasPago>
        <Top>
          <span>Pagar con Mercado Pago </span>
          <span>
            <Img src="/img/MP.png" alt="Opción de pago, Mercado Pago" />
          </span>
        </Top>
        <Main>
          <ReactIcon>
            <CgScreen />
          </ReactIcon>
          <Title>
            Hacé click en el botón de abajo. Se abrirá una ventana en donde
            podrás realizar el pago de forma segura. Podés abonar tanto con
            tarjeta de crédito o débito, así como en efectivo en locales de
            pago, por ej. Rapipago.
          </Title>
        </Main>
        <Bottom>
          {loading && <Spinner />}
          {preferenceId && <MPBtn className="cho-container"></MPBtn>}
        </Bottom>
      </ContainerFormasPago>
      {/* TEXT CONFIRMED y BOTTOMTEXTCONFIRMED SON COMPONENTES Q IMPORTE Q USO TANTO EN MP COMO EN WIRETRANSFER */}
      <TextConfirmed />
      <BottomTextConfirmed />
      {/*  */}
    </>
  );
};
