import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FRONTEND_ENDPOINTS } from "constants";
import { moverDcha, moverIzq } from "utils/sliderCarousel";
const ContainerCarousel = styled.section`
  position: relative;
  height: 70vh;
  overflow: hidden;
  @media (max-width: 770px) {
    height: 65vh;
  }
`;
const SlideShow = styled.div`
  display: flex;
  //no borrar sino se sobreestira
  height: 100%;
`;
const CarouselItem = styled(Link)`
  position: relative;
  min-width: 100%;
  display: flex;
`;
export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const ImgCont = styled(Link)`
  height: 100%;
  width: 100%;
  background-color: black;

  @media (max-width: 1000px) {
    display: ${(props) => props.disappear && "none"};
  }
`;

export const Control = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  right: ${(props) => (props.dcha ? "0" : undefined)};
  left: ${(props) => (props.dcha ? undefined : "0")};
  top: 0;
  bottom: 0;
  margin: auto calc(var(--fontMed) - 0.3rem);
  height: var(--fontLarge);
  width: var(--fontLarge);
  cursor: pointer;
  font-size: var(--fontMed);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Carousel = () => {
  const slideShow = useRef();

  useEffect(() => {
    const idInterval = setInterval(() => {
      moverIzq(slideShow);
    }, 5000);
    return () => {
      idInterval && clearInterval(idInterval);
    };
  }, []);
  return (
    <>
      <ContainerCarousel>
        <SlideShow ref={slideShow}>
          {/*  */}
          <CarouselItem as="div">
            <Img src="img/carousel/HotSale.jpeg" />
          </CarouselItem>
          <CarouselItem
            to={FRONTEND_ENDPOINTS.dynamicSingleProduct(
              "63324686a409c4bc3379ee44"
            )}
          >
            <Img src="img/carousel/CKdefy.jpeg"></Img>
          </CarouselItem>
          {/*   */}
          <CarouselItem
            to={FRONTEND_ENDPOINTS.dynamicSingleProduct(
              "6332360ea409c4bc3379ed63"
            )}
          >
            <Img src="img/carousel/CHbadboy.jpeg"></Img>
          </CarouselItem>
          <CarouselItem as="div">
            <ImgCont
              to={FRONTEND_ENDPOINTS.dynamicSingleProduct(
                "632e6a413b206ec3f3e1ff2d"
              )}
            >
              <Img src="img/carousel/phantom.jpeg"></Img>
            </ImgCont>
            <ImgCont disappear as="div">
              <Img src="/img/logo.jpeg"></Img>
            </ImgCont>
          </CarouselItem>

          <CarouselItem
            to={FRONTEND_ENDPOINTS.dynamicSingleProduct(
              "632e60ca3b206ec3f3e1fd87"
            )}
          >
            <Img src="img/carousel/myWayL.jpeg"></Img>
          </CarouselItem>
          <CarouselItem as="div">
            <Img src="img/carousel/alienL.jpeg"></Img>
          </CarouselItem>
          <CarouselItem as="div">
            <Img src="img/carousel/GaultierDivine.jpeg" />
          </CarouselItem>
        </SlideShow>

        <Control onClick={() => moverIzq(slideShow)}>
          {" "}
          <AiOutlineArrowLeft />
        </Control>
        <Control dcha onClick={() => moverDcha(slideShow)}>
          <AiOutlineArrowRight />
        </Control>
      </ContainerCarousel>
    </>
  );
};
