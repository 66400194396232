import React from "react";
import styled from "styled-components";
export const Direccion = styled.div``;
export const Ciudad = styled.div``;
export const CP = styled.div``;
export const NombreCompletoReceptor = styled.div`
  font-weight: bold;
`;

export default function DisplayAddress({ datosEnvio }) {
  return (
    <>
      <NombreCompletoReceptor>
        {`${datosEnvio?.nombreDestinatario} ${datosEnvio?.apellidoDestinatario}`}
      </NombreCompletoReceptor>
      <Direccion>{`${datosEnvio?.calle} ${datosEnvio?.nro} - ${datosEnvio?.depto}`}</Direccion>
      <Ciudad>{`${datosEnvio?.ciudad}, ${datosEnvio?.provincia}`}</Ciudad>
      <CP>{`CP: ${datosEnvio?.CP}`}</CP>
    </>
  );
}
