import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import {
  filterIfNoStockIsActive,
  getExcelDataFromProductos,
} from "utils/randomFunctions";
import { useSearchParams } from "react-router-dom";
import { productosAPI } from "API/productos";
import { errorHandler } from "API/errorHandler";
import { useDispatch } from "react-redux";
import { BtnPagar, Spinner } from "components/styles/smallComponents";
import styled from "styled-components";

const ExcelButton = styled(BtnPagar)`
  transition: 0.3s;
`;
const CSV = ({ totalProductos }) => {
  let [searchParams] = useSearchParams();
  const [prodExcel, setProdExcel] = useState([]);
  const csvShittyBtn = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  async function fetchAndExportExcel() {
    setLoading(true);
    try {
      const { data } = await productosAPI.getFullProdForExcel(searchParams);
      const maybeProdsWithoutStock = filterIfNoStockIsActive(
        data.productos,
        searchParams
      );
      setProdExcel(maybeProdsWithoutStock);
      csvShittyBtn?.current.link.click();
    } catch (error) {
      errorHandler(error, dispatch, "GET EXCEL DATA");
    } finally {
      setLoading(false);
    }
  }

  const text = `Exportar a Excel ${totalProductos} productos`;
  return (
    <div>
      <ExcelButton text={text} onClick={fetchAndExportExcel} disabled={loading}>
        {loading ? <Spinner /> : text}
      </ExcelButton>
      <CSVLink
        ref={csvShittyBtn}
        data={getExcelDataFromProductos(prodExcel)}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default CSV;
