import { productosAPI } from "API/productos";
import {
  Col,
  Input,
  ReactIcon,
  SingleRow,
} from "components/Admin/MiscelaneousComponents";
import { Spinner } from "components/styles/smallComponents";
import React, { useState } from "react";
import { AiFillDelete, AiFillSave } from "react-icons/ai";
import { useDispatch } from "react-redux";

const PotencialTamañoNuevo = ({
  potencialTamañoNuevo,
  handleChange,
  handleDelete,
  prodID,
}) => {
  //VER SI ACA AGREGO ALGO RELATIVO A LA PROMOCION/DESCUENTO, creo q no es necesario honestamente
  const { _id } = potencialTamañoNuevo;
  const newSizeData = ["tamaño", "precio", "stock"];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  async function handlePostNewTamaño(potencialTamañoNuevo) {
    try {
      setLoading(true);
      //podria dejar la id y lidiar con eso en el back, en ppio ahora, si no se la saco, me tira error del back
      const { _id, ...rest } = potencialTamañoNuevo;
      await productosAPI.updateProd(
        dispatch,
        {
          //tengo q mandarlo en un array sino creo q borrá todo, OJO
          esp: [rest],
        },
        prodID
      );

      handleDelete(_id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <SingleRow>
      {/* esta column es x el multi edit checkbox, ver q eventualmente capaz tenga q pasar un prop adicional p/saber si estoy en multiedit on o */}
      <Col />
      {newSizeData.map((i, ind) => {
        return (
          <Col key={i} className={i}>
            <Input
              min="0"
              autoFocus={!ind}
              type="number"
              value={potencialTamañoNuevo[i]}
              name={i}
              onChange={(e) => handleChange(e, _id)}
            ></Input>
          </Col>
        );
      })}

      <Col>
        <div className="acciones">
          {/* BORRAR POTENCIAL TAMAñO NUEVO */}
          <ReactIcon delete onClick={() => handleDelete(_id)}>
            <AiFillDelete />
          </ReactIcon>
          {/* GUARDAR NUEVO TAMAñO */}
          <>
            <ReactIcon
              as="button"
              className="save"
              onClick={() => handlePostNewTamaño(potencialTamañoNuevo)}
              disabled={loading}
            >
              {loading ? <Spinner fz="1.5rem" /> : <AiFillSave />}
            </ReactIcon>
          </>
        </div>
      </Col>
    </SingleRow>
  );
};

export default PotencialTamañoNuevo;
