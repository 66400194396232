import { Route, Routes } from "react-router-dom";
import { Main } from "pages/main/Main";
import { SingleProductPage } from "pages/SingleProdPage/SingleProductPage";
import { CheckStorage } from "components/Middle/CheckStorage";
import { CheckUser } from "components/Middle/CheckUser";
import { Carrito } from "pages/Carrito";
import { Login } from "pages/auth/Login";
import { Register } from "pages/auth/Register";
import { Contacto } from "pages/Contacto/Contacto";
import { UserProfile } from "pages/UserProfile/UserProfile";
import { CheckOut } from "pages/checkout/CheckOut";
import { Confirmed } from "pages/checkout/Confirmed";
import { useInterceptor } from "hooks/useInterceptor";
import { ForgotPwd } from "pages/auth/ForgotPwd";
import { ChangeForgottenPwd } from "pages/auth/ChangeForgottenPwd";
import {
  ContainerAdmin,
  OutletContainerGral1500,
} from "components/SmallComponents/Containers";
import { AdminMarcas } from "pages/admin/AdminMarcas";
import { CommonElements } from "components/Middle/CommonElements";
import { useEffect } from "react";
import { LSTORAGE_KEY_ONGOING_PURCHASE, LSTORAGE_KEY_CARRITO } from "constants";
import { useSelector, useDispatch } from "react-redux";
import { productosAPI } from "API/productos";
import { FRONTEND_ENDPOINTS } from "constants";
import { addDatosCompra } from "context/userSlice";
import AdminSales from "pages/admin/AdminSales";
import AdminClientes from "pages/admin/clients/AdminClientes";
import AdminPreguntas from "pages/admin/AdminPreguntas";
import AdminEditProducts from "pages/admin/EditProducts/AdminEditProducts";
import AdminCreateProd from "pages/admin/AdminCreateProd";
import AdminVisits from "pages/admin/clients/AdminVisits";

function App() {
  useInterceptor();

  const { carrito, compra } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  /* ------------------------CARRITO SECTION------------------------------------ */
  //ONLOAD check lstorage, call backend to update prices/stock and save carrito
  useEffect(() => {
    const maybe = localStorage.getItem(LSTORAGE_KEY_CARRITO);
    if (maybe) {
      const carritoFound = JSON.parse(maybe);
      if (carritoFound.length > 0) {
        productosAPI.checkCartOnRefresh(dispatch, carritoFound);
      }
    }
  }, [dispatch]);
  /* ----------------------------------------------------- */
  //OJO este useEffect tiene q ir en segundo lugar xq sino me borrar el carrito antes tener la oportunidad de actualizarlo
  useEffect(() => {
    //si el carrito esta vacio, eliminamos la key del LSTORAGE
    if (!carrito?.length) {
      return localStorage.removeItem(LSTORAGE_KEY_CARRITO);
    }
    localStorage.setItem(LSTORAGE_KEY_CARRITO, JSON.stringify(carrito));
  }, [carrito]);
  /* ------------------------------------------------------------------------------------ */

  useEffect(() => {
    const maybe = localStorage.getItem(LSTORAGE_KEY_ONGOING_PURCHASE);
    if (maybe) {
      const ongoingPurchase = JSON.parse(maybe);
      //ACTUALIZO EL STATE, al final no agrego preferenceId (ni aca ni en el store), xq alcanza con local state en Confirmed.jsx
      //eventualmente no guardar addressStep en storage, llamar al back
      dispatch(addDatosCompra(ongoingPurchase));
    }
  }, [dispatch]);
  //useEffect p/guardar en LOCAL STORAGE los datos de Payment y Address de la compra
  useEffect(() => {
    //ver como distingo eventualmente p/eliminar la COMPRA
    //terminé agregando una propiedad a compra q solo cambio en step 1 y al finalizar la compra

    if (compra.borrar) {
      localStorage.removeItem(LSTORAGE_KEY_ONGOING_PURCHASE);
      return;
    }
    localStorage.setItem(LSTORAGE_KEY_ONGOING_PURCHASE, JSON.stringify(compra));
  }, [compra]);

  return (
    <>
      <Routes>
        {/* CommonElements tiene: 
                          Nav, 
                          Notification (cartelito q aparece abajo a la izq), 
                          CarritoOverlay + 
                          FOOTER  */}
        <Route element={<CommonElements />}>
          <Route element={<OutletContainerGral1500 />}>
            <Route path={FRONTEND_ENDPOINTS.LOGIN} element={<Login />}></Route>
            <Route
              path={FRONTEND_ENDPOINTS.REGISTER}
              element={<Register />}
            ></Route>
            <Route
              path={FRONTEND_ENDPOINTS.FORGOT_PASSWORD}
              element={<ForgotPwd />}
            ></Route>
            <Route
              path={FRONTEND_ENDPOINTS.CHANGE_PASSWORD}
              element={<ChangeForgottenPwd />}
            ></Route>
          </Route>
          {/* -------PERSIST USER---------------- */}
          <Route element={<CheckStorage />}>
            <Route
              path={FRONTEND_ENDPOINTS.CONTACT}
              element={<Contacto />}
            ></Route>
            <Route path={FRONTEND_ENDPOINTS.HOME} element={<Main />}></Route>
            <Route
              path={FRONTEND_ENDPOINTS.SINGLEPRODUCT}
              element={<SingleProductPage />}
            ></Route>
            <Route
              path={FRONTEND_ENDPOINTS.CARRITO}
              element={<Carrito />}
            ></Route>
            {/* --------------------PRIVATE ROUTES---------------------- */}
            <Route element={<CheckUser admin={false} />}>
              {/* ---------------CHECKOUT ROUTES---------------------------- */}
              <Route
                path={FRONTEND_ENDPOINTS.CHECKOUT}
                element={<CheckOut />}
              ></Route>
              <Route
                path={FRONTEND_ENDPOINTS.CONFIRMED}
                element={<Confirmed />}
              ></Route>
              {/* --------------PROFILE USER------------------------------- */}
              <Route
                path={FRONTEND_ENDPOINTS.USER_PROFILE}
                element={<UserProfile />}
              ></Route>
              {/* ADMIN ROUTES */}
              <Route element={<CheckUser admin={true} />}>
                <Route element={<ContainerAdmin />}>
                  {/* EDIT PRODUCTOS */}
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN}
                    element={<AdminEditProducts />}
                  ></Route>
                  {/* CREATE PRODUCTOS */}
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_CREATE_PROD}
                    element={<AdminCreateProd />}
                  ></Route>
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_MARCAS}
                    element={<AdminMarcas />}
                  ></Route>
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_SALES}
                    element={<AdminSales />}
                  ></Route>
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_PREGUNTAS}
                    element={<AdminPreguntas />}
                  ></Route>
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_CUSTOMERS}
                    element={<AdminClientes />}
                  ></Route>
                  <Route
                    path={FRONTEND_ENDPOINTS.ADMIN_VISITS}
                    element={<AdminVisits />}
                  ></Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
