import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productosAPI } from "API/productos";
import { Table, TableHeader } from "components/Admin/MiscelaneousComponents";
import { ConfirmAction } from "components/Notification/ConfirmAction";
import { marcasAPI } from "API/marcas";
import { ChangePicOverlay } from "components/Admin/ChangePicOverlay";
import { Pagination } from "components/Pagination/Pagination";
import { useSearchParams } from "react-router-dom";
import MainProductDisplay from "./auxiliaries/MAINProductDisplay";
import { tableColumns } from "../data";
import styled from "styled-components";
import Filters2point0 from "components/Filters/Filter2.0";
import BulkEditBtnAndOverlay from "./BulkEdit/BulkEditButton";
import CSV from "./Excel/CSV";

const AdminContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const FlexIt = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;

  padding: 20px 0px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
    //centro la pagination
    & > :nth-child(2) {
      align-self: center;
    }
  }
`;
const GridToAllowSticky = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  //asigno a la table la entire width del grid
  & > :nth-child(3) {
    grid-column: 1 /-1;
  }
`;

export default function AdminEditProducts() {
  const { productos, totalProductos } = useSelector((state) => state.user);
  const [marcas, setMarcas] = useState([]);
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    marcasAPI.getMarcas(dispatch, setMarcas);
  }, [dispatch, setMarcas]);
  useEffect(() => {
    productosAPI.getAllProds(dispatch, searchParams);
  }, [dispatch, searchParams]);

  const setAllProductosCboxes = useCallback(() => {
    return productos.reduce((final, item) => {
      final.push({
        _id: item._id,
        checked: false,
        esp: item.esp.map((espItem) => ({
          _idTamaño: espItem._id,
          checked: false,
        })),
      });
      return final;
    }, []);
  }, [productos]);

  const [groupEdit, setGroupEdit] = useState(setAllProductosCboxes());

  useEffect(() => {
    setGroupEdit(setAllProductosCboxes());
  }, [productos, setAllProductosCboxes]);
  const [isGroupEditing, setIsGroupEditing] = useState(true);

  return (
    <>
      <ConfirmAction />
      <ChangePicOverlay />
      <AdminContainer>
        <Filters2point0 />
        <main style={{ width: "100%" }}>
          <FlexIt>
            <div>Producto(s): {totalProductos}</div>
            <Pagination />
            <div></div>
          </FlexIt>
          <GridToAllowSticky>
            {/* si ponia CSV y BulkBtn en un div individual con flexbox, no me iba a andar el sticky a lo largo de toda la table, si o si necesito un parent q incluya la table */}
            <CSV totalProductos={totalProductos} />

            <BulkEditBtnAndOverlay
              isGroupEditing={isGroupEditing}
              setIsGroupEditing={setIsGroupEditing}
              groupEdit={groupEdit}
            />

            <Table>
              <TableHeader
                allColumns={tableColumns}
                isGroupEditing={isGroupEditing}
                setGroupEdit={setGroupEdit}
                groupEdit={groupEdit}
              />
              <tbody>
                {productos.length > 0 &&
                  productos.map((i) => {
                    return (
                      <MainProductDisplay
                        key={i._id}
                        prod={i}
                        marcas={marcas}
                        isGroupEditing={isGroupEditing}
                        groupEdit={groupEdit}
                        setGroupEdit={setGroupEdit}
                      ></MainProductDisplay>
                    );
                  })}
              </tbody>
            </Table>
          </GridToAllowSticky>
        </main>
      </AdminContainer>
      <Pagination />
    </>
  );
}
