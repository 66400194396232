import styled from "styled-components";
import { AiOutlineMail, AiFillCreditCard } from "react-icons/ai";
import { CgScreen } from "react-icons/cg";
import { AnimatedLink, BtnPagar } from "components/styles/smallComponents";
import { useDispatch, useSelector } from "react-redux";
import { addDatosCompra } from "context/userSlice";
import { AnimateTranslateX } from "./AddressStep";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
export const ContainerFormasPago = styled.fieldset`
  border: 1px solid ${(props) => props.theme.border};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;
export const TitleFormasdePago = styled.legend`
  padding: 10px 0;
  font-size: calc(var(--fontMed) - 0.2rem);
  letter-spacing: 1px;
`;
export const SingleFormaPago = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  width: 100%;
`;
export const Label = styled.label`
  position: relative;
  cursor: pointer;
  padding: 10px 12px 12px 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    border: 3px solid ${(props) => props.theme.text};
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  &::after {
    background-color: var(--mainBlue);
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    transition: 0.3s;
    transform: scale(0);
  }
  & > span {
    display: flex;
    align-items: center;
  }
`;
export const Img = styled.img`
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;
export const AccordionTab = styled.div`
  /*   padding: 10px; */
  border-top: 1px solid ${(props) => props.theme.border};
  text-align: center;
  overflow: hidden;
  max-height: 0;
`;
export const RadioButton = styled.input`
  display: none;
  &:checked + ${Label}::after {
    transform: scale(1);
  }
  &:checked ~ ${AccordionTab} {
    max-height: 100%;
    padding: 10px 50px;
  }
`;
export const ReactIcon = styled.div`
  font-size: 4rem;
  width: 50%;
  margin: 0 auto;
  color: ${(props) => props.theme.text};
`;

export const PaymentDetails = () => {
  const {
    user: { compra },
  } = useSelector((state) => state);
  const { formaDePago, step } = compra;

  const dispatch = useDispatch();

  function handleChange(e) {
    dispatch(addDatosCompra({ formaDePago: e.target.value }));
  }

  return (
    <AnimateTranslateX>
      <Header>
        <h2>Forma de pago</h2>
        <AnimatedLink
          as="button"
          onClick={() => dispatch(addDatosCompra({ step: step - 1 }))}
          c="var(--mainBlue)"
          ch="var(--mainBlueHover)"
        >
          Volver
        </AnimatedLink>
      </Header>
      <TitleFormasdePago>
        Optá por pago con Mercado Pago o transferencia bancaria.
      </TitleFormasdePago>
      <ContainerFormasPago>
        {/* MERCADO PAGO */}
        <SingleFormaPago>
          <RadioButton
            type="radio"
            name="formaDePago"
            value="MP"
            id="MP"
            onChange={handleChange}
            defaultChecked={"MP" === formaDePago}
          ></RadioButton>
          <Label htmlFor="MP">
            <span>Mercado Pago </span>
            <span>
              <Img src="/img/MP.png" alt="Opción de pago, Mercado Pago" />
            </span>
          </Label>
          <AccordionTab>
            <ReactIcon>
              <CgScreen />
            </ReactIcon>
            En la próxima página, luego de hacer click en “Confirmar mi compra”,
            serás redirigido a Mercado Pago para completar tu compra de forma
            segura.
          </AccordionTab>
        </SingleFormaPago>
        {/* WIRETRANSFER */}
        <SingleFormaPago>
          <RadioButton
            defaultChecked={"transferencia" === formaDePago}
            type="radio"
            name="formaDePago"
            value="transferencia"
            id="transferencia"
            onChange={handleChange}
          ></RadioButton>
          <Label htmlFor="transferencia">
            <span>Transferencia bancaria/depósito </span>
            <span>
              <AiFillCreditCard
                style={{ fontSize: "2.5rem", marginRight: "5px" }}
              />
            </span>
          </Label>
          <AccordionTab>
            <ReactIcon>
              <AiOutlineMail />
            </ReactIcon>
            En la próxima página, luego de hacer click en “Confirmar mi compra”,
            tu orden será ingresada y recibirás los datos bancarios para
            realizar la transferencia o depósito.
          </AccordionTab>
        </SingleFormaPago>
      </ContainerFormasPago>
      <BtnPagar
        onClick={() =>
          dispatch(addDatosCompra({ formaDePago, step: step + 1 }))
        }
      >
        Siguiente
      </BtnPagar>
    </AnimateTranslateX>
  );
};
