import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const SingleRow = styled.tr`
  &:hover {
    background-color: ${(props) => !props?.isEditing && props.theme.border};
  }
  background-color: ${(props) => props?.isEditing && "var(--mainGrey)"};
`;

export const CheckBoxGroupEdits = styled.input.attrs((props) => ({
  type: "checkbox",
}))`
  width: 100%;
`;

const SelectAllCheckbox = styled(CheckBoxGroupEdits)``;

const pad = { padding: "10px 0" };

export const TableHeader = ({
  allColumns,
  isGroupEditing,
  setGroupEdit,
  groupEdit,
}) => {
  const pad = { padding: "10px 0" };
  const display = !isGroupEditing && "none";
  /* separar Header PPAL del header Subtable
  poner si es ind 0 q el display dependa del isGroupEditing*/

  function changeINState() {
    const result = groupEdit.every(
      (i) => i.checked && i.esp.every((tamañoItem) => tamañoItem.checked)
    );
    //if  NOestoyCURRENTLYchecked && editGroup.some(i=>{!i.checked}) return NOestoyCURRENTLYchecked
    //if  ESTOYCURRENTLYchecked && editGroup.some(i=>{i.checked}) return ESTOYCURRENTLYCHECKED
    //else return OPPOSITE STATE
    return result;
  }

  return (
    <thead>
      <SingleRow>
        {allColumns.map((i, ind) => {
          if (!ind) {
            return (
              <th style={{ ...pad, display }} key={ind}>
                <SelectAllCheckbox
                  //tengo q mapear todos los groupEdit y ponerlos en checked
                  onChange={(e) =>
                    setGroupEdit((v) =>
                      v.map((i) => ({
                        ...i,
                        checked: e.target.checked,
                        esp: i.esp.map((tamañoItem) => ({
                          ...tamañoItem,
                          checked: e.target.checked,
                        })),
                      }))
                    )
                  }
                  checked={changeINState()}
                />
              </th>
            );
          } else {
            return (
              <th style={pad} key={ind}>
                {i}
              </th>
            );
          }
        })}
      </SingleRow>
    </thead>
  );
};

export const SubTableHeader = ({ allColumns }) => {
  return (
    <thead>
      <SingleRow>
        {allColumns.map((i, ind) => {
          return (
            <th style={pad} key={ind}>
              {i}
            </th>
          );
        })}
      </SingleRow>
    </thead>
  );
};

export const Col = styled.td`
  vertical-align: bottom;
  padding-top: 5px;
  text-align: center;

  &.multiedit {
    width: 25px;
    display: ${(props) => !props.isGroupEditing && "none"};
  }
  &.nombre {
    text-align: left;
  }
  &.nombre > :last-child {
    margin-left: 15px;
  }
  &.precio {
    font-weight: bold;
  }
  .acciones {
    display: flex;
    gap: 5px;
    justify-content: right;
  }

  & > svg {
    font-size: 2rem;
  }
  .save {
    transition: 0.3s;
    background-color: var(--mainGreen);
  }
  .save:hover {
    background-color: var(--mainGreenHover);
  }

  .fem {
    color: #e097a3;
  }
  .man {
    color: ${(props) => props.theme.blue};
  }
  &.actions {
    width: 30px;
  }
`;

export const ReactIcon = styled.span`
  color: ${(props) => props.theme.body};
  background-color: ${(props) =>
    props.delete ? props.theme.red : props.theme.text};
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: var(--fontMed);
  cursor: pointer;
  &:focus {
    border: 1px solid ${(props) => props.theme.blue};
  }
`;
export const SingleEsp = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & ${ReactIcon} {
    align-self: flex-end;
    padding: 12px;
    display: flex;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  text-align: center;
  font-size: inherit;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.border};
  &:focus {
    border-bottom: 1px solid ${(props) => props.theme.blue};
  }
`;

export const Promo = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RandomDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
export const LittleInfo = styled.span`
  background-color: var(--mainFuxia);
  color: white;
  padding: 5px 7px;
  border-radius: 5px;
`;
export const PrecioOriginal = styled.div`
  text-decoration: ${(props) => props.tachar && "line-through"};
  //si esta en promo, tachamos y ademas le sacamos el bold q le habiamos agregado antes a la Columna "precio"
  font-weight: ${(props) => props.tachar && "500"};
`;
