import {
  deleteOrUpdateTamaño,
  successDeleteFullProducto,
  successGetProducts,
  successProdCreated,
  successNewImage,
  clearError,
  startAction,
  addCarrito,
} from "context/userSlice";
import { axiosPOSTLogin, axiosPRELogin } from "./axios";
import { errorHandler } from "./errorHandler";
import {
  hideOrShowEditPhotoOverlay,
  showOrCloseNotification,
} from "context/generalSlice";
import { BACKEND_ENDPOINTS } from "constants";
import { carritoController } from "utils/carritoControllers";
import {
  filterIfNoStockIsActive,
  processSearchParams,
} from "utils/randomFunctions";

export const productosAPI = {
  getAllProds: async function (dispatch, filters) {
    try {
      //uso axios postlogins xq si es admin mando incluso prods sin stock (si no es admin mando prods con stock nada mas, salvo q haya hecho una busqueda el user)
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.PRODUCTOS}/?${processSearchParams(filters)}`
      );

      dispatch(
        successGetProducts({
          ...data,
          productos: filterIfNoStockIsActive(data.productos, filters),
        })
      );
    } catch (error) {
      //error handler
      errorHandler(error, dispatch, "GET ALL PRODUCTS");
    }
  },
  getDestacadoSection: async function () {
    //uso axios postlogins xq si es admin mando incluso prods sin stock (si no es admin mando prods con stock nada mas, salvo q haya hecho una busqueda el user)
    return axiosPOSTLogin.get(
      `${BACKEND_ENDPOINTS.PRODUCTOS_DESTACADOS}/?destacado=true`
    );
  },
  getFullProdForExcel: async function (filters) {
    return axiosPOSTLogin.get(
      `${BACKEND_ENDPOINTS.PRODUCTOS_EXCEL}/?${processSearchParams(filters)}`
    );
  },

  //USO ESTE CUANDO EL USER O EL ADMIN ESCRIBEN EN LA SEARCH BAR
  getProdsToShowSuggestions: async function (input) {
    try {
      //NO HAY GENERAL STATE ACA, lo hacemos x component
      const { data } = await axiosPRELogin.get(`productos/?search=${input}`);

      /* como le agregué pagination, ahora devuelvo data.productos, antes era solo data */
      return data.productos;
    } catch (error) {
      //NO error handler
      console.log(error, "GET ALL PRODUCTS TO SHOW SUGGESTIONS");
    }
  },

  getSingleProd: async function (dispatch, _id, setProducto) {
    dispatch(startAction());

    try {
      const { data } = await axiosPRELogin.get(`/productos/${_id}`);
      /*  dispatch(successGetSingleProduct(data)); */
      dispatch(clearError());
      setProducto(data);
    } catch (error) {
      //error handler
      errorHandler(error, dispatch, "GET SINGLE PRODUCT");
    }
  },

  checkCartOnRefresh: async function (dispatch, carrito) {
    const arrayOfIds = carrito.map((i) => i._id);

    try {
      const { data } = await axiosPRELogin.post(BACKEND_ENDPOINTS.CHECKCART, {
        arrayOfIds,
      });

      const updatedCarrito = [];
      let textNotification = "";
      carrito.forEach((i) => {
        const itemFromBackend = data.find((prod) => prod._id === i._id);
        const sizeChosen = itemFromBackend.esp.find(
          (singleSize) => singleSize._id === i._idTamaño
        );
        //si no hay stock en el backend, directamente no pusheamos nada al updatedCarritoArray
        const { tamaño, stock, descuento, promocion } = sizeChosen;
        const { nombre } = itemFromBackend;
        if (!stock) {
          textNotification += `Hemos removido el producto ${nombre} de ${tamaño}ml de tu carrito, pues ya no hay más stock. `;
          return;
        }
        //CHECK STOCK
        let { qty } = i;

        if (qty > stock) {
          qty = stock;
          textNotification += `Hemos actualizado el stock del producto ${nombre} de ${tamaño}ml. `;
        }
        //CHECK PRICE
        if (sizeChosen.precio !== i.precio) {
          textNotification += `Hemos actualizado el precio del producto ${nombre} de ${tamaño}ml, lo verás reflejado en tu carrito. `;
        }
        //CHECK PROMO
        if (descuento !== i.descuento || promocion !== i.promocion) {
          textNotification += `Hemos actualizado el descuento/promo del producto ${nombre} de ${tamaño}ml, lo verás reflejado en tu carrito. `;
        }

        const finalItem = carritoController.processMyEspObject(
          itemFromBackend,
          {
            ...sizeChosen,
            qty,
          }
        );
        updatedCarrito.push(finalItem);
      });
      if (textNotification) {
        dispatch(
          showOrCloseNotification({ show: true, message: textNotification })
        );
      }

      dispatch(addCarrito(updatedCarrito));
      return data;
    } catch (error) {
      //error handler
      errorHandler(error, dispatch, "CHECK CART ON REFRESH");
    }
  },

  sendEmailLackOfStock: function (objInput, prodSizeShowing) {
    return axiosPRELogin.post(
      `${BACKEND_ENDPOINTS.PRODUCTOS}/notifywhenstockisback/${prodSizeShowing?._id}`,
      { userInfo: objInput, prodSizeShowing }
    );
  },

  createProd: async function (dispatch, prod) {
    dispatch(startAction());
    const processed = prod.esp.map((i) =>
      i.espInputs.reduce((finalEspItem, i) => {
        finalEspItem[i.id] = i.value;
        return finalEspItem;
      }, {})
    );
    const dataImg = new FormData();
    const arrayEntries = Object.entries(prod);
    arrayEntries.forEach((i) => {
      if (i[0] === "esp") {
        //creo q esto lo tuve q hacer xq el backend sino no me lo tomaba ver notas
        return dataImg.append(i[0], JSON.stringify(processed));
      }
      dataImg.append(i[0], i[1]);
    });
    try {
      const { data } = await axiosPOSTLogin.post(
        BACKEND_ENDPOINTS.PRODUCTOS,
        dataImg
      );
      dispatch(successProdCreated(data));
      dispatch(
        showOrCloseNotification({
          show: true,
          message: "Se cargó exitosamente.",
        })
      );
    } catch (error) {
      errorHandler(error, dispatch, "POST CREATE_PRODUCT");
    }
  },

  updateImg: async function (dispatch, img, _id, setImg) {
    dispatch(startAction());
    const dataImg = new FormData();
    dataImg.append("img", img);
    try {
      const { data } = await axiosPOSTLogin.post(
        `${BACKEND_ENDPOINTS.PRODUCTOS}/newimage/${_id}`,
        dataImg
      );
      setImg("");
      dispatch(successNewImage(data));
      dispatch(hideOrShowEditPhotoOverlay({ show: false }));
      dispatch(
        showOrCloseNotification({
          show: true,
          message: "Se cargó exitosamente la foto.",
        })
      );
    } catch (error) {
      errorHandler(error, dispatch, "POST NEW IMAGE");
    }
  },

  deleteFullProd: async function (dispatch, _id) {
    dispatch(startAction());
    try {
      await axiosPOSTLogin.delete(`${BACKEND_ENDPOINTS.PRODUCTOS}/${_id}`);
      dispatch(successDeleteFullProducto(_id));
    } catch (error) {
      errorHandler(error, dispatch, "DELETE FULL PRODUCTO, NO SOLO TAMAÑOS");
    }
  },

  deleteTamaño: async function (dispatch, _id) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.delete(
        `${BACKEND_ENDPOINTS.PRODUCTOS}/tamano/${_id}`
      );
      dispatch(deleteOrUpdateTamaño(data));
    } catch (error) {
      errorHandler(error, dispatch, "DELETE TAMAñO INDIVIDUAL");
    }
  },

  updateProd: async function (dispatch, prod, _id) {
    //recibo la _id aparte y no con el prod xq asi resultó cuando agrego tamaño nuevo, ver si conviene fusionarlo previamente
    dispatch(startAction());
    try {
      //ver si mando _id tamaño o prod entero
      const { data } = await axiosPOSTLogin.put(
        `${BACKEND_ENDPOINTS.PRODUCTOS}/${_id}`,
        prod
      );
      //Updatear el state, vuelve el producto individual ya modificado
      dispatch(deleteOrUpdateTamaño(data));
    } catch (error) {
      errorHandler(error, dispatch, "PUT SINGLE_PRODUCT");
    }
  },
  bulkUpdate: async function (dispatch, arrayProd, { type, value }) {
    const depurado = arrayProd.reduce((final, item) => {
      let singleProd = { _id: item._id, esp: [] };
      item.esp.forEach((espSingle) => {
        if (espSingle.checked) {
          singleProd.esp.push(espSingle._idTamaño);
        }
      });
      if (singleProd.esp.length > 0) {
        final.push(singleProd);
      }

      return final;
    }, []);

    try {
      if (!depurado.length)
        throw Error("No seleccionaste productos para editar.");

      dispatch(startAction());

      const { data } = await axiosPOSTLogin.put(
        `${BACKEND_ENDPOINTS.PRODUCTOS_GROUP_EDIT}/?type=${type}&value=${value}`,
        depurado
      );
      console.log(data, 999);
      //COMPLETAR ESTO
      dispatch(deleteOrUpdateTamaño(data));
    } catch (error) {
      errorHandler(error, dispatch, "PUT SINGLE_PRODUCT");
      return Promise.reject();
    }
  },
};
