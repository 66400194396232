export function moverDcha(slideShow) {
  const tiraDeSlides = slideShow.current;

  const currentSlide = tiraDeSlides.children[0];
  const lastSlide = tiraDeSlides.children[tiraDeSlides.children.length - 1];

  tiraDeSlides.prepend(lastSlide);
  tiraDeSlides.style.transition = "none";
  tiraDeSlides.style.transform = `translateX(-${currentSlide.offsetWidth}px)`;

  setTimeout(() => {
    tiraDeSlides.style.transition = ".3s";
    tiraDeSlides.style.transform = `translateX(0px)`;
  }, 30);
}

export function moverIzq(slideShow) {
  const tiraDeSlides = slideShow.current;
  const currentSlide = tiraDeSlides.children[0];

  tiraDeSlides.style.transition = ".3s";
  tiraDeSlides.style.transform = `translateX(-${currentSlide.offsetWidth}px)`;
  function resetLastElement() {
    tiraDeSlides.style.transition = "none";
    tiraDeSlides.appendChild(currentSlide);
    tiraDeSlides.style.transform = `translateX(0px)`;
    tiraDeSlides.removeEventListener("transitionend", resetLastElement);
  }
  tiraDeSlides.addEventListener("transitionend", resetLastElement);
}
