import {
  AnimatedLink,
  BtnPagar,
  OverlayStructure,
} from "components/styles/smallComponents";
import DisplayAddress from "pages/checkout/auxiliaries/DisplayAddress";
import React, { useState } from "react";
import styled from "styled-components";
import { AiFillCreditCard } from "react-icons/ai";
import { STATUS_PAGO } from "constants";
import { comprasAPI } from "API/compras";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Btn } from "pages/admin/auxiliaries/OverlayStyledComp";

const TopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
`;
const Main = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
`;
const FlexIt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  gap: 10px;
`;
const Img = styled.img`
  width: 100%;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ContainerInfoEnvio = styled.section`
  font-size: 1.2rem;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
`;
const HeaderDireccionEnvio = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  padding: 10px;
`;
const InfoEnvio = styled.div`
  padding: 10px;
`;
const ContainerFormaDePago = styled(ContainerInfoEnvio)``;
const HeaderFormaDePago = styled(HeaderDireccionEnvio)``;
const InfoFormaDePago = styled(InfoEnvio)``;

export default function EditPaymentAdmin({
  isOpen,
  openOrClose,
  compra,
  setMonthlySales,
}) {
  const { formaDePago, msjeClient, datosEnvio, statusPago } = compra;
  const [radioStatusPago, setRadioStatusPago] = useState(statusPago);
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  async function handleSubmit() {
    if (radioStatusPago === statusPago) {
      openOrClose((v) => !v);
      return;
    }

    comprasAPI
      .editStatusPago(
        compra,
        {
          statusPago: radioStatusPago,
        },
        dispatch
      )
      .then((data) => {
        setMonthlySales((v) => v.map((i) => (i._id === data?._id ? data : i)));
        openOrClose((v) => !v);
      });
  }
  return (
    <tr>
      <td>
        <OverlayStructure show={isOpen} close={openOrClose}>
          <TopPart>
            <h2> EDITAR STATUS PAGO</h2>
            <AnimatedLink
              as="button"
              onClick={() => openOrClose((v) => !v)}
              c="var(--mainBlue)"
              ch="var(--mainBlueHover)"
            >
              Cerrar
            </AnimatedLink>
          </TopPart>
          <Main>
            <ContainerFormaDePago>
              <HeaderFormaDePago>Forma de pago</HeaderFormaDePago>
              <InfoFormaDePago>
                <FlexIt>
                  {formaDePago === "MP" ? (
                    <>
                      <span>Mercado Pago </span>
                      <span>
                        <Img
                          src="/img/MP.png"
                          alt="Opción de pago, Mercado Pago"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Transferencia bancaria/depósito </span>
                      <span>
                        <AiFillCreditCard
                          style={{ fontSize: "2.5rem", marginRight: "5px" }}
                        />
                      </span>
                    </>
                  )}
                </FlexIt>
              </InfoFormaDePago>
            </ContainerFormaDePago>
            <ContainerInfoEnvio>
              <HeaderDireccionEnvio>STATUS DEL PAGO</HeaderDireccionEnvio>
              <InfoEnvio>
                {Object.values(STATUS_PAGO).map((i) => {
                  return (
                    <div key={i}>
                      <label>
                        <input
                          type="radio"
                          value={i}
                          name="statusPago"
                          checked={radioStatusPago === i}
                          onChange={(e) => setRadioStatusPago(i)}
                        />
                        {i}
                      </label>
                    </div>
                  );
                })}
              </InfoEnvio>
            </ContainerInfoEnvio>
            <ContainerInfoEnvio>
              <HeaderDireccionEnvio>Dirección de envío</HeaderDireccionEnvio>
              <InfoEnvio>
                <DisplayAddress datosEnvio={datosEnvio} />
              </InfoEnvio>
            </ContainerInfoEnvio>
            <ContainerInfoEnvio>
              <HeaderDireccionEnvio>
                Mensaje para el vendedor
              </HeaderDireccionEnvio>
              <InfoEnvio>
                <b>{msjeClient || "---"}</b>
              </InfoEnvio>
            </ContainerInfoEnvio>
            {/* -------------------SECCION BOTONES---------------------------- */}
            <FlexIt>
              <BtnPagar
                danger
                type="button"
                onClick={() => openOrClose((v) => !v)}
                /*             disabled={Indloading} */
              >
                Volver
              </BtnPagar>
              <Btn danger onClick={handleSubmit} /* disabled={Indloading} */>
                {loading ? "..." : "Confirmar"}
              </Btn>
            </FlexIt>
          </Main>
        </OverlayStructure>
      </td>
    </tr>
  );
}
