import {
  AnimatedLink,
  BtnPagar,
  OverlayStructure,
} from "components/styles/smallComponents";
import DisplayAddress from "pages/checkout/auxiliaries/DisplayAddress";
import React from "react";
import styled from "styled-components";
import { AiFillCreditCard } from "react-icons/ai";
const TopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  & :nth-child(1) {
    font-size: inherit;
  }
`;
const Main = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
`;
const FlexIt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;
const Img = styled.img`
  width: 100%;
  height: calc(var(--fontSmall) + 20px);
  background-color: white;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ContainerInfoEnvio = styled.section`
  font-size: calc(var(--fontSmall) - 0.2rem);
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
`;
const HeaderDireccionEnvio = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  padding: 10px;
`;
const InfoEnvio = styled.div`
  padding: 10px;
`;
const ContainerFormaDePago = styled(ContainerInfoEnvio)``;
const HeaderFormaDePago = styled(HeaderDireccionEnvio)``;
const InfoFormaDePago = styled(InfoEnvio)``;

export default function CompraAdditionalInfo({
  openInfoOverlay,
  setOpenInfoOverlay,
  compra,
}) {
  const { formaDePago, msjeClient, datosEnvio, datePago } = compra;

  return (
    <tr>
      <td>
        <OverlayStructure show={openInfoOverlay} close={setOpenInfoOverlay}>
          <TopPart>
            <h2> Datos de tu compra</h2>
            <AnimatedLink
              as="button"
              onClick={() => setOpenInfoOverlay((v) => !v)}
              c="var(--mainBlue)"
              ch="var(--mainBlueHover)"
            >
              Cerrar
            </AnimatedLink>
          </TopPart>
          <Main>
            <ContainerFormaDePago>
              <HeaderFormaDePago>Forma de pago</HeaderFormaDePago>
              <InfoFormaDePago>
                <FlexIt>
                  {formaDePago === "MP" ? (
                    <>
                      <span>Mercado Pago </span>
                      <span>
                        <Img
                          src="/img/MP.png"
                          alt="Opción de pago, Mercado Pago"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span>Transferencia bancaria/depósito </span>
                      <span>
                        <AiFillCreditCard
                          style={{ fontSize: "2.5rem", marginRight: "5px" }}
                        />
                      </span>
                    </>
                  )}
                </FlexIt>
              </InfoFormaDePago>
            </ContainerFormaDePago>
            <ContainerInfoEnvio>
              <HeaderDireccionEnvio>Dirección de envío</HeaderDireccionEnvio>
              <InfoEnvio>
                <DisplayAddress datosEnvio={datosEnvio} />
              </InfoEnvio>
            </ContainerInfoEnvio>
            <ContainerInfoEnvio>
              <HeaderDireccionEnvio>
                Mensaje para el vendedor
              </HeaderDireccionEnvio>
              <InfoEnvio>
                <b>{msjeClient || "---"}</b>
              </InfoEnvio>
            </ContainerInfoEnvio>
            {datePago && (
              <ContainerInfoEnvio>
                <HeaderDireccionEnvio>Fecha de pago</HeaderDireccionEnvio>
                <InfoEnvio>
                  <b>{new Date(datePago).toLocaleString()}</b>
                </InfoEnvio>
              </ContainerInfoEnvio>
            )}

            <BtnPagar onClick={() => setOpenInfoOverlay((v) => !v)}>
              Cerrar
            </BtnPagar>
          </Main>
        </OverlayStructure>
      </td>
    </tr>
  );
}
