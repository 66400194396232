import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { comprasAPI } from "API/compras";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import {
  Aside,
  FlexIt,
  Main,
  TopAside,
  BottomAside,
  Subtotal,
  Envio,
  Total,
  HeaderAside,
} from "./styles";
import { WireTransfer } from "./auxiliaries/confirmed/WireTransfer";
import { PaymentMP } from "./auxiliaries/confirmed/PaymentMP";
import { SingleCheckOutItem } from "components/Single/SingleCheckOut";
import { getPriceAndDecimalsInTheSameDiv } from "utils/mathFunctions";
import { ContainerGral1500 } from "components/SmallComponents/Containers";

export const Confirmed = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.user);
  const [purchaseToDisplay, setPurchaseToDisplay] = useState(null);
  const navigate = useNavigate();
  //si el pago está approved tengo q returnear

  //ver dsp en q momento opto x poner en el user la compra, creo q minimo desde q esta confirmed, y capaz antes incluso, rechequear. En base a eso tmb voy a tener q actualizar user
  //en el back si es bank tenemos q mandar info bank + prod,
  //en caso mp solo info prod, y mp se encarga de renderizar el btn con el preferenceId no?
  //ver si distingo MP poscall o se requiere antes,mmm, creo q el preferenceId
  useEffect(() => {
    comprasAPI.confirmedPurchaseGetFinalData(
      dispatch,
      _id,
      setPurchaseToDisplay
    );
  }, [dispatch, _id]);
  //VER Q SI TENGO ERROR, dsp de los 5 segs desaparece y aparecen los componenentes
  //tengo q usar un useState y ponerlo en true cuando hay error, dsp ya queda fijo y listo,mmm puede q sí
  return (
    <ContainerGral1500>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <FlexIt>
        <Main>
          {loading ? (
            <Spinner fz="5rem" h="30vh" />
          ) : error ||
            !purchaseToDisplay ? undefined : purchaseToDisplay.preferenceId ? (
            <PaymentMP preferenceId={purchaseToDisplay.preferenceId} />
          ) : (
            <WireTransfer PerfMundialData={purchaseToDisplay} />
          )}
          {/* ESTO SE MUESTA EN EL CASO Q EL BACKEND NO MANDE NADA, x ej, error porque la compra ya estaba pagada. Asi el user puede volver atras facilmente, aunq tecnicamente no debería llegar aca legalmente */}
          {!purchaseToDisplay && !loading && (
            <div style={{ textAlign: "center" }}>
              Parece que algo salió mal.{" "}
              <AnimatedLink
                as="button"
                onClick={() => navigate(-1)}
                c="var(--mainBlue)"
                ch="var(--mainBlueHover)"
              >
                Volver
              </AnimatedLink>
            </div>
          )}
        </Main>

        <Aside>
          <HeaderAside>RESUMEN COMPRA</HeaderAside>
          <TopAside>
            {purchaseToDisplay &&
              purchaseToDisplay?.compra?.productos.map((i) => {
                return (
                  <SingleCheckOutItem
                    {...i}
                    img={i.prodId.img}
                    marca={i.prodId.marca}
                    key={i.prodId._id + i.tamaño}
                  />
                );
              })}
          </TopAside>
          {purchaseToDisplay && (
            <BottomAside>
              <Subtotal>
                <span>Subtotal</span>
                <span>
                  $
                  {getPriceAndDecimalsInTheSameDiv({
                    precio: purchaseToDisplay.compra?.total,
                  })}
                </span>
              </Subtotal>
              <Envio>
                <span>Envío</span>
                <span>
                  {purchaseToDisplay?.compra?.costoEnvio
                    ? `$${purchaseToDisplay.compra?.costoEnvio}`
                    : "GRATIS"}
                </span>
              </Envio>
              <Total>
                <span>Total</span>
                <span>
                  $
                  {getPriceAndDecimalsInTheSameDiv({
                    precio:
                      purchaseToDisplay.compra?.total +
                      purchaseToDisplay.compra?.costoEnvio,
                  })}
                </span>
              </Total>
            </BottomAside>
          )}
        </Aside>
      </FlexIt>
    </ContainerGral1500>
  );
};
