import { BACKEND_ENDPOINTS } from "constants";
import { clearError, startAction } from "context/userSlice";
import { axiosPOSTLogin } from "./axios";
import { errorHandler } from "./errorHandler";

export const marcasAPI = {
  getMarcas: async function (dispatch, setMarcas) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.get(BACKEND_ENDPOINTS.MARCAS);
      dispatch(clearError());
      setMarcas(data.marcas.sort());
    } catch (error) {
      errorHandler(error, dispatch, "GET MARCAS");
    }
  },
  createMarcas: async function (dispatch, marcasArray) {
    dispatch(startAction());
    try {
      const { data } = await axiosPOSTLogin.post(BACKEND_ENDPOINTS.MARCAS, {
        marcas: marcasArray,
      });
      dispatch(clearError());
      return data.marcas.sort();
    } catch (error) {
      errorHandler(error, dispatch, "CREATE MARCAS");
      return Promise.reject();
    }
  },
  deleteMarcas: async function (dispatch, marcasArrayToDelete) {
    dispatch(startAction());
    try {
      if (!marcasArrayToDelete.length) {
        throw Error("Seleccioná al menos una marca para borrar.");
      }
      const { data } = await axiosPOSTLogin.delete(BACKEND_ENDPOINTS.MARCAS, {
        data: { marcas: marcasArrayToDelete },
      });
      dispatch(clearError());
      return data.marcas.sort();
    } catch (error) {
      errorHandler(error, dispatch, "DELETE MARCAS");
      return Promise.reject();
    }
  },
};
