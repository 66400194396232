import { comprasAPI } from "API/compras";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SalesBarChart } from "./auxiliaries/SalesBarChart";
import SalesPerMonth from "./auxiliaries/SalesPerMonth";

export default function AdminSales() {
  const dispatch = useDispatch();
  const [sales, setSales] = useState([]);

  useEffect(() => {
    comprasAPI.getSalesData(dispatch).then((data) => setSales(data));
  }, [dispatch]);

  return (
    <div>
      <SalesPerMonth />
      {sales?.length > 0 ? (
        <SalesBarChart sales={sales} />
      ) : (
        <h2>NO HAY VENTAS</h2>
      )}
    </div>
  );
}
