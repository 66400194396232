import React from "react";
import styled from "styled-components";

const HeaderTable = styled.tr`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.body};
`;
const Col = styled.th`
  font-size: var(--fontMed);
  padding: 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
export default function HeaderTableClientes() {
  return (
    <thead>
      <HeaderTable>
        <Col colSpan={5}>USUARIOS</Col>
      </HeaderTable>
      <HeaderTable>
        <Col></Col>
        <Col>Email</Col>
        <Col>Nombre</Col>
        <Col>Antiguedad</Col>
        <Col>Admin</Col>
      </HeaderTable>
    </thead>
  );
}
export function HeaderTablePreguntas() {
  return (
    <thead>
      <HeaderTable>
        <Col colSpan={7}>PREGUNTAS</Col>
      </HeaderTable>
      <HeaderTable>
        <Col>Producto</Col>
        <Col>Email</Col>
        <Col>Celular</Col>
        <Col>Fecha</Col>
        <Col>Pregunta</Col>
        <Col>Respuesta</Col>
        <Col></Col>
      </HeaderTable>
    </thead>
  );
}

/* export default function ClientsTable() {
  return (
    <div>ClientsTable</div>
  )
} */
