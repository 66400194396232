import {
  LSTORAGE_KEY_USER,
  FAILED_REFRESH,
  BACKEND_ENDPOINTS,
  FRONTEND_ENDPOINTS,
} from "constants";
import { clearError, startAction, successLogout } from "context/userSlice";
import { dispatchNotification } from "utils/dispatchNotification";
import setHeadersAndUser from "utils/setHeadersAndUser";
import { axiosPOSTLogin, axiosPRELogin, setHeaders } from "./axios";
import { errorHandler } from "./errorHandler";

export const authAPI = {
  forgotPwd: async function (dispatch, emailUsuario, setSuccess) {
    dispatch(startAction());
    try {
      const { data } = await axiosPRELogin.post(
        `${BACKEND_ENDPOINTS.AUTH}/forgotPwd/sendEmail`,
        { emailUsuario }
      );
      setSuccess(data);
      dispatch(clearError());
    } catch (error) {
      errorHandler(error, dispatch, "POST USER_FORGOT-PWD");
    }
  },
  changeForgottenPwd: async function (
    dispatch,
    input,
    secretToken,
    setSuccess
  ) {
    dispatch(startAction());
    try {
      await axiosPRELogin.put(
        `${BACKEND_ENDPOINTS.AUTH}/forgotPwd/${secretToken}/changePassword`,
        input
      );
      setSuccess("Ya podés iniciar sesión con tu nueva contraseña.");
      dispatch(clearError());
    } catch (error) {
      errorHandler(error, dispatch, "POST USER_CHANGE_FORGOTTEN-PWD");
    }
  },

  register: async function (dispatch, regInput) {
    dispatch(startAction());
    try {
      const { data } = await axiosPRELogin.post(
        BACKEND_ENDPOINTS.REGISTER,
        regInput
      );
      //set headers and user
      setHeadersAndUser(dispatch, data);
      /*-------------custom hook pal local storage*/
      /*  setUserLocalStorage(data); */
      localStorage.setItem(LSTORAGE_KEY_USER, JSON.stringify(true));

      //BORRAR DSP SUCCESSREGISTER, no la uso
    } catch (error) {
      errorHandler(error, dispatch, "POST USER_REGISTER");
      throw new Error();
    }
  },

  login: async function (dispatch, logInput, navigate, from) {
    dispatch(startAction());

    try {
      const { data } = await axiosPRELogin.post(
        BACKEND_ENDPOINTS.LOGIN,
        logInput
      );
      setHeadersAndUser(dispatch, data);
      /*-------------custom hook pal local storage*/
      /*  setUserLocalStorage(data); */
      localStorage.setItem(LSTORAGE_KEY_USER, JSON.stringify(true));

      /*---Vaciar inputs del submit*/
      /*   pwd.current.value = "";
      email.current.value = ""; */

      navigate(data.user?.admin ? `${FRONTEND_ENDPOINTS.ADMIN}#` : `${from}#`, {
        replace: true,
      });
    } catch (error) {
      //VER SI USO OTRO ERRORHANDLER
      errorHandler(error, dispatch, "POST USER_LOGIN");
    }
  },

  logout: async function (dispatch) {
    dispatch(startAction());
    try {
      await axiosPOSTLogin.get(BACKEND_ENDPOINTS.LOGOUT, {
        withCredentials: true,
      });
      //borrar local storage
      localStorage.removeItem(LSTORAGE_KEY_USER);
      //redireccionar? o redirecciona solo xq no hay user?
      /*       const { data } = await axiosPOSTLogin.get(`${BACKEND_ENDPOINTS.AUTH}/logout`);
      return data; */
      setHeaders();
      dispatch(successLogout());
    } catch (error) {
      //devuelvo el error al interceptor
      errorHandler(error, dispatch, "LOGOUT");
    }
  },

  refreshMyTokenOnPageRefresh: async function (dispatch) {
    try {
      const { data } = await this.refreshAPI();

      setHeadersAndUser(dispatch, data);
    } catch (error) {
      //el error pasa previamente x el interceptor, pero simplemente lo devolvemos

      //si el RTCookie estaba expired el backend se va a encargar de borrarla, pero igual tenemos q limpiar el storage (headers y user se borraron automaticamente)

      //creo q tengo q setear el error.config (p/q me logueé out el errorHandler), xq si te fijas el interceptor, nunca se cumple uno de los req (q la url no sea refresh), x lo q nunca seteo FAILED_REFRESH ahi, y lo tengo q hacer necesariamente aca
      error.config[FAILED_REFRESH] = true;
      //tendria q chequear si el error es por falta de internet, en ese caso loguearla out no sirve xq la cookie permanence
      errorHandler(error, dispatch, "CHECKSTORAGE FAILED REFRESHTKN API CALL");
    }
  },
  //esta la uso tmb en el interceptor
  refreshAPI: async function () {
    return axiosPOSTLogin.get(BACKEND_ENDPOINTS.REFRESH, {
      withCredentials: true,
    });
    //withCredentials true lo seteo en el POSTLogin en este API Call y en Logout, y  el preLogin lo seteo tmb xq sino Chrome no me muestra la cookie
  },

  contactSeller: async function (dispatch, input) {
    dispatch(startAction());
    try {
      await axiosPRELogin.post(BACKEND_ENDPOINTS.CONTACT, input);
      dispatch(clearError());
      dispatchNotification(
        dispatch,
        "Se envío el mensaje con éxito. Te vamos a contactar a la brevedad."
      );
    } catch (error) {
      errorHandler(error, dispatch, "POST SEND MESSAGE CONTACTING SELLER");
    }
  },
};
