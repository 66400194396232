import React from "react";
import styled from "styled-components";

const HeaderTable = styled.tr`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.body};
`;
const Col = styled.th`
  font-size: 1.3rem;
  padding: 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
const BodyCol = styled.td`
  font-size: 1.3rem;
  padding: 20px 0;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
const Table = styled.table`
  width: 100%;
  padding: 20px 0;
`;
export default function VisitsTable({ visits }) {
  return (
    <Table>
      <thead>
        <HeaderTable>
          <Col colSpan={4}>VISITAS</Col>
        </HeaderTable>
        <HeaderTable>
          <Col>IP</Col>
          <Col>Pais</Col>
          <Col>Ciudad</Col>
          <Col>Visitas</Col>
        </HeaderTable>
      </thead>
      <tbody>
        {visits.length > 0 &&
          visits.map((i) => {
            return (
              <tr key={i.ip}>
                <BodyCol>{i.ip}</BodyCol>
                <BodyCol>{i.country || "---"}</BodyCol>
                <BodyCol>{i.city || "---"}</BodyCol>
                <BodyCol>{i.visits}</BodyCol>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
