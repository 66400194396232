import React, { useState } from "react";
import styled from "styled-components";
import { turnSearchParamsIntoObject } from "utils/randomFunctions";
import { AiOutlineSend } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { Input } from "pages/UserProfile/auxiliaries/ContactoContainer";

const AddNewComment = styled.button`
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  font-size: var(--fontMed);

  display: grid;
  place-items: center;

  border-radius: 5px;
  &:hover {
    border: 1px solid ${(props) => props.theme.border};
  }
`;

const InputSearchQuery = styled(Input)`
  font-size: inherit;
`;
const FlexItForm = styled.form`
  display: flex;
  gap: 5px;
`;

const SendButton = () => {
  return (
    <AddNewComment>
      <AiOutlineSend />
    </AddNewComment>
  );
};

const SearchQueryForm = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  function onSubmitSearch(e) {
    e.preventDefault();
    //cambio search params, el useEffect va a llamar a la api
    setSearchParams({
      ...turnSearchParamsIntoObject(searchParams),
      search: searchQuery,
    });
    setSearchQuery("");
  }

  return (
    <FlexItForm onSubmit={onSubmitSearch}>
      <InputSearchQuery
        placeholder="Buscar fragrancia..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <SendButton />
    </FlexItForm>
  );
};

export default SearchQueryForm;
