import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { comprasAPI } from "API/compras";
import {
  AnimatedLink,
  BtnPagar,
  Spinner,
} from "components/styles/smallComponents";
import styled from "styled-components";
import { Img } from "./PaymentDetails";
import { AiFillCreditCard } from "react-icons/ai";
import { addDatosCompra } from "context/userSlice";
import DisplayAddress from "../DisplayAddress";
import { AnimateTranslateX } from "./AddressStep";

export const TopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Main = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: calc(var(--fontSmall) - 0.1rem);
`;
export const FlexIt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const ContainerInfoEnvio = styled.section`
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
`;
export const HeaderDireccionEnvio = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  padding: 10px;
  letter-spacing: 1px;
`;
export const InfoEnvio = styled.div`
  padding: 10px;
`;
const ContainerFormaDePago = styled(ContainerInfoEnvio)``;
const HeaderFormaDePago = styled(HeaderDireccionEnvio)``;
const InfoFormaDePago = styled(InfoEnvio)``;

export const Modifier = styled.div`
  padding-top: 5px;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => props.theme.red};
  font-weight: bold;
  font-size: calc(var(--fontSmall) - 0.2rem);
`;

export const ReviewOrder = ({ setOpenMsjeClient }) => {
  const {
    user: { carrito, compra, loading },
  } = useSelector((state) => state);
  const { step, formaDePago, msjeClient, datosEnvio } = compra;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <AnimateTranslateX>
      <TopPart>
        <h2 style={{ fontSize: "inherit" }}> Revisá tu compra</h2>
        <AnimatedLink
          as="button"
          onClick={() => dispatch(addDatosCompra({ step: step - 1 }))}
          c="var(--mainBlue)"
          ch="var(--mainBlueHover)"
        >
          Volver
        </AnimatedLink>
      </TopPart>
      <Main>
        <ContainerFormaDePago>
          <HeaderFormaDePago>Forma de pago</HeaderFormaDePago>
          <InfoFormaDePago>
            <FlexIt>
              {formaDePago === "MP" ? (
                <>
                  <span>Mercado Pago </span>
                  <span>
                    <Img src="/img/MP.png" alt="Opción de pago, Mercado Pago" />
                  </span>
                </>
              ) : (
                <>
                  <span>Transferencia bancaria/depósito </span>
                  <span>
                    <AiFillCreditCard
                      style={{ fontSize: "2.5rem", marginRight: "5px" }}
                    />
                  </span>
                </>
              )}
            </FlexIt>
            <Modifier
              onClick={() => dispatch(addDatosCompra({ step: step - 1 }))}
            >
              Modificar
            </Modifier>
          </InfoFormaDePago>
        </ContainerFormaDePago>
        <ContainerInfoEnvio>
          <HeaderDireccionEnvio>Dirección de envío</HeaderDireccionEnvio>
          <InfoEnvio>
            <DisplayAddress datosEnvio={datosEnvio} />
            <Modifier
              onClick={() => dispatch(addDatosCompra({ step: step - 2 }))}
            >
              Modificar
            </Modifier>
          </InfoEnvio>
        </ContainerInfoEnvio>
        <ContainerInfoEnvio>
          <HeaderDireccionEnvio>Mensaje para el vendedor</HeaderDireccionEnvio>
          <InfoEnvio>
            <b>
              {msjeClient
                ? msjeClient
                : "Podés especificarnos lo que consideres conveniente a los fines de concretar la compra. "}
            </b>
            <Modifier onClick={() => setOpenMsjeClient(true)}>
              Modificar
            </Modifier>
          </InfoEnvio>
        </ContainerInfoEnvio>

        <BtnPagar
          onClick={() =>
            comprasAPI.confirmOrder(
              dispatch,
              { ...compra, productos: carrito },
              navigate
            )
          }
          disabled={loading}
        >
          {loading ? <Spinner /> : "Confirmar mi compra"}
        </BtnPagar>
      </Main>
    </AnimateTranslateX>
  );
};
