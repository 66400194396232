import { ADDRESS_STEP_PROVINCIA } from "constants";
import {
  APELLIDO_DEST,
  fullData,
  NOMBRE_DEST,
} from "pages/checkout/auxiliaries/steps/addressData";

export function validateAddressStep(inputsEnvio) {
  const errorObj = {};
  for (const key in inputsEnvio) {
    if (
      (key !== "depto" && !inputsEnvio[key]) ||
      inputsEnvio[key] === ADDRESS_STEP_PROVINCIA
    ) {
      errorObj[key] = fullData.find((i) => i.id === key).errorMsg;
    }

    if (
      (key === NOMBRE_DEST || key === APELLIDO_DEST) &&
      inputsEnvio[key].length > 25
    ) {
      errorObj[key] = fullData.find((i) => i.id === key).errorMsg;
    }
  }
  if (Object.keys(errorObj).length > 0) {
    return [false, errorObj];
  }
  return [true];
}
