import React, { useEffect, useState } from "react";
import { HeaderTablePreguntas } from "./clients/ClientsTable";
import { AiFillDelete } from "react-icons/ai";
import styled from "styled-components";
import { preguntasAPI } from "API/preguntas";
import { Link } from "react-router-dom";
import { FRONTEND_ENDPOINTS } from "constants";
const PaginationSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  & > button {
    padding: 10px;
    border: 1px solid ${(props) => props.theme.border};
    cursor: pointer;
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.body};
    border-radius: 3px;
    min-width: 50px;
    &:hover {
      background-color: ${(props) => props.theme.blue};
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: right;
  padding: 5px 5px 0 0;
  align-items: center;
  gap: 5px;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${(props) => props.theme.border};
  font-weight: bold;
`;
const ImgContainer = styled.div`
  width: 50px;
  height: 50px;
  /* sin el display inline-block, la imagen y el texto q le siguen se ponen en modo column, yo los quiero en flex */
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
`;
const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;
const FILTER_QUESTIONS = {
  ALL: "TODAS",
  UNANSWERED: "NO RESPONDIDAS",
};

export default function AdminPreguntas() {
  const [preguntas, setPreguntas] = useState([]);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(1);
  const [query, setQuery] = useState({
    page: 1,
    filter: FILTER_QUESTIONS.UNANSWERED,
  });

  const totalPages = Math.ceil(total / limit);

  useEffect(() => {
    preguntasAPI.getAdminAllPregs(query).then(({ data }) => {
      console.log(data);
      setPreguntas(data.preguntas);
      setLimit(data.limit);
      setTotal(data.total);
    });
  }, [query]);

  const handleDelete = (pregID) => {
    if (window.confirm(`Estás seguro que querés eliminar esta pregunta?`)) {
      preguntasAPI
        .deletePreg(pregID)
        .then(() =>
          setPreguntas((v) => v.filter((preg) => preg._id !== pregID))
        )
        .catch(() => {
          alert("error");
        });
    }
  };

  return (
    <div>
      <FilterSection>
        FILTRAR PREGUNTAS
        <Select
          value={query.filter}
          onChange={(e) => {
            setQuery((v) => ({ ...v, filter: e.target.value }));
          }}
        >
          <option value={FILTER_QUESTIONS.ALL}>{FILTER_QUESTIONS.ALL}</option>
          <option value={FILTER_QUESTIONS.UNANSWERED}>
            {FILTER_QUESTIONS.UNANSWERED}
          </option>
        </Select>
      </FilterSection>

      {preguntas?.length > 0 ? (
        <table style={{ width: "100%" }}>
          <HeaderTablePreguntas />
          <tbody>
            {preguntas.map((i) => {
              console.log(i, 777);
              const { _id, email, cellphone, value, reply, createdAt, prodId } =
                i;
              return (
                <tr key={_id}>
                  <td>
                    {" "}
                    <Link
                      to={FRONTEND_ENDPOINTS.dynamicSingleProduct(prodId?._id)}
                    >
                      <ImgContainer>
                        <Img src={prodId?.img}></Img>
                      </ImgContainer>
                      {prodId?.nombre}/{prodId?.marca}
                    </Link>
                  </td>
                  <td>{email}</td>
                  <td>{cellphone}</td>
                  <td>
                    {new Date(createdAt).toLocaleString("es-AR", {
                      year: "numeric",
                      month: "short",
                    })}
                  </td>
                  <td>'{value}'</td>
                  <td>{reply || "---"}</td>
                  <td>
                    {" "}
                    <AiFillDelete onClick={() => handleDelete(_id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <h2>NO HAY PREGUNTAS</h2>
      )}
      <br />
      <PaginationSection>
        <button
          disabled={query.page === 1}
          onClick={() => setQuery((v) => ({ ...v, page: 1 }))}
        >
          1
        </button>
        <button
          disabled={query.page - 1 === 0}
          onClick={() => setQuery((v) => ({ ...v, page: v.page - 1 }))}
        >
          Ant
        </button>
        <button
          disabled={query.page === totalPages}
          onClick={() => setQuery((v) => ({ ...v, page: v.page + 1 }))}
        >
          Sig
        </button>
        <button
          disabled={query.page === totalPages}
          onClick={() => setQuery((v) => ({ ...v, page: totalPages }))}
        >
          {totalPages}
        </button>
      </PaginationSection>
    </div>
  );
}
