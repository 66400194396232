import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { addDatosCompra } from "context/userSlice";

const AñadirNota = styled.div`
  font-size: var(--fontSmall);
`;
const FlexIt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TopNota = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
const Circle = styled.div`
  height: 20px;
  /* min-width en vez de width p/evitar shrinking */
  min-width: 20px;
  border-radius: 50%;
  background-color: ${(props) => (props.texto ? "green" : props.theme.red)};
  margin-right: 10px;
  @media (max-width: 600px) {
    height: 13px;
    /* min-width en vez de width p/evitar shrinking */
    min-width: 13px;
  }
`;
const BtnAbrirNota = styled.div`
  transition: 0.3s;
  transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0)")};
`;
const FormNota = styled.textarea`
  width: 100%;
  /*   transform: ${(props) => (props.show ? "scale(1)" : "scale(0)")}; */
  /*   height: ${(props) => (props.show ? "100%" : "0")}; */
  outline: ${(props) =>
    props.show ? `1px solid ${props.theme.border}` : "none"};
  transition: 0.3s ease-out;
  max-height: ${(props) => (props.show ? "600px" : "0px")};
  overflow: hidden;
  padding: ${(props) => (props.show ? "10px" : "0px")};
  font-size: inherit;
  &:focus {
    border: 1px solid #1f9c35;
  }
`;
export const NotaCliente = ({ openMsjeClient, setOpenMsjeClient }) => {
  const { compra } = useSelector((state) => state.user);

  const { msjeClient } = compra;
  const dispatch = useDispatch();
  const notaCliente = useRef();
  const [texto, setTexto] = useState(msjeClient);

  //el initialState del useState runs only on first render, so i need a useEffect if I want to keep both them linked
  useEffect(() => {
    setTexto(msjeClient);
  }, [msjeClient]);

  useEffect(() => {
    if (openMsjeClient) {
      notaCliente.current.focus();
    }
  }, [openMsjeClient]);

  return (
    <AñadirNota>
      <TopNota
        onClick={() => {
          setOpenMsjeClient(!openMsjeClient);
        }}
      >
        <FlexIt>
          <Circle texto={texto?.trim()} />
          ¿Querés decirnos algo?
        </FlexIt>
        <BtnAbrirNota show={openMsjeClient}>
          <AiFillCaretDown />
        </BtnAbrirNota>
      </TopNota>
      <FormNota
        rows={4}
        show={openMsjeClient}
        ref={notaCliente}
        value={texto}
        onChange={(e) => setTexto(e.target.value)}
        onBlur={() => {
          dispatch(addDatosCompra({ msjeClient: texto }));
        }}
      ></FormNota>
    </AñadirNota>
  );
};
