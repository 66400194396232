const BACKEND_DEV = "http://localhost:5000/api";
const BACKEND_PRODUCTION = {
  RENDER_COM_APP: "https://perfumeria-mundial-inc.onrender.com/api",
  RAILWAY_APP: "https://perfumeriamundiale-shop-production.up.railway.app/api",
};

export const BACKEND_ROOT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? BACKEND_DEV
    : BACKEND_PRODUCTION.RENDER_COM_APP;

export const HEADERS_NAME = "auth";
export const LSTORAGE_KEY_USER = "user";
export const LSTORAGE_KEY_ONGOING_PURCHASE = "ongoingPurchase";
export const LSTORAGE_KEY_CARRITO = "carrito";
export const LSTORAGE_KEY_THEME = "theme";

export const ADDRESS_STEP_PROVINCIA = "Seleccioná provincia";
export const FAILED_REFRESH = "sent";
export const RODRI_WEB = "https://www.rodrigohernanlopez.com.ar/";
export const CONSUMIDOR_CABA =
  "https://www.buenosaires.gob.ar/defensaconsumidor";
export const PHONE = "1154594235";
export const EMAIL = "contacto@perfumeriamundial.ar";
export const WHATSAPP_LINK = `https://api.whatsapp.com/send/?phone=54${PHONE}&text&type=phone_number&app_absent=0`;
export const FB_LINK = "https://www.facebook.com/PerfumeriaMundialOficial";

export const STATUS_PAGO = {
  PENDIENTE: "pendiente",
  APROBADO: "aprobado",
  CANCELADO: "cancelado",
};
export const HASH_LINKS = {
  PROD: "prods",
  TOP: "top",
};

export const BULK_EDIT_OPTIONS = {
  PRECIO: "precio",
  PRECIO$: "$",
  PRECIO_PERCENTAGE: "%",
  PRECIO_ADD: "+",
  PRECIO_SUBTRACT: "-",
  STOCK: "stock",
  PROMO: "promo",
  DESTACADO: "destacado",
};

class URL_BACK_ENDPOINTS {
  #PREGUNTAS = "/preguntas";
  PRODUCTOS = "/productos";
  PRODUCTOS_EXCEL = `${this.PRODUCTOS}/nopagination/excel`;
  PRODUCTOS_DESTACADOS = `${this.PRODUCTOS}/nopagination/destacados`;
  PRODUCTOS_GROUP_EDIT = `${this.PRODUCTOS}/bulk`;
  dynamicSingleProdPREGUNTAS(prodId) {
    return `${this.#PREGUNTAS}/${prodId}`;
  }
  dynamicAdminGetAllPregs(optionsObj) {
    let query = "";
    Object.entries(optionsObj).forEach(
      ([key, value]) => (query += `${key}=${value}&`)
    );

    return `${this.#PREGUNTAS}/?${query}`;
  }
  AUTH = "/users/auth";
  COMPRAS = "/checkout/pagos";
  PROFILE = "/profile";
  PROFILE_EDIT_PWD = `${this.PROFILE}/pwd`;
  MARCAS = "/marcas";
  VISITS = "/visits";
  LOGIN = `${this.AUTH}/login`;
  REGISTER = `${this.AUTH}/register`;
  REFRESH = `${this.AUTH}/refresh`;
  LOGOUT = `${this.AUTH}/logout`;
  CONTACT = `${this.AUTH}/contact`;
  CHECKCART = `${this.PRODUCTOS}/checkcart`;
}
export const BACKEND_ENDPOINTS = new URL_BACK_ENDPOINTS();
const productos = "/productos";
class URL_FRONT_ENDPOINTS {
  HOME = "/";
  AUTH = "/user/auth";
  CARRITO = "/compras/carrito";
  CHECKOUT = "/compras/checkout";
  SUCCESS = `${this.CHECKOUT}/success`;
  CONFIRMED = `${this.CHECKOUT}/confirmed/:_id`;
  dynamicConfirmed(id) {
    return this.CONFIRMED.replace(":_id", id);
  }
  SINGLEPRODUCT = productos + "/:id";
  dynamicSingleProduct(id) {
    return `${productos}/${id}`;
  }
  USER_PROFILE = "/profile";
  CONTACT = "/contacto";
  LOGIN = `${this.AUTH}/login`;
  REGISTER = `${this.AUTH}/register`;
  FORGOT_PASSWORD = `${this.AUTH}/forgotpassword`;
  CHANGE_PASSWORD = `${this.FORGOT_PASSWORD}/:secretToken/changePassword`;
  //VER SINGLE COMPRA y singleproducto y adminprofile para .SINGLEPRODUCT
  /* ----------------ADMIN----------------- */
  ADMIN = "/admin";
  ADMIN_CREATE_PROD = `${this.ADMIN}/create`;
  ADMIN_CUSTOMERS = `${this.ADMIN}/customers`;
  ADMIN_MARCAS = `${this.ADMIN}/marcas`;
  ADMIN_SALES = `${this.ADMIN}/sales`;
  ADMIN_PREGUNTAS = `${this.ADMIN}/preguntas`;
  ADMIN_VISITS = `${this.ADMIN}/visits`;
}
export const FRONTEND_ENDPOINTS = new URL_FRONT_ENDPOINTS();
