import styled from "styled-components";
import { AiFillCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BottomTextConfirmed,
  SecondBox,
  TextConfirmed,
} from "./styleAndComponents";
import { FRONTEND_ENDPOINTS } from "constants";

export const FirstBox = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const FlexIt = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const Pedido = styled.div``;
export const ThankYou = styled.div`
  font-weight: 600;
`;
export const Img = styled.img`
  width: 100%;
  max-width: 130px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.border};
`;
//second box

export const Top = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
export const Text = styled.span`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Title = styled.h2``;
export const Par = styled.p`
  color: ${(props) => props.theme.secondaryText};
`;
export const ReactIcon = styled.span`
  font-size: 5rem;
  color: ${(props) => props.theme.blue};
  display: flex;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 580px) {
    font-size: 3rem;
  }
`;

export const Middle = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 400px) {
    word-break: break-all;
  }
`;
export const BankDetails = styled.div`
  display: flex;
  & ${Par} {
    flex: 1;
  }
`;
export const Deco = styled.div`
  border-left: 3px solid ${(props) => props.theme.blue};
  width: 30%;
  /* p/separar el texto de la linea decorativa */
  margin-left: 3px;
  @media (max-width: 580px) {
    width: 10px;
  }
`;

export const WireTransfer = ({ PerfMundialData }) => {
  //GET DATA BANCARIA
  const {
    user: { user },
  } = useSelector((state) => state);
  return (
    <>
      <FirstBox>
        <FlexIt to={FRONTEND_ENDPOINTS.USER_PROFILE}>
          <Pedido>Nº de pedido {PerfMundialData?.compra?._id}</Pedido>
          <ThankYou>Gracias {user?.nombre}!</ThankYou>
        </FlexIt>
        <Img src="/img/logo.ico" alt="logo Perfumería Mundial Inc."></Img>
      </FirstBox>
      <SecondBox>
        <Top>
          <Text>
            <Title>Tu pedido está confirmado</Title>
            <Par>
              Te enviamos un email con el detalle de tu pedido y la información
              para que realices la transferencia o el depósito bancario
            </Par>
          </Text>
          <ReactIcon>
            <AiFillCheckCircle />
          </ReactIcon>
        </Top>
        <Middle>
          <Par>
            Realizá la transferencia o el depósito a la siguiente cuenta:
          </Par>
          <BankDetails>
            <Par>
              ‌🏦Banco: {PerfMundialData?.bankAccount?.bank}
              <br />
              🏦{PerfMundialData?.bankAccount?.accType}, nro:{" "}
              {PerfMundialData?.bankAccount?.accNumber} <br />
              CBU: {PerfMundialData?.bankAccount?.CBU}
              <br />
              Titular: {PerfMundialData?.bankAccount?.holder} <br />
            </Par>
            <Deco />
          </BankDetails>
          <Par> ‌⚠️ ¡IMPORTANTE!</Par>
          <Par>
            ⚠️ ‌Tenés que enviar el comprobante al email{" "}
            <b>{PerfMundialData?.mail}</b> indicando tu Nº de Pedido.
          </Par>
          <Par>
            ‌⚠️ RECORDÁ INDICAR TU Nº DE PEDIDO AL ENVIAR EL COMPROBANTE. Tu
            orden no será enviada hasta que el pago sea recibido. ‌‌ ✅ Una vez
            validado el pago, recibirás la confirmación en tu mail.
          </Par>
        </Middle>
      </SecondBox>
      {/* TEXT CONFIRMED y BOTTOMTEXTCONFIRMED SON COMPONENTES Q IMPORTE Q USO TANTO EN MP COMO EN WIRETRANSFER */}
      <TextConfirmed />
      {/*  */}
      <SecondBox>
        <Par> ‌⚠️ ¡IMPORTANTE!</Par>
        <Par>
          Si aún no recibiste el mail de confirmación, revisá tu casilla de spam
          o correo no deseado.
        </Par>
      </SecondBox>
      {/* TEXT CONFIRMED y BOTTOMTEXTCONFIRMED SON COMPONENTES Q IMPORTE Q USO TANTO EN MP COMO EN WIRETRANSFER */}
      <BottomTextConfirmed />
      {/*  */}
    </>
  );
};
