import { errorHandler } from "API/errorHandler";
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

function useApiCall({ customApiCall, args }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const execute = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await customApiCall(args);

      setData(data);
    } catch (error) {
      errorHandler(error, dispatch, "CUSTOM API CALL");
    } finally {
      setLoading(false);
    }
  }, [dispatch, customApiCall, args]);

  useEffect(() => {
    execute();
  }, [execute]);

  return { data, loading, execute };
}

export default useApiCall;
