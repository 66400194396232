import { FAILED_REFRESH } from "constants";

import { clearError, failureCheckOut } from "context/userSlice";
import { authAPI } from "./auth";

export const errorHandler = async (error, dispatch, etapa) => {
  console.log(error);
  console.log(
    "%c" + JSON.stringify(error),
    "color:crimson; font-weight:bold; font-size:15px;"
  );
  console.log(
    "%c" + JSON.stringify(error?.response?.data),
    "color:blue; font-weight:400; font-size:15px;"
  );
  console.log("STAGE: ", etapa);

  //NO HAY INTERNET
  //tengo q chequear si el error es por falta de internet, en ese caso loguearla out no sirve xq la cookie permanece, entonces tengo q informarle q no hay internet
  if (
    error.message === "Network Error" ||
    error.message === "Failed to fetch"
  ) {
    dispatch(failureCheckOut("Parece que se cayó el servicio de internet."));
    return setTimeout(() => dispatch(clearError()), 6000);
  }

  //ver si agrego 401 : no deberia ocurrir, ya q actualizo los tokens con c/refresh, siempre hay accessTokens
  //agregué el status xq si hacia un refresh exitoso pero dsp una solicitud fallida,el config sent estaba presente y me logueaba out, a pesar de q no correspondía
  if (error?.config?.[FAILED_REFRESH] && error?.response?.status === 403) {
    console.log(
      "aca es un error pos-refresh-token api call o puede ser tmb cookie rt vencida tras actualizar la pag",
      error.config
    );
    return await authAPI.logout(dispatch);
  }

  //CATCH ALL
  dispatch(
    failureCheckOut(
      error?.response?.data || error?.message || "Ocurrió un error."
    )
  );

  setTimeout(() => dispatch(clearError()), 6000);
};
