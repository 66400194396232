export const darkTheme = {
  body: "#18191A",
  secondaryBody: "#242526",
  text: "white",
  secondaryText: "#e9e9e9",
  grey: "#616060",
  border: "#333333",
  shadow: "#333333",
  overlay: "rgba(367, 367, 367, 0.1);",
  blue: "#719ef4",
  blueHover: "#548bf1",
  red: "#f74f4f",
  hoverRed: "#f74040",
};

export const lightTheme = {
  body: "#e5e3e3",
  secondaryBody: "white",
  text: "black",
  secondaryText: "#333333",
  grey: "#5a5959",
  border: "#d3d3d3",
  shadow: "black",
  overlay: "rgba(0, 0, 0, 0.5);",
  blue: "#335ef0",
  blueHover: "#1a49ee",
  red: "red",
  hoverRed: "#950606",
};
