import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { CarritoOverlay } from "components/Carrito/CarritoOverlay";
import { Footer } from "components/Footer/Footer";
import { Nav } from "components/Nav/Nav";
import { Notification } from "components/Notification/Notification";

const FlexIt = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export function CommonElements() {
  return (
    <>
      <FlexIt className="FLEXFOOTERTOTHEBOTTOM">
        <div style={{ flex: 1 }} className="flex1 topPart">
          <Nav />
          <CarritoOverlay />
          <Notification />
          <Outlet />
        </div>
        <Footer />
      </FlexIt>
    </>
  );
}
