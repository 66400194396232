import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { marcasAPI } from "API/marcas";
import { AiFillCaretDown } from "react-icons/ai";
import { navHeight, ReactIcon } from "components/Nav/Nav";
import {
  grupoRadiosNoAdmin,
  grupoRadiosAdmin,
  noStockCbox,
  promoCbox,
  destacadoCbox,
} from "./data";
import { AiOutlineClose, AiFillControl } from "react-icons/ai";
import { CloseButton, ScrollStyles } from "components/styles/smallComponents";
import {
  turnSearchParamsIntoObject,
  makeMyUrlSearchParamsObject,
} from "utils/randomFunctions";
import { useSearchParams } from "react-router-dom";
import SearchQueryForm from "./SearchQueryForm";
import { useSelector } from "react-redux";

import { AiOutlineSearch } from "react-icons/ai";

const FilterSection = styled.div`
  background-color: ${(props) => props.theme.body};
  padding-left: 5px;
  @media (max-width: 1200px) {
    width: 400px;
    position: relative;
    padding: ${(props) =>
      props.displaceCloseButton ? "80px 10px 20px" : "20px 10px"};
    ${ScrollStyles}
  }
`;
const Header = styled.h2`
  font-size: var(--fontMed);
  padding: 20px 0;
`;
const FilterContainer = styled.div`
  @media (max-width: 1200px) {
    z-index: 500;
    display: ${(props) => (props.show ? "flex" : "none")};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    justify-content: right;
    background-color: ${(props) => props.theme.overlay};
  }
  font-size: var(--fontMed);
  width: 25%;
  /* POSITION STICKY */
  position: sticky;
  top: ${navHeight}; /* height dinamica de acuerdo con el nav height */
  height: fit-content; /* sin esto se estira full height y no funciona el position:sticky */
`;
const SubHeader = styled.h4`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 5px 0;
`;
const Content = styled.div`
  ${ScrollStyles}
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: ${(props) => (props.scrollBar ? "scroll" : "hidden")};
  transition: 0.3s;
  /* si scrollBar es true, significa q estamos en Marcas, le damos mayor height a pedido del cliente */
  max-height: ${(props) =>
    !props.show ? "0" : props.scrollBar ? "400px" : "200px"};
  text-transform: capitalize;
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: calc(var(--fontSmall) + 0.1rem);
  &::before {
    content: "";
    height: 20px;
    //min width to avoid shrinking
    min-width: 20px;
    border-radius: 50%;
    display: block;
    border: 1px solid ${(props) => props.theme.border};
  }
  @media (max-width: 650px) {
    &::before {
      height: 15px;
      //min width to avoid shrinking
      min-width: 15px;
    }
  }
`;
const CheckBoxOrRadio = styled.input`
  display: none;
  &:checked + ${Label}::before {
    background-color: ${(props) => props.theme.text};
  }
`;
const ActiveFiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 5px;
  ${ScrollStyles}
  max-height:300px;
  font-size: var(--fontSmall);
  @media (max-width: 1200px) {
    max-height: 200px;
  }
`;
const CboxActiveFilter = styled.input`
  display: none;
`;
const LabelActiveFilter = styled.label`
  cursor: pointer;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  gap: 5px;
  background-color: ${(props) => props.theme.blue};
  color: white;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px;
`;
const ClearActiveFilters = styled.button`
  color: ${(props) => props.theme.red};
  background-color: ${(props) => props.theme.body};
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${(props) => props.theme.red};
  }
`;
const CellphoneFilterBtn = styled.button`
  @media (min-width: 1200px) {
    display: none;
  }
  position: sticky;
  /* antes tenia 5px + Navheight, ahora le puse 20px xq el admin sidebar btn p/abrirlo sino queda tapado */
  top: calc(${navHeight} + 20px);
  z-index: 300;
  border: 1px solid ${(props) => props.theme.border};
  padding: 10px;
  max-width: 150px;
  font-size: var(--fontSmall);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.border};
  }
`;
const DisplayNone = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Filters2point0 = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  /*------MARCAS: Como paso un setMarcas como argumento y la api hace lo suyo (es decir, no puedo preprocesar salvo q cambie la funcion q estan usando ya otro componente y tenga eso en cuenta. Podría poner q devuelva marcas en vez de setMarcas), no me queda otra mas q tener 2 states, uno espera a q se setée el otro-----------*/
  const [marcas, setMarcas] = useState([]);
  useEffect(() => {
    marcasAPI.getMarcas(dispatch, setMarcas);
  }, [dispatch]);
  /*---------------SEARCH PARAMS-------------------------*/
  //uso searchParams como useState tanto p/Cbox como p/radiobtn
  let [searchParams, setSearchParams] = useSearchParams();
  /*------FILTERS Q EL USER ACTIVO--------------------*/
  const [activeFilters, setActiveFilters] = useState([]);
  //c/vez q cambia el params, actualizo los filtros activos
  useEffect(() => {
    let arr = [];
    for (const [key, value] of searchParams) {
      if (key === "marca") {
        let marcasArray = value.split(",").map((i) => {
          return { key, value: i };
        });
        arr.push(...marcasArray);
      }
      //PAGE Y LIMIT NO TIENEN Q APARECER EN LOS FILTROS ACTIVOS
      if (key !== "page" && key !== "limit" && key !== "marca") {
        arr.push({ key, value });
      }
    }
    setActiveFilters(arr);
  }, [searchParams]);
  /* OPEN OR CLOSE FILTER CONTAINER WHEN RESPONSIVE */
  const [showFilterResp, setShowFilterResp] = useState(false);
  const grupoRadios = user?.admin ? grupoRadiosAdmin : grupoRadiosNoAdmin;
  return (
    <>
      <FilterContainer
        show={showFilterResp}
        id="greyOverlay"
        onClick={(e) => {
          if (e.target?.id === "greyOverlay") {
            setShowFilterResp(false);
          }
        }}
        className="sticky"
      >
        <FilterSection displaceCloseButton={activeFilters.length}>
          <ActiveFiltersSection>
            {/* a veces pasa q el searchParams tiene key, pero el value esta vacio. Si se diera el caso q todas las keys estan vacias no quiero q el btn de borrar todos los filtros aparezca */}
            {activeFilters.length > 0 && activeFilters.some((i) => i.value) && (
              <>
                {activeFilters.map(
                  (i, ind) =>
                    i.value && (
                      <div key={i.value}>
                        <CboxActiveFilter
                          value={i.value}
                          type="checkbox"
                          id={i.value + ind}
                          checked
                          onChange={(e) => {
                            i.key === "marca"
                              ? setSearchParams({
                                  ...turnSearchParamsIntoObject(searchParams),
                                  marca: searchParams
                                    .get(i.key)
                                    .split(",")
                                    .filter((iv) => iv !== e.target.value)
                                    .join(),
                                })
                              : setSearchParams({
                                  ...turnSearchParamsIntoObject(searchParams),
                                  [i.key]: "",
                                });
                          }}
                        ></CboxActiveFilter>
                        <LabelActiveFilter htmlFor={i.value + ind}>
                          {/* if filter is $search, we show a magnifying glass. In that way we can differentiate a $search "Kenzo" from a brand Cbox "Kenzo", for instance*/}
                          {i.key === "search" && <AiOutlineSearch />}
                          {i.value}
                          <AiOutlineClose />
                        </LabelActiveFilter>
                      </div>
                    )
                )}
                <ClearActiveFilters
                  onClick={() => {
                    setSearchParams();
                    setShowFilterResp((v) => !v); //cierra filter overlay
                  }}
                >
                  Borrar todos los filtros
                </ClearActiveFilters>
              </>
            )}
          </ActiveFiltersSection>
          <DisplayNone>
            <CloseButton f={() => setShowFilterResp(false)} move />
          </DisplayNone>
          <Header>Filtrar por</Header>
          {/* SEARCH QUERY FORM */}
          <SearchQueryForm />
          {/* FILTER UNITS, puse Marcas aparte xq proviene de una API call, mientras q el resto de los filtros no */}
          <FilterUnit
            marcas={marcas}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
          {grupoRadios.map((i) => (
            <FilterUnit
              key={i.header}
              data={i}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          ))}
        </FilterSection>
      </FilterContainer>

      <CellphoneFilterBtn onClick={() => setShowFilterResp((v) => !v)}>
        <AiFillControl />
        Filtros:{" "}
        {activeFilters.reduce((tot, i) => {
          i.value && tot++;
          return tot;
        }, 0)}
      </CellphoneFilterBtn>
    </>
  );
};

export default Filters2point0;

export const FilterUnit = ({ searchParams, setSearchParams, data, marcas }) => {
  //1. cuando clickeo, le doy trato especial a promocion y noStock  xq es un checkbox, No es radio
  // 2. tengo q reflejar el cambio en checked: diferenciar el cbox del radio
  function handleChange(e, marca, promocion) {
    const { target } = e;
    if (marca)
      return setSearchParams({
        ...turnSearchParamsIntoObject(searchParams),
        marca: !searchParams.get("marca")
          ? e.target.value
          : e.target.checked
          ? searchParams.get("marca") + `,${e.target.value}`
          : searchParams
              .get("marca")
              .split(",")
              .filter((i) => i !== e.target.value)
              .join(),
      });

    // [name=promocion]: value="Promos"
    if (
      target.name === promoCbox.name ||
      target.name === noStockCbox.name ||
      target.name === destacadoCbox.name
    ) {
      return setSearchParams({
        ...turnSearchParamsIntoObject(searchParams),
        //si dejaba el checked el user iba a ver "true" o "false" como nombre de filtro en Active filters
        //asi se ve "Promos"
        [target.name]: target.checked ? target.value : "",
      });
    }
    setSearchParams(makeMyUrlSearchParamsObject(searchParams, e));
  }
  const [openOrCloseAccordion, setOpenOrCloseAccordion] = useState(
    marcas ? true : false
  );
  return (
    <>
      <SubHeader onClick={() => setOpenOrCloseAccordion(!openOrCloseAccordion)}>
        {marcas ? "Marcas" : data.header}
        <ReactIcon>
          <AiFillCaretDown />
        </ReactIcon>
      </SubHeader>
      <Content show={openOrCloseAccordion} scrollBar={marcas}>
        {marcas
          ? marcas.length > 0 &&
            marcas.map((marca) => {
              return (
                <div key={marca}>
                  <CheckBoxOrRadio
                    value={marca}
                    type="checkbox"
                    id={marca}
                    checked={
                      !searchParams.get("marca")
                        ? false
                        : searchParams.get("marca").split(",").includes(marca)
                    }
                    onChange={(e) => handleChange(e, true)}
                  ></CheckBoxOrRadio>
                  <Label htmlFor={marca}>{marca}</Label>
                </div>
              );
            })
          : data.radioUnits.map((i) => {
              const { name, valueANDid, label } = i;

              return (
                <div key={valueANDid}>
                  <CheckBoxOrRadio
                    value={valueANDid}
                    type={data.type}
                    id={valueANDid}
                    onChange={handleChange}
                    name={name}
                    checked={
                      //promocion es cbox
                      data.type === "checkbox"
                        ? !!searchParams.get(name)
                        : searchParams.get(name) === valueANDid
                    }
                  ></CheckBoxOrRadio>
                  <Label htmlFor={valueANDid}>{label || valueANDid}</Label>
                </div>
              );
            })}
      </Content>
    </>
  );
};
