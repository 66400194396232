import styled from "styled-components";
import { useState } from "react";
import { GoSettings } from "react-icons/go";
import { Line } from "pages/admin/auxiliaries/OverlayStyledComp";
import { BULK_EDIT_OPTIONS } from "constants";

const BulkActionsIcon = styled.div`
  cursor: pointer;
  font-size: var(--fontMed);
  position: relative;
  z-index: 3;
  & > svg {
    opacity: 0.5;
  }

  &:hover > svg {
    opacity: 1;
  }
`;

//lo pongo antes de comment count p/poder referenciarlo en CommentCount:hover
const ModalUserNamesHover = styled.div`
  color: ${(props) => props.theme.text};
  border-radius: 10px;
  position: absolute;
  right: 100%;
  background-color: ${(props) => props.theme.secondaryBody};
  border: 1px solid ${(props) => props.theme.border};
  overflow-y: auto;
  max-height: 50vh;
  width: max-content;
  max-width: 50vh;
  //shadow p/ q sobresalga el modal
  box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.75);
`;
const Div = styled.div`
  padding: 10px;
`;
const OptionsBulk = styled(Div)`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.border};
  }
`;

const BulkActionsModal = ({
  openOrCloseBulkEditOverlay,
  setBulkActionType,
}) => {
  const [showBulkModal, setShowBulkModal] = useState(false);

  function doIt(type) {
    openOrCloseBulkEditOverlay();
    setBulkActionType(type);
  }
  return (
    <BulkActionsIcon
      onClick={() => setShowBulkModal((v) => !v)}
      onBlur={() => showBulkModal && setShowBulkModal((v) => !v)}
      tabIndex="0"
    >
      <GoSettings />
      {showBulkModal && (
        <ModalUserNamesHover>
          <Div>Acciones masivas</Div>
          <Line />
          <OptionsBulk
            onClick={() => {
              doIt(BULK_EDIT_OPTIONS.PRECIO_ADD + BULK_EDIT_OPTIONS.PRECIO);
            }}
          >
            Aumentar precio
          </OptionsBulk>
          <OptionsBulk
            onClick={() =>
              doIt(BULK_EDIT_OPTIONS.PRECIO_SUBTRACT + BULK_EDIT_OPTIONS.PRECIO)
            }
          >
            Bajar precio
          </OptionsBulk>
          <OptionsBulk onClick={() => doIt(BULK_EDIT_OPTIONS.STOCK)}>
            Modificar stock
          </OptionsBulk>
          <OptionsBulk onClick={() => doIt(BULK_EDIT_OPTIONS.PROMO)}>
            Modificar promo
          </OptionsBulk>
          <OptionsBulk onClick={() => doIt(BULK_EDIT_OPTIONS.DESTACADO)}>
            Modificar destacado
          </OptionsBulk>
        </ModalUserNamesHover>
      )}
    </BulkActionsIcon>
  );
};

export default BulkActionsModal;
