import { noStockCbox } from "components/Filters/data";

export function turnSearchParamsIntoObject(searchParams) {
  let newObject = {};
  for (const [key, value] of searchParams) {
    newObject[key] = value;
  }
  return newObject;
}
export function makeMyUrlSearchParamsObject(searchParams, e) {
  let newObject = turnSearchParamsIntoObject(searchParams);
  newObject[e.target.name] = e.target.value;
  return newObject;
}

export function findBiggerEspSize(esp) {
  return esp.reduce((final, item) => {
    //en la primera vuelta todavia no hay tamaño/producto, asi q lo agrego, independientemente de q haya o no stock
    if (!final.tamaño) {
      final = item;
      return final;
    }
    if ((item.tamaño > final.tamaño && item.stock) || !final.stock) {
      final = item;
    }
    return final;
  });
}

export function getExcelDataFromProductos(productos) {
  const data = productos.reduce((final, current) => {
    const { _id: masterID, nombre, sexo, marca } = current;
    const processedEsp = current.esp.map((i) => {
      let processed = { nombre, ...i, masterID, sexo, marca };
      return processed;
    });
    if (!final.length) {
      const headerKeysArray = Object.keys(processedEsp[0]);
      console.log(headerKeysArray);
      final.push(headerKeysArray);
    }
    final = [...final, ...processedEsp.map((i) => Object.values(i))];
    return final;
  }, []);

  return data;
}
export function getExcelHeaders(productos) {
  const data = getExcelDataFromProductos(productos);
  if (data.length > 0) {
    const keys = Object.keys(data[0]);
    const headersArray = keys.map((k) => ({ label: k, key: k }));
    return headersArray;
  }
}

export function processSearchParams(searchParams) {
  let string = "";

  for (const [key, value] of searchParams) {
    string ? (string += `&${key}=${value}`) : (string = `${key}=${value}`);
  }

  return string;
}

export function filterIfNoStockIsActive(prods, filters) {
  if (
    processSearchParams(filters).includes(
      `${noStockCbox.name}=${noStockCbox.value}`
    )
  ) {
    return prods.map((i) => {
      return { ...i, esp: i.esp.filter((prod) => !prod.stock) };
    });
  } else {
    return prods;
  }
}
