import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { carritoController } from "utils/carritoControllers";
import { getDecimals, getPriceWithoutDecimals } from "utils/mathFunctions";
import { findBiggerEspSize } from "utils/randomFunctions";
import { MainButton } from "components/styles/smallComponents";
import { PromoDisplay } from "./SingleCartItem";
import { FRONTEND_ENDPOINTS } from "constants";
import { BsTruck } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { HASH_LINKS } from "constants";
import { HashLink } from "react-router-hash-link";

/* subí arriba la parte de seleccion de tamaños xq hice el truquito de referenciarlo al en otro component (p/mostrar o no el overlay) */
export const TamañoSelectorOverlay = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  opacity: 0;
  transition: 0.3s;
  transform: translateY(-20px);
  /* lo dejo de mostrar y aparece el TamañoSelectorResponsive */

  @media (max-width: 600px) {
    display: none;
  }
`;
const TamañoIndividual = styled.div`
  border: ${(props) => (props.active ? "3px solid black" : "2px dotted black")};
  color: black;
  cursor: pointer;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fontSmall);
  transition: 0.3s;
  &:hover {
    border: ${(props) => !props.active && "3px solid #313131"};
  }
`;
const TamañoSelectorResponsive = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px;
  @media (min-width: 600px) {
    display: none;
  }
`;
const TamañoIndividualResponsive = styled.div`
  border: 2px solid ${(props) => props.theme.body};
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--fontSmall) - 0.2rem);
  transition: 0.3s;
  padding: 5px;
  /* ---------- */
  color: ${(props) => props.active && props.theme.secondaryBody};
  background-color: ${(props) => props.active && props.theme.text};
`;
/* ---------------------------------------- */
export const ImgOverlay = styled.div`
  background-color: rgba(365, 365, 365, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 0.3s;
`;
export const Container = styled.div`
  box-shadow: 11px 17px 22px -17px ${(props) => props.theme.shadow};
  padding: 30px 0 10px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.border};
  color: ${(props) => props.theme.text};
  align-self: stretch;
  background-color: ${(props) => props.theme.secondaryBody};
  font-size: calc(var(--fontLarge) - 0.4rem);
  @media (max-width: 720px) {
    padding: 50px 0;
    border-radius: 20px;
  }
  @media (max-width: 600px) {
    padding: 10px 0;
    border-radius: initial;
    font-size: var(--fontMed);
  }
`;

export const Info = styled.div`
  padding: 0 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: calc(var(--fontMed) + 0.1rem);
  &:hover ${TamañoSelectorOverlay} {
    opacity: 1;
    transform: translate(0, 0);
  }
  &:hover ${ImgOverlay} {
    opacity: 1;
  }
`;
/* -----------------TEXTO DE ARRIBA PREVIO A LA IMG--- */
export const Header = styled(HashLink)`
  flex: 1;
  @media (max-width: 600px) {
    font-size: var(--fontSmall);
  }
`;
export const Marca = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.secondaryText};
`;
export const TituloNombre = styled.div`
  font-weight: bold;
  margin-top: 10px;
  letter-spacing: 1px;
  color: ${(props) => props.theme.secondaryText};
`;
/* -------------------------------------- */
const containHeight = "500px";
export const ImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 350px;
  margin: 20px 0;
  cursor: pointer;
  /* lo pongo blanco p/q en object-fit contain no se vea el background gris y la img centrada 
  IMPORTANTE: si la img tiene otro color de fondo q no sea blanco va a quedar feo igual*/
  background-color: white;
  @media (max-width: 720px) {
    max-height: 250px;
  }
  @media (max-width: ${containHeight}) {
    max-height: 120px;
  }
`;

export const Img = styled.img`
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  @media (max-width: ${containHeight}) {
    object-fit: contain;
  }
`;

export const FlexIt = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  /* ----------tanto fw como fz aplican a Preciopromo y Precio x default */
  /* si dsp Precio esta en promo le hago un override */
  font-size: var(--fontMed);
  font-weight: bold;
  /* ----------------------------- */
  @media (max-width: 500px) {
    font-size: var(--fontSmall);
    flex-direction: ${(props) => (props.cartAndSingleProd ? "column" : "row")};
    align-items: ${(props) =>
      props.cartAndSingleProd ? "flex-start" : "center"};
  }
`;
export const Precio = styled.span`
  font-weight: ${(props) => props.promocion && "300"};
  color: ${(props) => props.promocion && props.theme.grey};
  text-decoration: ${(props) => props.promocion && "line-through"};
  font-size: ${(props) => props.promocion && css`calc(var(--fontMed) - .2rem)`};
  @media (max-width: 500px) {
    font-size: ${(props) => props.promocion && css`var(--fontSmall)`};
  }
`;
export const PrecioPromo = styled.div``;
export const Decimals = styled.span`
  vertical-align: top;
  font-size: calc(var(--fontSmall) - 0.4rem);
`;
export const Envio = styled.div`
  font-size: calc(var(--fontSmall) + 0.1rem);
  letter-spacing: 1px;
  /* ------------- */
  color: var(--mainGreen);
  padding: 10px 10px 0 10px;
  margin-top: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  /* truck logo */
  & > * {
    font-size: var(--fontBig);
  }
  @media (max-width: 600px) {
    font-size: calc(var(--fontSmall) - 0.3rem);
    & > * {
      font-size: var(--fontBig);
    }
  }
`;

const Devolucion = styled(Envio)`
  margin: 0;
  padding-bottom: 15px;
  & > * {
    font-size: var(--fontSmall);
  }
`;
export const NoStock = styled.div`
  padding: 10px;
  text-align: center;
  font-size: var(--fontBig);
  color: ${(props) => props.theme.red};
  @media (max-width: 600px) {
    font-size: calc(var(--fontMed) - 0.2rem);
  }
`;

export const SingleProducto = (props) => {
  //este es el tamaño q el Cliente va a ver/q vamos a mostrar, loopeamos ESP y lo elegimos.
  const [prodSizeShowing, setProdSizeShowing] = useState(); //contiene tamaño, stock, precio, promocion, descuento, _id
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { carrito },
  } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const { nombre, esp, marca, img, _id } = props; //es el PROD entero, con esp/todos los tamaños
  /*-------------------ADD TO CART ------------------------*/
  /*Si mirás abajo en el MainButton, a la addCart fn le paso las props, x eso dsp uso el prodSizeShowing _id p/buscar el producto q el cliente quiere efectivamente agregar al carrito*/
  function addCart(item) {
    const final = carritoController.processMyEspObject(item, prodSizeShowing);
    /* si no agrego 4to parameter a addCart, defaultea a 1 unidad p/agregar */
    carritoController.addCart(final, dispatch, carrito);
  }
  //CON ESTE USEEFFECT DECIDO Q TAMAñO MOSTRAR, por lo gral el de + tamaño (por eso biggerProd), siempre y cuando HAYA STOCK
  //Aca EN PPIO no es necesario modificar NADA P/ la PROMOCION. Pero creo q para el filtro en searchParams sí voy a tener q agregar una pequeña modificacion
  //21/5/22: creo q si en searchParams hay una promo activa, tendría q mostrar el prod/tamaño q está en PROMO, tiene prioridad
  useEffect(() => {
    if (esp.length > 0) {
      if (searchParams.get("promocion")) {
        return setProdSizeShowing(esp.find((i) => i.promocion));
      }
      /* esta funcion la pasé a utils xq la usa tmb la SingleProdPage */
      setProdSizeShowing(findBiggerEspSize(esp));
    }
  }, [esp, searchParams]);

  return (
    <Container>
      <Info>
        <Header
          to={`${FRONTEND_ENDPOINTS.dynamicSingleProduct(_id)}#${
            HASH_LINKS.TOP
          }`}
        >
          <Marca>{marca}</Marca>
          <TituloNombre>
            {nombre} {prodSizeShowing?.tamaño}ml
          </TituloNombre>
        </Header>
        <ImgContainer
          onClick={() =>
            navigate(
              `${FRONTEND_ENDPOINTS.dynamicSingleProduct(_id)}#${
                HASH_LINKS.TOP
              }`
            )
          }
        >
          {prodSizeShowing?.promocion && (
            <>
              <PromoDisplay>{prodSizeShowing.descuento}% OFF</PromoDisplay>
            </>
          )}
          <ImgOverlay />
          <Img src={img}></Img>
          <TamañoSelectorOverlay>
            {esp.length > 0 &&
              esp.map((i) => (
                <TamañoIndividual
                  //CREO q no es necesario tampoco aca realizar cambios
                  onClick={(e) => {
                    e.stopPropagation();
                    setProdSizeShowing(i);
                  }}
                  active={i?.tamaño === prodSizeShowing?.tamaño}
                  key={i._id}
                >
                  {i.tamaño}ml
                </TamañoIndividual>
              ))}
          </TamañoSelectorOverlay>
        </ImgContainer>
        {/* ACA TENGO Q CHEQUEAR SI HAY O NO PROMOCION */}
        <PriceComponent {...prodSizeShowing} cart />
      </Info>
      <TamañoSelectorResponsive>
        {esp.length > 0 &&
          esp.map((i) => (
            <TamañoIndividualResponsive
              //CREO q no es necesario tampoco aca realizar cambios
              onClick={(e) => {
                e.stopPropagation();
                setProdSizeShowing(i);
              }}
              active={i?.tamaño === prodSizeShowing?.tamaño}
              key={i._id}
            >
              {i.tamaño}ml
            </TamañoIndividualResponsive>
          ))}
      </TamañoSelectorResponsive>
      {prodSizeShowing?.stock ? ( //CALCULO Q EN ADDCART PONGO EL PRODSIZESHOWING
        <>
          <Envio>
            Envío gratis a todo el país.
            <BsTruck />
          </Envio>
          <Devolucion>
            Devolución gratis por 30 días.
            <GiReturnArrow />
          </Devolucion>
          <MainButton
            fn={() => {
              addCart(props);
            }}
          >
            Comprar
          </MainButton>
        </>
      ) : (
        <>
          <br />
          <NoStock>No hay stock</NoStock>
        </>
      )}
    </Container>
  );
};

export function PriceComponent(props) {
  if (!props?.stock) {
    return <br />;
  }
  return (
    <FlexIt cartAndSingleProd={props?.cart}>
      {props?.promocion && (
        <PrecioPromo>
          $ {getPriceWithoutDecimals(props).toLocaleString()}
          <Decimals>{getDecimals(props)}</Decimals>
        </PrecioPromo>
      )}
      <Precio stock={props?.stock} promocion={props?.promocion}>
        $ {props?.precio && Math.floor(props?.precio)}
        {/* Paso true como 2nd parametro, p/q me mande full price, regardless of whether there is a promo */}
        <Decimals>{props?.precio && getDecimals(props, true)}</Decimals>
      </Precio>
    </FlexIt>
  );
}
