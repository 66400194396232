import React, { useEffect, useState } from "react";
import { BtnPagar, Spinner } from "components/styles/smallComponents";
import { Form, Input, InputControl, Label } from "pages/auth/data";
import styled from "styled-components";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { showOrCloseNotification } from "context/generalSlice";
import { marcasAPI } from "API/marcas";
import { dispatchNotification } from "utils/dispatchNotification";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ReactIcon = styled.span`
  font-size: 1.5rem;
  color: ${(props) => (props.delete ? "crimson" : "white")};
  background-color: ${(props) =>
    props.delete ? "black" : "rgba(0, 0, 0, 0.8);"};
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;
const MarcasContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
const BtnDelete = styled(BtnPagar)`
  width: fit-content;
`;
const CheckBox = styled.input``;
export const AdminMarcas = () => {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [marcas, setMarcas] = useState([]);
  const [editMarcas, setEditMarcas] = useState([]);
  const [marcasArray, setMarcasArray] = useState([{ value: "", id: 1 }]);
  useEffect(() => {
    marcasAPI.getMarcas(dispatch, setMarcas);
  }, [dispatch]);
  useEffect(() => {
    marcas.length > 0 &&
      setEditMarcas(
        marcas.map((i) => {
          return { marca: i, checked: false };
        })
      );
  }, [marcas]);
  function addNewEspItem() {
    return {
      id: Date.now(),
      value: "",
    };
  }
  function handleValueChange(e) {
    console.log(e.target.name, ":", e.target.value);
    setMarcasArray(
      marcasArray.map((i) =>
        i.id === Number(e.target.name) ? { ...i, value: e.target.value } : i
      )
    );
  }

  function createNewMarcas(e) {
    e.preventDefault();
    //check all createMarcas
    const marcasArrayToCreate = marcasArray.map((i) => i.value);
    marcasAPI
      .createMarcas(dispatch, marcasArrayToCreate)
      .then((data) => {
        setMarcas(data);
        dispatchNotification(
          dispatch,
          "Se crearon la(s) marca(s) exitosamente."
        );
      })
      .catch(() => {});
  }

  function handleDeleteMarca() {
    const updatedMarcasArray = editMarcas
      .filter((i) => i.checked)
      .map((i) => i.marca);

    if (
      window.confirm(
        `Estás seguro que querés eliminar ${updatedMarcasArray.length} marca(s)?`
      )
    ) {
      marcasAPI
        .deleteMarcas(dispatch, updatedMarcasArray)
        .then(setMarcas)
        .catch(() => {});
    }
  }

  return (
    <Center>
      <Form onSubmit={createNewMarcas}>
        <b>Podés agregar varias marcas a la vez con el botón inferior</b>
        <ReactIcon
          onClick={() => setMarcasArray([...marcasArray, addNewEspItem()])}
        >
          Agregar nuevo input de marca
          <AiOutlinePlus />
        </ReactIcon>
        {marcasArray.length > 0 &&
          marcasArray.map((i) => {
            const { id, value } = i;
            return (
              <InputControl key={id}>
                <Label htmlFor={id}></Label>
                <Input
                  as="input"
                  id={id}
                  value={value}
                  name={id}
                  placeholder="Nombre de la marca..."
                  onChange={handleValueChange}
                ></Input>
                <ReactIcon
                  delete
                  onClick={() => {
                    marcasArray.length === 1
                      ? dispatch(
                          showOrCloseNotification({
                            show: true,
                            message: "Como mínimo debe haber una marca.",
                          })
                        )
                      : setMarcasArray(marcasArray.filter((i) => i.id !== id));
                  }}
                >
                  <AiFillDelete />
                </ReactIcon>
              </InputControl>
            );
          })}

        <BtnPagar disabled={loading}>
          {loading ? <Spinner /> : "CREAR MARCAS"}
        </BtnPagar>
      </Form>
      <br />
      <h2>Eliminar marcas</h2>
      <b>
        Podés eliminar varias marcas a la vez, seleccioná las que quieras
        eliminar y apretá el botón inferior.
      </b>
      <br />
      <MarcasContainer>
        {editMarcas.length > 0
          ? editMarcas.map((i) => {
              const { marca, checked } = i;
              return (
                <Label key={marca} htmlFor={marca}>
                  <CheckBox
                    value={marca}
                    type="checkbox"
                    id={marca}
                    checked={checked}
                    onChange={(e) => {
                      setEditMarcas(
                        editMarcas.map((it) =>
                          it.marca === e.target.value
                            ? { ...it, checked: e.target.checked }
                            : it
                        )
                      );
                    }}
                  ></CheckBox>
                  {marca}
                </Label>
              );
            })
          : "No hay marcas"}
      </MarcasContainer>
      <br />
      <BtnDelete danger onClick={handleDeleteMarca} disabled={loading}>
        {loading ? <Spinner /> : "Borrar marcas"}
      </BtnDelete>
    </Center>
  );
};
