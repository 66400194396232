import { clearError, startAction } from "context/userSlice";
import { axiosPOSTLogin, axiosPRELogin } from "./axios";
import { errorHandler } from "./errorHandler";

import { BACKEND_ENDPOINTS } from "constants";

export const visitsAPI = {
  saveVisit: async function () {
    try {
      await axiosPRELogin.post(BACKEND_ENDPOINTS.VISITS);
    } catch (error) {
      //error handler
      console.log(error, "GEOIP");
    }
  },
  //VER SI USO LOADING GRAL O INDIVIDUAL
  getVisits: async function (dispatch, page) {
    dispatch(startAction());
    try {
      //NO HAY GENERAL STATE ACA, lo hacemos x component
      const { data } = await axiosPOSTLogin.get(
        `${BACKEND_ENDPOINTS.VISITS}?page=${page}`
      );
      dispatch(clearError());
      console.log(data, 666);
      return data;
    } catch (error) {
      errorHandler(error, dispatch, "GET ALL VISITS");
    }
  },
};
