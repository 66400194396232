import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAPI } from "API/auth";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import {
  Bottom,
  Form,
  Input,
  InputControl,
  Label,
  registerInputs,
  Submit,
  Title,
} from "./data";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";

export const Register = () => {
  const { error, loading, carrito } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [regInput, setRegInput] = useState({
    emailUsuario: "",
    nombre: "",
    contraseña: "",
    confirmaContraseña: "",
  });
  function handleSubmit(e) {
    e.preventDefault();
    authAPI
      .register(dispatch, regInput)
      .then(() => {
        const from =
          carrito.length === 1
            ? `${FRONTEND_ENDPOINTS.CHECKOUT}#${HASH_LINKS.TOP}`
            : carrito.length > 1
            ? `${FRONTEND_ENDPOINTS.CARRITO}#${HASH_LINKS.TOP}`
            : `${FRONTEND_ENDPOINTS.USER_PROFILE}#${HASH_LINKS.TOP}`;
        navigate(from, {
          replace: true,
        });
      })
      .catch(() => {});
  }
  return (
    <Form>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <Title>Registrarse</Title>
      {registerInputs?.map((i) => {
        const { type, id, label } = i;
        return (
          <InputControl key={id}>
            <Label htmlFor={id}>{label}</Label>
            <Input
              as="input"
              type={type}
              id={id}
              placeholder={label}
              name={id}
              value={regInput[id]}
              onChange={(e) =>
                setRegInput({ ...regInput, [e.target.name]: e.target.value })
              }
            ></Input>
          </InputControl>
        );
      })}
      <Submit onClick={handleSubmit} disabled={loading}>
        {loading ? <Spinner /> : "Registrarse"}
      </Submit>
      <Bottom>
        <AnimatedLink to={`${FRONTEND_ENDPOINTS.LOGIN}#${HASH_LINKS.TOP}`}>
          ¿Ya tenés cuenta? Iniciar sesión
        </AnimatedLink>{" "}
      </Bottom>
    </Form>
  );
};
