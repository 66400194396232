import styled from "styled-components";
import { BtnPagar } from "components/styles/smallComponents";
const emailUsuario = { type: "text", id: "emailUsuario", label: "Email *" };
const contraseña = {
  type: "password",
  id: "contraseña",
  label: "Contraseña *",
};
const confirmaContraseña = {
  type: "password",
  id: "confirmaContraseña",
  label: "Confirmá tu contraseña *",
};
export const forgotInputs = [
  { type: "text", id: "emailUsuario", label: "Escribí tu email." },
];
export const loginInputs = [emailUsuario, contraseña];
export const registerInputs = [
  { type: "text", id: "nombre", label: "Nombre *" },
  emailUsuario,
  contraseña,
  confirmaContraseña,
];
export const changeForgottenPwdInputs = [contraseña, confirmaContraseña];

/* ------------------------------------------------------------------------------- */

export const Form = styled.form`
  max-width: 1000px;
  margin: 0 auto;
  font-size: var(--fontSmall);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Title = styled.h3`
  padding: 20px 10px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
export const InputControl = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  padding: 10px;
`;
export const Input = styled(Label)`
  font-size: inherit;
  outline: 1px solid ${(props) => props.theme.border};
  &:focus {
    outline: 1px solid var(--mainBlue);
  }
  &:focus::placeholder {
    color: rgb(175, 168, 175);
  }
`;
export const Submit = styled(BtnPagar)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const Bottom = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SuccessRegister = styled.div`
  background-color: #d6ebd6;
  color: var(--mainGreen);
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  font-weight: 600;
  letter-spacing: 1px;
`;
export const Header = styled.h1`
  text-align: center;

  font-size: var(--fontBig);
`;
