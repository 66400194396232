import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAPI } from "API/profile";
import {
  AnimatedLink,
  BtnPagar,
  ErrorNotification,
  ScrollStyles,
  Spinner,
} from "components/styles/smallComponents";
import styled from "styled-components";
import { SingleCompra } from "pages/UserProfile/auxiliaries/SingleCompra";
import { authAPI } from "API/auth";
import {
  Contacto,
  ContactoContainer,
  Header,
} from "./auxiliaries/ContactoContainer";
import { AiOutlineLogout } from "react-icons/ai";
import HeaderTableCompras from "./auxiliaries/HeaderTable";
import { HASH_LINKS } from "constants";
import { ContainerGral1500 } from "components/SmallComponents/Containers";

export const ProfileSection = styled.section`
  display: flex;
  gap: 10px;
  font-size: var(--fontSmall);
  @media (max-width: 1000px) {
    display: block;
  }

  /* min-height: 100%; */
`;
export const ColumnFlex = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  /* separa info personal de historial de compras cuando estan en modo column */
  @media (max-width: 1000px) {
    padding-bottom: 20px;
  }
`;
export const TopPart = styled(ColumnFlex)`
  gap: 20px;
  flex: 1;
  gap: 20px;
`;

export const DeliveryAddressContainer = styled(ContactoContainer)``;
export const ComprasContainer = styled(ContactoContainer)`
  flex: 5;
  gap: 0px;
`;
export const NoPurchase = styled.td`
  padding-top: 30px;
  text-align: center;
`;
const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const ScrollContainer = styled.div`
  /*     display: flex;
  flex-direction: column; */
  position: relative;
  overflow-y: auto;
  /* la unica funcion de esto es mantener el logout abajo, NO ME GUSTA */
  max-height: 70vh;
  ${ScrollStyles}
`;

const BtnLogout = styled(BtnPagar)`
  background-color: inherit;
  color: ${(props) => props.theme.text};
  &:hover {
    color: white;
    background-color: ${(props) => props.theme.red};
  }
  font-size: inherit;
  letter-spacing: 2px;
  margin: 10px auto;
  border: 1px solid ${(props) => props.theme.red};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: max-content;
`;
const TopLink = styled(AnimatedLink)`
  display: inline-block;
  max-height: var(--fontSmall);
  margin-top: -10px;
  margin-bottom: 8px;
`;
export const UserProfile = () => {
  const { user, error, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    profileAPI.getProfile(dispatch);
  }, [dispatch]);

  return (
    <ContainerGral1500>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <TopLink to={`/#${HASH_LINKS.PROD}`} style={{ padding: "20px 0" }}>
        Continuar comprando
      </TopLink>
      <ProfileSection>
        <ColumnFlex>
          <TopPart style={{ flex: 1 }} className="flex1 topPart">
            <Contacto user={user} />
            {/* VER SI AGREGO ESTO DSP
                <DeliveryAddressContainer>
              <Header>Dirección de envío</Header>
            </DeliveryAddressContainer> */}
          </TopPart>
          <BtnLogout onClick={() => authAPI.logout(dispatch)}>
            Cerrar sesión <AiOutlineLogout />
          </BtnLogout>
        </ColumnFlex>
        <ComprasContainer>
          <Header>Historial compras</Header>
          <ScrollContainer>
            <Table>
              <HeaderTableCompras />
              <tbody>
                {loading && (
                  <tr>
                    <NoPurchase colSpan={5}>
                      <Spinner fz="5rem" h="30vh" />
                    </NoPurchase>
                  </tr>
                )}
                {loading ? undefined : user?.compras?.length > 0 ? (
                  user?.compras?.map((i) => {
                    //ver xq aca no me manda los id populated al ppio (asi como esta no parece tener problemas)
                    if (!i._id) {
                      return undefined;
                    }
                    return (
                      <SingleCompra key={i._id} prod={i} dispatch={dispatch} />
                    );
                  })
                ) : (
                  <tr>
                    <NoPurchase colSpan={5}>
                      No registrás compras aún
                    </NoPurchase>
                  </tr>
                )}
              </tbody>
            </Table>
          </ScrollContainer>
        </ComprasContainer>
      </ProfileSection>
    </ContainerGral1500>
  );
};
