import ReactDOM from "react-dom";
import { HashLink } from "react-router-hash-link";
import styled, { css } from "styled-components";
import { AiOutlineLoading, AiOutlineClose } from "react-icons/ai";

export const ScrollStyles = css`
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${(props) => props.theme.border};
  }
  &::-webkit-scrollbar {
    /* la width es p/scroll on the Y axis */
    width: 15px;
    /* la height es para horizontal scroll */
    height: 5px;
    background-color: ${(props) => props.theme.border};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(props) => props.theme.blue};
  }
`;

const AddToCart = styled.button`
  display: inline-block;
  font-size: ${(props) => (props.fz ? props.fz : "inherit")};
  padding: 25px;
  color: white;
  background-color: black;
  cursor: pointer;
  border: 1px solid #1b1b1b;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  width: 180px;
  height: 40px;
  pointer-events: all;
  &::after {
    transition: 0.3s;
    background-color: #1b1b1b;
    content: "";
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateX(-100%);
  }
  &:hover::after {
    transform: translateX(0);
  }
  /* ------------MODO RESPONSIVE EN SINGLE PRODUCTO, VER Q CAPAZ AFECTA OTROS COMPONENTS */
  @media (max-width: 600px) {
    font-size: var(--fontBig);
    width: 120px;
    padding: 0;
  }
`;
const BtnText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MainButton = ({ children, fn, fz }) => {
  return (
    <AddToCart fz={fz} onClick={fn}>
      <BtnText>{children}</BtnText>
    </AddToCart>
  );
};

export const BtnPagar = styled.button`
  margin-top: 5px;
  width: ${(props) => (props.text ? props.text.length + 3 + "ch" : "100%")};
  background-color: ${(props) => (props.danger ? props.theme.red : "#1f9c35")};
  border: 1px solid
    ${(props) => (props.danger ? props.theme.hoverRed : "#187829")};
  color: white;
  border-radius: 10px;
  font-size: calc(var(--fontMed) - 0.2rem);
  transition: 0.3s;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    background-color: ${(props) =>
      props.danger ? props.theme.hoverRed : " #187829"};
  }
  &:disabled {
    cursor: "not-allowed";
  }
`;
export const BtnLinked = styled(HashLink)`
  padding: 10px;
  background-color: ${({ bc }) => (bc ? bc : css`var(--mainGreen)`)};
  border: 1px solid
    ${({ border }) => (border ? border : css`var(--mainGreenHover)`)};
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: ${({ fz }) => (fz ? fz : css`var(--fontMed) - 0.2rem)`)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  &:hover {
    background-color: ${({ border }) =>
      border ? border : css`var(--mainGreenHover)`};
  }
`;
export const AnimatedLink = styled(HashLink)`
  font-size: inherit;
  margin-left: 5px;
  cursor: pointer;
  background-color: inherit;
  font-size: var(--fontSmall);
  color: ${(props) => (props.c ? props.c : props.theme.blue)};
  transition: 0.3s;
  position: relative;

  &:hover {
    color: ${(props) => (props.ch ? props.ch : props.theme.blueHover)};
  }
  &::after {
    background-color: ${(props) => (props.c ? props.c : props.theme.blue)};
    width: 0;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s;
  }
  &:hover::after {
    width: 100%;
    background-color: ${(props) =>
      props.ch ? props.ch : props.theme.blueHover};
  }
`;
export const ErrorNotification = styled.div`
  padding: ${(props) => (props.show ? "10px" : "0")};
  border-radius: 10px;
  color: #e62e0d;
  background-color: #fec8c0;
  font-size: var(--fontSmall);
  transition: 0.3s;
  position: fixed;
  z-index: 2000;
  max-width: 500px;
  top: 30vh;
  left: 30px;
  word-break: break-word;
  transform: ${(props) =>
    props.show ? "translateX(0)" : "translateX(-300vw)"};
  @media (max-width: 500px) {
    max-width: calc(100% - 20px);
    left: 10px;
  }
`;

const Preloader = styled.div`
  font-size: ${({ fz }) => (fz ? fz : css`calc(var(--fontMed) + .1rem)`)};
  height: ${({ h }) => (h ? h : "100%")};
  color: var(--mainBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    animation: spin 1s infinite linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
export const Spinner = ({ fz, h }) => {
  return (
    <Preloader fz={fz} h={h}>
      <AiOutlineLoading />
    </Preloader>
  );
};
const ReactIconClose = styled.button`
  position: absolute;
  top: 20px;
  right: ${(props) => (props.move ? "20px" : "none")};
  left: ${(props) => (props.move ? "none" : "20px")};

  border-radius: 50%;
  cursor: pointer;
  font-size: var(--fontMed);
  padding: 10px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #d7d7d7;
  }
`;

export const CloseButton = ({ f, move }) => {
  return (
    <>
      <ReactIconClose onClick={f} move={move}>
        <AiOutlineClose />
      </ReactIconClose>
    </>
  );
};
const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  background-color: ${(props) => props.theme.overlay};
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  /*----------------- SCROLL--------------------- */
  ${ScrollStyles}
`;
const Box = styled.div`
  width: 500px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  margin: 0 10px;
  max-height: 90vh;
  //en edit compra overlay uso esto p/pasar a la sig seccion
  overflow-x: hidden;
  ${ScrollStyles}
`;
export const OverlayStructure = ({ show, children, close }) => {
  return ReactDOM.createPortal(
    <Overlay
      show={show}
      id="greyOverlay"
      onClick={(e) => {
        if (close && e.target?.id === "greyOverlay") {
          close((v) => !v);
        }
      }}
    >
      <Box>{children}</Box>
    </Overlay>,
    document.getElementById("modal")
  );
};
