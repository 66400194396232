export const rest = [
  {
    id: "depto",
    type: "text",
    placeholder: "Piso/depto (opcional)",
    innerText: "Piso/Depto",
  },
  {
    id: "ciudad",
    type: "text",
    placeholder: "Ciudad*",
    innerText: "Ciudad",
    required: true,
    errorMsg: "Indicar ciudad",
  },
  {
    //SELECT no lleva placeholder ni type
    id: "provincia",
    innerText: "Provincia",
    select: true,
    required: true,
    errorMsg: "Seleccionar provincia",
  },
  {
    id: "CP",
    type: "text",
    placeholder: "Código postal*",
    innerText: "Código postal",
    required: true,
    errorMsg: "Indicar código postal",
  },
];

const row1 = [
  {
    id: "nombreDestinatario",
    type: "text",
    placeholder: "Nombre*",
    innerText: "Nombre de quien recibe",
    errorMsg: "Indicar nombre. Máximo 25 caracteres.",
    required: true,
  },
  {
    id: "apellidoDestinatario",
    type: "text",
    placeholder: "Apellido*",
    innerText: "Apellido de quien recibe",
    errorMsg: "Indicar apellido. Máximo 25 caracteres.",
    required: true,
  },
];

export const NOMBRE_DEST = row1[0].id;

export const APELLIDO_DEST = row1[1].id;

const row2 = [
  {
    id: "calle",
    type: "text",
    placeholder: "Calle/Avenida*",
    innerText: "Calle para el envío",
    required: true,
    errorMsg: "Indicar calle",
  },
  {
    id: "nro",
    type: "number",
    placeholder: "Número/altura*",
    innerText: "Número/altura",
    required: true,
    errorMsg: "Indicar altura",
  },
];

export const rows = [row1, row2];

export const fullData = [...row1, ...row2, ...rest];
