import { ADDRESS_STEP_PROVINCIA } from "constants";
import React from "react";
import styled, { css } from "styled-components";

const baseStyleErrors = css`
  border: 2px solid
    ${(props) => (props.error ? props.theme.red : props.theme.border)};

  padding: 10px;
`;
export const Label = styled.label`
  display: flex;
  flex: 1;
`;
export const Input = styled.input`
  font-size: inherit;
  width: 100%;
  ${baseStyleErrors}
`;
export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.red};
  font-size: calc(var(--fontSmall) - 0.1rem);
  font-weight: 400;
  padding-bottom: 5px;
`;
export const InputControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @media (max-width: 600px) {
    gap: 2px;
  }
`;
export const SelectProv = styled.select`
  cursor: pointer;
  font-size: inherit;

  ${baseStyleErrors}
`;
export const OptionProv = styled.option``;

export default function AddressInputLine(props) {
  const {
    id,
    type,
    placeholder,
    innerText,
    changeInput,
    inputsEnvio,
    prov,
    select,
    required,
    errors,
  } = props;
  if (!select) {
    return (
      <InputControl>
        <Label htmlFor={id}>{innerText}</Label>
        <Input
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={changeInput}
          value={inputsEnvio?.[id]}
          name={id}
          required={required}
          error={errors?.[id]}
        ></Input>
        {errors?.[id] && <ErrorMessage>{errors?.[id]}</ErrorMessage>}
      </InputControl>
    );
  }
  return (
    <InputControl>
      <Label htmlFor={id}>{innerText}</Label>
      <SelectProv
        id={id}
        onChange={changeInput}
        value={inputsEnvio?.[id]}
        name={id}
        required={required}
        error={errors?.[id]}
      >
        <OptionProv disabled hidden>
          {ADDRESS_STEP_PROVINCIA}
        </OptionProv>
        {prov?.length && prov.map((i) => <OptionProv key={i}>{i}</OptionProv>)}
      </SelectProv>
      {errors?.[id] && <ErrorMessage>{errors?.[id]}</ErrorMessage>}
    </InputControl>
  );
}
