import axios from "axios";
import { HEADERS_NAME, BACKEND_ROOT } from "constants";

/*------------------PRELOGIN AND POST LOGIN INSTANCE-----*/
//el withCredentials:true lo agrego solo con el api call a /refresh y /logout
const axiosPOSTLogin = axios.create({ baseURL: BACKEND_ROOT });
//el PRELogin necesita withCredentials:true, sino no se setea la cookie (bizarramente en Firefox se seteaba, pero en Chrome no andaba sin withCredentials)
const axiosPRELogin = axios.create({
  baseURL: BACKEND_ROOT,
  withCredentials: true,
});
/*---------------------------*/

function setHeaders(accessToken = "") {
  axiosPOSTLogin.defaults.headers[HEADERS_NAME] = accessToken;
  /*   console.log("cambiando headers: ", accessToken); */
}

export { axiosPOSTLogin, axiosPRELogin, setHeaders };
