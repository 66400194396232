import { changeShowCarrito } from "context/generalSlice";
import { addCarrito, removeItemCarrito } from "../context/userSlice";
import { dispatchNotification } from "./dispatchNotification";
import { getPriceWithDiscount, roundUpIn5With2Decimals } from "./mathFunctions";

export const carritoController = {
  processMyEspObject: function (originalItem, prodSizeShowing) {
    /* cambio la _id del esp object q el user quiere compra a _idTamaño */
    //aca va el precio y el descuento/promo (+tamaño, stock, la _id del tamaño  le cambié el nombre x las dudas)
    /* prodSizeShowing NO TIENE nombre, ni ninguna caracteristica gral del originalItem */
    const { _id: _idTamaño, ...rest } = prodSizeShowing;
    /* saco ESP del objeto q finalmente voy a agregar al carrito */
    const { esp, ...goodPart } = originalItem;
    return {
      ...goodPart,
      _idTamaño,
      //OJAZO Q REST TIENE Q IR SI O SI DSP de goodPart, en particular cuando el user refresca la pagina, asi mantengo el carrito actualizado
      ...rest,
    };
  },

  /* -------------------------------si no mando qty defaultea a 1 */
  addCart(final, dispatch, carrito, qty = 1, carritoPage) {
    /* FINAL VIENE SIN QTY SIEEEMPRE */
    const found = carrito.find((i) => i._idTamaño === final._idTamaño);
    if (!found) {
      dispatch(changeShowCarrito(true));

      return dispatch(addCarrito({ ...final, qty }));
    }
    /* CHEQUEO STOCK, y q no esté restando items del carrito (qty>0): DISPATCH NOTIFICATION SI FUERA NECESARIO*/
    if (found?.qty === final.stock && qty > 0) {
      dispatchNotification(
        dispatch,
        `Solo hay ${final.stock} ${
          final.stock === 1 ? "unidad" : "unidades"
        } en stock.`
      );
      return;
    }
    /* tengo q chequear q no haya exceso de stock?? tecnicamente ya lo hago con los botones pero nunca esta de + el doble control*/
    /* En SingleProdPage puedo agregar muchas unidades a la vez, si me excedo, agregar lo maximo q se pueda + notificar */
    if (found?.qty + qty > final.stock) {
      dispatchNotification(
        dispatch,
        `Solo hay ${final.stock} ${
          final.stock === 1 ? "unidad" : "unidades"
        } en stock.`
      );
      qty = final.stock - found.qty;
    }
    if (!carritoPage) {
      dispatch(changeShowCarrito(true));
    }

    return dispatch(addCarrito({ ...final, qty: found.qty + qty }));
  },

  deleteItemCart: function (item, dispatch) {
    dispatch(removeItemCarrito(item));
    //el useEffect se encarga de actualizar el local storage c/vez q modifico, elimino, etc, el carrito
  },

  getTotal: function (carrito) {
    const total = carrito.reduce((subt, i) => {
      if (i.promocion) {
        const discountedPrice = getPriceWithDiscount(i);
        subt += (i.qty * (discountedPrice * 10)) / 10;
        return subt;
      }
      subt += (i.precio * 10 * i.qty) / 10;
      return subt;
    }, 0);
    return roundUpIn5With2Decimals(total).toLocaleString(
      "es-AR" /* , {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      } */
    );
    /*       .toFixed(2)
      .toLocaleString(); */
  },
};
