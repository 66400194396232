import { createSlice /* , createAsyncThunk */ } from "@reduxjs/toolkit";
import { ADDRESS_STEP_PROVINCIA } from "constants";
const initialState = {
  user: null,
  loading: false,
  successRegister: false,
  /* -----COMPRA---------- */
  compra: {
    datosEnvio: {
      CP: "",
      nombreDestinatario: "",
      apellidoDestinatario: "",
      calle: "",
      nro: "",
      depto: "",
      ciudad: "",
      provincia: ADDRESS_STEP_PROVINCIA,
    },
    msjeClient: "",
    formaDePago: "transferencia",
    step: 0,
    borrar: true,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: initialState.user,
    loading: initialState.loading,
    successRegister: initialState.successRegister,
    error: false,
    carrito: [],
    totalProductos: 0,
    totalPaginatedPages: 1,
    productos: [],
    /* ----ONGOING PURCHARSE ITEMS---- */
    compra: initialState.compra,
  },
  reducers: {
    startAction: (state) => {
      state.loading = true;
      state.error = false;
      state.successRegister = false;
    } /* la usó cuando hay success en SingleProdPage getProduct */,
    clearError: (state) => {
      state.error = false;
      state.loading = false;
    },
    /* ----------ERROR------------------------ */
    failureCheckOut: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    successRegister: (state) => {
      state.loading = false;
      state.successRegister =
        "Te registraste exitosamente. Ahora podés iniciar sesión.";
    },
    /*---------CUANDO FETCHEO PROFILE USO esta tmb-*/
    successLogin: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    successLogout: (state) => {
      state.user = initialState.user;
      state.loading = initialState.loading;
      state.compra = initialState.compra;
      state.carrito = [];
      state.error = false;
    },

    /* ahora solo uso un update p/compra, antes cancelaba aparte x ej */
    successUpdateCompra: (state, action) => {
      state.user = {
        ...state.user,
        compras: state.user.compras.map((i) =>
          i._id === action.payload._id ? action.payload : i
        ),
      };
      state.loading = false;
    },

    addCarrito: (state, action) => {
      //onload/after refresh, I check storage for the carrito and if there is something, I will send the whole array, not an object
      if (action.payload instanceof Array) {
        state.carrito = action.payload;
        return;
      }

      const foundProd = state.carrito.find(
        (i) => i._idTamaño === action.payload._idTamaño
      );
      if (!foundProd) {
        state.carrito = [...state.carrito, action.payload];
        return;
      }

      const newCarrito = state.carrito
        .map((i) =>
          //antes comparaba _id y tamaño(porque si comparar solo _id puede haber el mismo prod con dos tamaños diferentes x ej, y entonces terminas aumentando la qty en ambos prod erroneamente) dsp me di cuenta q tenía la id del tamaño específico
          i._idTamaño === action.payload._idTamaño ? action.payload : i
        )
        .filter((i) => i.qty > 0);
      state.carrito = newCarrito;
    },

    removeItemCarrito: (state, action) => {
      state.carrito = state.carrito.filter(
        (i) => i._idTamaño !== action.payload._idTamaño
      );
    },

    //useEffect en APP.JSX garantiza q no se pierdan los datos al actualizar la pagina
    addDatosCompra: (state, action) => {
      const copia = { ...state.compra };
      state.loading = false;
      state.compra = { ...copia, ...action.payload };
    },

    //BORRAMOS EL CARRITO y compra y agregamos la _id de la compra al array de compras del user
    //El useEffect en APP.jsx se encarga de borrar el LSTORAGE
    successConfirmedOrder: (state, action) => {
      state.carrito = [];
      state.loading = false;
      state.compra = initialState.compra;
      state.user = {
        ...state.user,
        compras: [...state.user.compras, action.payload],
      };
    },

    successGetProducts: (state, action) => {
      state.productos = action.payload.productos;
      state.totalProductos = action.payload.total;
      state.totalPaginatedPages = action.payload.totalPaginatedPages;
      state.loading = false;
    },
    successDeleteFullProducto: (state, action) => {
      state.productos = state.productos.filter((prod) => {
        return prod._id !== action.payload;
      });
      state.loading = false;
    },
    deleteOrUpdateTamaño: (state, action) => {
      let finalProductosState = [];
      if (Array.isArray(action.payload)) {
        const copy = [...state.productos];
        action.payload.forEach((i) => {
          const found = copy.findIndex((prod) => prod._id === i._id);
          if (found >= 0) {
            copy[found] = i;
          }
        });
        finalProductosState = copy;
      } else {
        finalProductosState = state.productos.map((prod) => {
          return prod._id === action.payload._id ? action.payload : prod;
        });
      }
      state.productos = finalProductosState;
      state.loading = false;
    },
    successProdCreated: (state, action) => {
      state.productos = [action.payload, ...state.productos];
      state.loading = false;
    },
    successNewImage: (state, action) => {
      /*----VER SI ESTO VA BIEN--------*/
      state.productos = state.productos.map((prod) => {
        return prod._id === action.payload._id ? action.payload : prod;
      });
      state.loading = false;
    },
  },
  extraReducers: {},
});
export const {
  startAction,
  clearError,
  failureCheckOut,
  /* -----AUTH-------- */
  successRegister,
  successLogin,
  successLogout,
  /* ---carrito methods, ahora solo hay 1 add */
  addCarrito,
  removeItemCarrito,
  /* ---------------------------------------- */
  addDatosCompra,
  successConfirmedOrder,
  /* updating COMPRA */
  successUpdateCompra,
  /* -------------- */
  deleteOrUpdateTamaño,
  successDeleteFullProducto,
  successGetProducts,
  successProdCreated,
  successNewImage,
} = userSlice.actions;

export default userSlice.reducer;
