import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineHome,
  AiOutlineSearch,
} from "react-icons/ai";
import { BsLightbulbFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeShowCarrito, changeTheme } from "context/generalSlice";
import { moveSearchBar, SearchBar } from "components/SearchBar/SearchBar";
import { FRONTEND_ENDPOINTS } from "constants";
import { LSTORAGE_KEY_THEME } from "constants";
import { HASH_LINKS } from "constants";

export const WrapIt = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* si no pones background-color es como si fuera transparente y el searchbar en modo celu no se esconde */
  background-color: inherit;
  height: inherit;
  width: 100%;
  padding: 0 300px;

  @media (max-width: 1700px) {
    padding: 0 100px;
  }
  @media (max-width: 1200px) {
    padding: 0 100px;
  }
  @media (max-width: 800px) {
    padding: 0 30px;
  }
  @media (max-width: 400px) {
    padding: 0 10px;
  }
`;
export const NavBar = styled.nav`
  height: ${(props) => props.navHeight};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  transition: 0.2s;
  transform: ${(props) =>
    props.show ? "translateY(-100%);" : "translateY(0);"};
`;
export const AbsoluteDiv = styled.div`
  height: ${(props) => props.navHeight};
  transition: 0.3s;
  @media (max-width: ${moveSearchBar}px) {
    margin-bottom: ${(props) => (props.showSearchBar ? "60px" : "0")};
  }
`;
export const ImgContainer = styled.div`
  position: relative;
  border: 1px solid inherit;
  transition: 0.3s;
  cursor: pointer;
  height: 95%;
  transition: 0.3s;
  border-radius: 10px;
  overflow: hidden;
`;
export const Snow = styled.img`
  position: absolute;
`;
export const ImgLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Logo = styled.img`
  object-fit: cover;
  height: 100%;
  width: 150px;
  /* ---------RESPONSIVE IMAGE---------- */
  @media (max-width: 520px) {
    width: 120px;
  }
  @media (max-width: 400px) {
    width: 80px;
    height: auto;
  }
`;

export const Iconos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  transition: 0.3s;
`;
export const SingleItem = styled(Link)`
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;
export const ShowCarrito = styled.div`
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;
const TextIcon = styled.span`
  text-transform: uppercase;
  /* dont display text, just logos */
  @media (max-width: ${moveSearchBar}px) {
    display: none;
  }
`;
const makeIconsSmaller = 1.6;
export const ReactIcon = styled.span`
  font-size: var(--fontLarge);
  position: relative;
  @media (max-width: 520px) {
    font-size: ${makeIconsSmaller}rem;
  }
`;
const ReactIconLupa = styled(SingleItem)`
  font-size: var(--fontLarge);
  @media (min-width: ${moveSearchBar}px) {
    display: none;
  }
  @media (max-width: 520px) {
    font-size: ${makeIconsSmaller}rem;
  }
`;
const ItemsBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -7px;
  background-color: crimson;
  color: white;
  font-size: var(--fontSmall);
  height: calc(var(--fontSmall) + 0.3rem);
  min-width: calc(var(--fontSmall) + 0.3rem);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  @media (max-width: 520px) {
    height: 18px;
    min-width: 18px;
    font-size: 0.8rem;
  }
`;
export const Banner = styled.div`
  background-color: #1e1e1e;
  text-align: center;
  padding: 10px;
  color: white;
  font-size: var(--fontSmall);
`;
const Light = styled.div`
  position: absolute;
  top: 0;
`;

export const navHeight = "70px";

export const Nav = () => {
  const {
    user: { carrito },
    general: { letThereBeLight, showCarrito },
    user: { user },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    const x =
      carrito.length > 0
        ? carrito.reduce((final, i) => {
            return (final += i.qty);
          }, 0)
        : 0;
    setTotalItems(x);
  }, [carrito]);

  /* SEARCH BAR OPEN + CLOSE */
  const [showSearchBar, setShowSearchBar] = useState(false);
  /* lo tengo q mandar a los props de SearchBar p/ tener al input de referencia y poder mandar focus al abrir el searchBar*/
  const focusBar = useRef();
  /* intenté poner el focus() en la misma funcion donde cambio el estado del showSearchBar pero no funcaba, tuve q armar este useEffect.Ya me fijé y la razón x la q no pude es xq hay un cambio de visibility. Por eso tengo q esperar al posrenderizado p/armar el focus 
  UPDATE: x alguna razon funciona solo en el 1er renderizado, dsp se pone bizarro y no entiendo xq*/
  useEffect(() => {
    if (showSearchBar) {
      focusBar.current.focus();
    }
  }, [showSearchBar]);

  /* --------------LSTORAGE DARK THEME-------------------- */
  useEffect(() => {
    const maybe = localStorage.getItem(LSTORAGE_KEY_THEME);
    if (maybe) {
      const theme = JSON.parse(maybe);
      if (theme) {
        dispatch(changeTheme());
      }
    }
  }, [dispatch]);

  return (
    <>
      <NavBar show={showCarrito} navHeight={navHeight}>
        <WrapIt>
          <ImgContainer>
            <ImgLink to={FRONTEND_ENDPOINTS.HOME}>
              {/* SNOW EFFECT CHRISTMAS   <Snow src="https://media1.giphy.com/media/26BRyql7J3iOx875u/giphy.gif?cid=ecf05e47rpt4t9qvbqkbzto5lf43ohqa8b2zvy7h2ukttj0w&rid=giphy.gif&ct=s"></Snow> */}
              <Logo src="/img/logo.jpeg" />
            </ImgLink>
          </ImgContainer>

          <SearchBar
            showSearchBar={showSearchBar}
            setShowSearchBar={setShowSearchBar}
            navHeight={navHeight}
            focusBar={focusBar}
          />
          <Iconos>
            <ReactIconLupa
              as="div"
              onClick={() => {
                setShowSearchBar(!showSearchBar);
              }}
            >
              <AiOutlineSearch />
            </ReactIconLupa>
            <SingleItem to={FRONTEND_ENDPOINTS.HOME}>
              <ReactIcon>
                <AiOutlineHome />
              </ReactIcon>
              <TextIcon>Inicio</TextIcon>
            </SingleItem>

            <SingleItem
              to={
                user?.admin
                  ? `${FRONTEND_ENDPOINTS.ADMIN}#${HASH_LINKS.TOP}`
                  : `${FRONTEND_ENDPOINTS.USER_PROFILE}#${HASH_LINKS.TOP}`
              }
            >
              <ReactIcon>
                <AiOutlineUser />
              </ReactIcon>
              <TextIcon>Usuario</TextIcon>
            </SingleItem>
            <ShowCarrito
              onClick={() => {
                /* si no sacar la searchBar anda igual, es una cuestion de prolijidad */
                setShowSearchBar(false);
                dispatch(changeShowCarrito());
              }}
            >
              <ReactIcon>
                <AiOutlineShoppingCart />
                <ItemsBadge>{totalItems}</ItemsBadge>
              </ReactIcon>
              <TextIcon>Compras</TextIcon>
            </ShowCarrito>
            <ReactIcon
              onClick={() => {
                localStorage.setItem(
                  LSTORAGE_KEY_THEME,
                  JSON.stringify(!letThereBeLight)
                );
                dispatch(changeTheme());
              }}
            >
              <BsLightbulbFill
                style={{ color: `${letThereBeLight ? "#e6e8bb" : "white"}` }}
              />
              {letThereBeLight && (
                <Light
                  style={{
                    boxShadow:
                      " 0 0 90px 20px #56bcf9, 35px 20px 40px 10px #fff, -30px -30px 40px 5px #fff",
                  }}
                />
              )}
            </ReactIcon>
          </Iconos>
        </WrapIt>
      </NavBar>
      <AbsoluteDiv navHeight={navHeight} showSearchBar={showSearchBar} />
      <Banner>Envíos gratis a todo el país | Comprá online.</Banner>
    </>
  );
};
