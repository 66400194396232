import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { productosAPI } from "API/productos";
import { AiOutlineSearch } from "react-icons/ai";
import { FRONTEND_ENDPOINTS, HASH_LINKS } from "constants";
import { ScrollStyles } from "components/styles/smallComponents";
import { HashLink } from "react-router-hash-link";

export const moveSearchBar = 1300;

const RelativeInputControl = styled.div`
  /* este position relative ES necesario, p/mostrar las suggestions cuando no esta en modo celu */
  position: relative;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  font-size: var(--fontSmall);
  @media (max-width: 1800px) {
    /* le saco width a la search bar p/q sea + responsive */
    width: 400px;
  }
  @media (max-width: ${moveSearchBar}px) {
    transition: visibility 0.25s ease-in-out, margin-top 0.25s ease-in-out;
    /* terminé sacando visibility animation xq estaba jodiendo con el focus del input */
    /*     visibility: ${(props) => (props.show ? "visible" : "hidden")}; */
    background-color: inherit;
    /* no sacar z-index */
    z-index: -1;
    margin-top: ${(props) => (props.show ? "0" : `-${props.navHeight}`)};
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100% - 0.3rem);
    padding: 10px 20px;
  }
`;
const PreviewPerfumesContainer = styled.div`
  position: absolute;
  top: 101%;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  border: 0 solid ${(props) => props.theme.border};
  border-width: ${(props) => props.showBorder && "1px"};
  z-index: 1000;
  max-height: 50vh;
  ${ScrollStyles}
`;
const Label = styled.label`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  @media (min-width: 800px) {
    max-width: 800px;
  }
`;
const InputNombreSearch = styled.input`
  width: 100%;
  font-size: var(--fontSmall);
  padding: 10px calc(15px + var(--fontBig)) 10px 20px;
  border-radius: 20px;
  &:focus::placeholder {
    color: rgb(175, 168, 175);
  }
  &:focus {
    border: 2px solid ${(props) => props.theme.blue};
  }
`;
const SearchIcon = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: black;
  right: 15px;
  font-size: var(--fontBig);
`;
const SingleSuggestion = styled(HashLink)`
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: calc(var(--fontSmall) - 0.3rem);
  /* p/el cerrar */
  min-height: 45px;
  cursor: pointer;
  & > img {
    height: 45px;
    width: 45px;
  }
  &:hover {
    font-weight: bold;
    background-color: ${(props) => props.theme.border};
  }
`;
export const SearchBar = ({
  showSearchBar,
  setShowSearchBar,
  navHeight,
  focusBar,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState({ nombre: "", noResultsFound: false });
  function handleValueChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    async function callAPIshowSuggestionsProds(inp) {
      const prods = await productosAPI.getProdsToShowSuggestions(inp);
      setSuggestions(prods);
      /* si no hubo resultados, ponemos noResultsFound TRUE p/q aparezca el cartelito informando al user q se hizo la busqueda y no volvio nada.Sino hiciera esto, es como q nunca le informo q pasó */
      !prods.length && setInput({ ...input, noResultsFound: true });
    }
    if (!input.nombre.length) {
      /* reseteo noResultFound p/q no se muestre el cartelito */
      setInput((v) => ({ ...v, noResultsFound: false }));
      return setSuggestions([]);
    }
    if (input.nombre.length < 2) {
      /* reseteo noResultFound p/q no se muestre el cartelito */
      setInput((v) => ({ ...v, noResultsFound: false }));
      return;
    }
    let id;
    if (input.nombre.length > 2) {
      id = setTimeout(() => callAPIshowSuggestionsProds(input.nombre), 500);
    }
    return () => {
      if (id) clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.nombre]);
  /* MOSTRAR O NO EL CONTAINER DE SUGGESTIONS */
  const displayNone = useRef();
  function dis(e) {
    //17/9/22: lo bajé a 200s y parecia q andaba, dsp dejo de andar
    setTimeout(() => {
      displayNone.current.style.display = e.type === "blur" ? "none" : "block";
    }, 300);
  }
  return (
    <RelativeInputControl show={showSearchBar} navHeight={navHeight}>
      {/* TIENE UNA LABEL EL ADMIN */}
      <Label htmlFor="searching">
        <InputNombreSearch
          type="text"
          id="searching"
          placeholder="Buscar productos"
          name="nombre"
          value={input.nombre}
          onChange={handleValueChange}
          onBlur={dis}
          onFocus={dis}
          autoComplete="off"
          ref={focusBar}
        ></InputNombreSearch>
        <SearchIcon>
          <AiOutlineSearch />
        </SearchIcon>
      </Label>
      <PreviewPerfumesContainer
        ref={displayNone}
        showBorder={suggestions.length}
      >
        {suggestions?.length > 0 && (
          <>
            {/* si son pocos resultados no tiene sentido poner 2 cerrar */}
            {suggestions?.length > 5 && (
              <SingleSuggestion as="div" style={{ padding: "0 10px" }}>
                Cerrar
              </SingleSuggestion>
            )}
            {suggestions.map((i) => (
              <SingleSuggestion
                to={`${FRONTEND_ENDPOINTS.dynamicSingleProduct(i._id)}#${
                  HASH_LINKS.TOP
                }`}
                key={i._id}
              >
                <img src={`${i.img}`} alt={i.nombre}></img>
                <span>{i.nombre}</span>
              </SingleSuggestion>
            ))}
            <SingleSuggestion as="div" style={{ padding: "0 10px" }}>
              Cerrar
            </SingleSuggestion>
          </>
        )}
        {!suggestions?.length && input.noResultsFound && (
          <SingleSuggestion as="div" style={{ padding: "0 10px" }}>
            No se encontraron resultados
          </SingleSuggestion>
        )}
      </PreviewPerfumesContainer>
    </RelativeInputControl>
  );
};
