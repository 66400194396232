/* import { useDispatch } from "react-redux"; */
import styled, { css } from "styled-components";
import { getPriceAndDecimalsInTheSameDiv } from "utils/mathFunctions";

export const SingleItem = styled.div`
  padding: 2px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: calc(var(--fontSmall) - 0.2rem);
`;
export const Flex1 = styled.div`
  flex: 1;
  display: flex;
  gap: 5px;
`;
export const ImgContainer = styled.div`
  width: 100px;
  height: 110px;
  position: relative;
  @media (max-width: 400px) {
    width: 75px;
    height: 75px;
  }
`;
export const PromoDisplay = styled.div`
  color: white;
  background-color: var(--mainPink);
  font-size: calc(var(--fontSmall) - 0.2rem);
  bottom: 0;
  position: absolute;
  content: "";
  padding: 3px;
  border-radius: 2px;
  //originalmente tenía 1000, pero se superponía con el cellphone menu
  z-index: 1;
`;
export const Quantity = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
`;
export const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const Total = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.secondaryText};
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const Precio = styled.div`
  font-weight: ${(props) => props.promocion && "300"};
  text-decoration: ${(props) => props.promocion && "line-through"};
  font-size: ${(props) =>
    props.promocion && css`calc(var(--fontSmall) - .1rem)`};
`;
const PrecioPromo = styled.div``;

export const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;
  text-align: left;
`;

export const ProductoNombre = styled.div`
  font-weight: bold;
`;

export const Marca = styled.div``;
export const ProductoCantidad = styled.div`
  letter-spacing: 2px;
`;

export const SingleCheckOutItem = (i) => {
  return (
    <SingleItem>
      <Flex1>
        <ImgContainer>
          {/* Si esta en PROMO mostramos el cartelito */}
          {i?.promocion && (
            <>
              <PromoDisplay>{i.descuento}% OFF</PromoDisplay>
            </>
          )}
          <Quantity>{i.qty}</Quantity>
          <Img src={i.img} />
        </ImgContainer>
        <Center>
          <Marca>{i.marca}</Marca>
          <ProductoNombre>{`${i.nombre} ${i.tamaño}ml`}</ProductoNombre>
          <ProductoCantidad>x{i.qty}</ProductoCantidad>
        </Center>
      </Flex1>

      <Total>
        <Precio stock={i?.stock} promocion={i?.promocion}>
          ${getPriceAndDecimalsInTheSameDiv(i, true)}
        </Precio>
        {i?.promocion && (
          <PrecioPromo>${getPriceAndDecimalsInTheSameDiv(i)}</PrecioPromo>
        )}
      </Total>
    </SingleItem>
  );
};
