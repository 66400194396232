import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { turnSearchParamsIntoObject } from "utils/randomFunctions";

const PaginateMyLife = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  @media (max-width: 1090px) {
    display: ${(props) => props.disappear && "none"};
  }
`;
const Btn = styled.button`
  height: 45px;
  width: 45px;
  border-radius: 5px;
  font-size: var(--fontSmall);
  font-weight: bold;
  border: 1px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.active && props.theme.blue};
  color: ${(props) => props.active && "white"};
  cursor: ${(props) => (props.forbidden ? "not-allowed" : "pointer")};
  &:disabled {
    background-color: #333;
    color: white;
  }
  &:hover:enabled {
    background-color: ${(props) => !props.active && "#c7d6e9"};
  }
  @media (max-width: 600px) {
    height: 28px;
    width: 28px;
  }
`;
let secondRenderOrMore = false;
export const Pagination = ({ disappear }) => {
  const { totalPaginatedPages } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();

  function render() {
    let middle = [];
    for (let ind = page - 3; ind <= totalPaginatedPages; ind++) {
      let currentValue = ind;
      if (ind === page - 3) {
        middle.push(
          <Btn
            onClick={() =>
              setSearchParams({
                ...turnSearchParamsIntoObject(searchParams),
                page: currentValue - 1,
              })
            }
          >
            ...
          </Btn>
        );
      }
      middle.push(
        <Btn
          onClick={() =>
            setSearchParams({
              ...turnSearchParamsIntoObject(searchParams),
              page: currentValue,
            })
          }
          active={currentValue === page}
        >
          {currentValue}
        </Btn>
      );
    }
    return middle.map((i, ind) => (
      <React.Fragment key={ind}>{i}</React.Fragment>
    ));
  }

  const page = +searchParams.get("page") || 1;

  //fixed bug where if im in page 11 and change a filter to fetch prods that have less pages, I wouldnt automatically switch to the last page
  if (totalPaginatedPages && totalPaginatedPages < page && secondRenderOrMore) {
    setSearchParams({
      ...turnSearchParamsIntoObject(searchParams),
      page: totalPaginatedPages,
    });
  }
  secondRenderOrMore = true;

  return (
    <PaginateMyLife disappear={disappear}>
      {/*--------------------------- PRIMER BOTON----------------------------- */}
      <Btn
        disabled={page <= 1}
        forbidden={page <= 1}
        onClick={() =>
          setSearchParams({
            ...turnSearchParamsIntoObject(searchParams),
            page: page - 1,
          })
        }
      >
        &#10094;
      </Btn>
      {/*-------------------------BOTONES DEL MEDIO-------------------------- */}
      {/* MENOS DE 5 TOTAL PAGINATED PAGES, es el caso + simple */}
      {totalPaginatedPages <= 5 &&
        [...Array(+totalPaginatedPages)].map((_, ind) => (
          <Btn
            onClick={() =>
              setSearchParams({
                ...turnSearchParamsIntoObject(searchParams),
                page: ind + 1,
              })
            }
            active={ind + 1 === page}
            key={ind}
          >
            {ind + 1}
          </Btn>
        ))}
      {/* MAS DE 5 TOTAL PAGINATED PAGES, aca es donde hay mas lógica*/}
      {totalPaginatedPages > 5 && (
        <>
          {/* page ya avanzada, en las ultimas, sobre el final de la straw */}
          {totalPaginatedPages - page < 4 ? (
            <>
              <FirstBtn
                setSearchParams={setSearchParams}
                searchParams={searchParams}
              />
              {render()}
            </>
          ) : (
            [...Array(4)].map((_, ind) => {
              let currentValue = page + ind;
              let result = (
                <Btn
                  onClick={() =>
                    setSearchParams({
                      ...turnSearchParamsIntoObject(searchParams),
                      page: currentValue,
                    })
                  }
                  active={currentValue === page}
                  key={ind}
                >
                  {currentValue}
                </Btn>
              );
              if (currentValue === page && page > 4) {
                return (
                  <React.Fragment key={ind}>
                    <FirstBtn
                      setSearchParams={setSearchParams}
                      searchParams={searchParams}
                    />
                    <Btn
                      onClick={() =>
                        setSearchParams({
                          ...turnSearchParamsIntoObject(searchParams),
                          page: currentValue - 1,
                        })
                      }
                    >
                      ...
                    </Btn>
                    {result}
                  </React.Fragment>
                );
              }
              if (
                ind !== 3 ||
                (ind === 3 && currentValue === totalPaginatedPages)
              ) {
                return result;
              }
              return (
                <React.Fragment key={ind}>
                  {result}
                  <Btn
                    onClick={() =>
                      setSearchParams({
                        ...turnSearchParamsIntoObject(searchParams),
                        page: currentValue + 1,
                      })
                    }
                  >
                    ...
                  </Btn>
                  <Btn
                    onClick={() =>
                      setSearchParams({
                        ...turnSearchParamsIntoObject(searchParams),
                        page: totalPaginatedPages,
                      })
                    }
                  >
                    {totalPaginatedPages}
                  </Btn>
                </React.Fragment>
              );
            })
          )}
        </>
      )}

      {/* ULTIMA BOTON */}
      <Btn
        disabled={page >= totalPaginatedPages}
        forbidden={page >= totalPaginatedPages}
        onClick={() =>
          setSearchParams({
            ...turnSearchParamsIntoObject(searchParams),
            page: page + 1,
          })
        }
      >
        &#10095;
      </Btn>
    </PaginateMyLife>
  );
};

const FirstBtn = ({ setSearchParams, searchParams }) => {
  return (
    <Btn
      onClick={() =>
        setSearchParams({
          ...turnSearchParamsIntoObject(searchParams),
          page: 1,
        })
      }
    >
      1
    </Btn>
  );
};
