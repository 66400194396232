import React, { useState } from "react";
import { FlexIt, Form } from "./styled";
import { Input } from "pages/auth/data";
import { BtnPagar, Spinner } from "components/styles/smallComponents";
import { dispatchNotification } from "utils/dispatchNotification";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { preguntasAPI } from "API/preguntas";

const SubmitPregBtn = styled(BtnPagar)`
  flex-basis: auto;
  flex-grow: 0;
  display: flex;
  justify-content: center;
`;
const submitText = "Responder";

function AdminReplyForm({ handleUpdatePreg, pregID }) {
  const [replyInput, setReplyInput] = useState("");

  const dispatch = useDispatch();
  const [indLoading, setIndLoading] = useState(false);
  const handleSubmitReply = (e) => {
    e.preventDefault();
    if (window.confirm(`Confirmá tu rta antes de enviarla: "${replyInput}"`)) {
      setIndLoading(true);
      preguntasAPI
        .updatePreg(pregID, { reply: replyInput })
        .then(({ data: newPreg }) => {
          dispatchNotification(dispatch, "Operación exitosa.");
          setReplyInput("");
          handleUpdatePreg(newPreg);
        })
        .catch(() => {})
        .finally(() => setIndLoading(false));
    }
  };

  return (
    <Form onSubmit={handleSubmitReply}>
      <FlexIt dontfold>
        <Input
          style={{ width: "100%" }}
          as="input"
          type="text"
          placeholder="Respondele al comprador"
          value={replyInput}
          onChange={(e) => setReplyInput(e.target.value)}
        />
        <SubmitPregBtn danger text={submitText} disabled={indLoading}>
          {indLoading ? <Spinner /> : submitText}
        </SubmitPregBtn>
      </FlexIt>
    </Form>
  );
}

export default AdminReplyForm;
