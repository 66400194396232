import React, { useEffect, useState } from "react";
import {
  CloseButton,
  OverlayStructure,
  Spinner,
} from "components/styles/smallComponents";
import styled from "styled-components";
import { AiFillCreditCard } from "react-icons/ai";
import { comprasAPI } from "API/compras";
import { useDispatch } from "react-redux";
import { errorHandler } from "API/errorHandler";
import { successUpdateCompra } from "context/userSlice";
import DisplayAddress from "pages/checkout/auxiliaries/DisplayAddress";
import { fullData } from "pages/checkout/auxiliaries/steps/addressData";
import AddressInputLine from "../../../components/SmallComponents/AddressInputLine";
import { dispatchNotification } from "utils/dispatchNotification";
import {
  Btn,
  HeaderItem,
  Line,
  MainHeader,
  SubContainer,
} from "pages/admin/auxiliaries/OverlayStyledComp";

export const MPImg = styled.img`
  width: 100%;
  height: 40px;
  object-fit: contain;
  padding-right: 5px;
`;
const ContainerFormasPago = styled.fieldset`
  border: 1px solid ${(props) => props.theme.border};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

export const SingleFormaPago = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border};
  width: 100%;
`;
export const Label = styled.label`
  position: relative;
  cursor: pointer;
  padding: 10px 12px 12px 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    border: 3px solid ${(props) => props.theme.text};
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  &::after {
    background-color: var(--mainBlue);
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    transition: 0.3s;
    transform: scale(0);
  }
  & > span {
    display: flex;
    align-items: center;
  }
  /* img de MP y transferencia / responsiveness */
  & span:nth-child(2) {
    @media (max-width: 450px) {
      margin: 0;
      width: 50px;
      height: 30px;
    }
    @media (max-width: 380px) {
      display: none;
    }
  }
`;

export const RadioButton = styled.input`
  display: none;
  &:checked + ${Label}::after {
    transform: scale(1);
  }
`;

export const Modifier = styled.div`
  padding: 10px;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => props.theme.red};
  font-weight: bold;
`;

const FormNota = styled.textarea`
  width: 100%;

  padding: 10px;
  font-size: inherit;
  &:focus {
    border: 1px solid #1f9c35;
  }
`;
export const FlexIt = styled.div`
  display: flex;
  overflow: hidden;
`;
export const Section = styled.section`
  transition: 0.3s;
  flex-shrink: 0;
  width: 100%;
  transform: ${(props) =>
    props.editAddress ? "translateX(-100%)" : "translateX(0)"};
`;
export const FlexColumn = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const EditCompraOverlay = ({
  openEditOverlay,
  setOpenEditOverlay,
  prod,
}) => {
  const dispatch = useDispatch();
  const { formaDePago, /* productos, */ _id, msjeClient, datosEnvio } = prod;

  const [isEditing, setIsEditing] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  //si usaba el loading gral, se me cerraba el Overlay automaticamente, ya q en SingleCompra tengo conditional rendering con ese mismo loading, x eso independicé
  const [indLoading, setLoading] = useState(false);
  const [form, setForm] = useState(formaDePago);
  const [newMsg, setNewMsg] = useState(msjeClient);
  async function handleSubmit(e) {
    e.stopPropagation();

    let diff = true;

    for (const key in datosEnvio) {
      if (
        datosEnvio[key].toString().toUpperCase() !==
        inputsEnvio[key].toString().trim().toUpperCase()
      ) {
        diff = false;
      }
    }

    /* SI NO CAMBIé nada, no hago call al backend */
    if (formaDePago === form && msjeClient === newMsg && diff) {
      setOpenEditOverlay(false);
      return;
    }
    setLoading(true);
    try {
      //usé la API call asi, x lo q explico arriba, necesito individual loading
      const { data } = await comprasAPI.updateCompraR({
        ...prod,
        formaDePago: form,
        msjeClient: newMsg,
        datosEnvio: inputsEnvio,
      });
      dispatch(successUpdateCompra(data));
      dispatchNotification(dispatch, "Se actualizó exitosamente");
      /* CLOSE, es clave cerrar ya q cuando mando el prod de vuelta, no hago populate, entonces se pierden las imagenes */
      setOpenEditOverlay((v) => !v);
      /* if in 2nd section, reverse back to first */
      setEditAddress(false);
    } catch (error) {
      errorHandler(
        error,
        dispatch,
        "EDITCOMPRAOVERLAY.jsx individual API PUT CALL"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setForm(formaDePago);
    setNewMsg(msjeClient);
    setInputsEnvio(datosEnvio);
  }, [formaDePago, msjeClient, datosEnvio]);

  function handleChange(e) {
    setForm(e.target.value);
  }

  function backToNormal() {
    /* inputs back to normal on 1st section */
    setIsEditing(false);
    /* if in 2nd section, reverse back to first */
    setEditAddress(false);
    /* reset all states */
    setForm(formaDePago);
    setNewMsg(msjeClient);
    setInputsEnvio(datosEnvio);
    /* close EDIT OVERLAY */
    setOpenEditOverlay((v) => !v);
  }

  /* ------------------2DA SECCION----------------------- */
  const [prov, setProv] = useState([]);
  useEffect(() => {
    async function getProvEnums() {
      try {
        const { data } = await comprasAPI.getProv();
        setProv(data);
      } catch (error) {
        console.log(error);
      }
    }
    getProvEnums();
  }, []);
  const [inputsEnvio, setInputsEnvio] = useState(datosEnvio);

  function changeInput({ target }) {
    setInputsEnvio({ ...inputsEnvio, [target.name]: target.value });
  }

  return (
    <tr>
      <td>
        <OverlayStructure show={openEditOverlay} close={setOpenEditOverlay}>
          <MainHeader>
            <CloseButton
              f={(e) => {
                e.stopPropagation();
                backToNormal();
              }}
            />
            Editar compra
          </MainHeader>
          <Line />
          <FlexIt>
            <Section editAddress={editAddress}>
              <SubContainer>
                <ContainerFormasPago>
                  <HeaderItem>Forma de pago</HeaderItem>
                  {/* MERCADO PAGO */}
                  <SingleFormaPago>
                    <RadioButton
                      type="radio"
                      name={"formaDePago" + _id}
                      value="MP"
                      id="MP"
                      onChange={handleChange}
                      checked={"MP" === form}
                    ></RadioButton>
                    <Label htmlFor="MP">
                      <span>Mercado Pago </span>
                      <span>
                        <MPImg
                          src="/img/MP.png"
                          alt="Opción de pago, Mercado Pago"
                        />
                      </span>
                    </Label>
                  </SingleFormaPago>
                  {/* WIRETRANSFER */}
                  <SingleFormaPago>
                    <RadioButton
                      checked={"transferencia" === form}
                      type="radio"
                      name={"formaDePago" + _id}
                      value="transferencia"
                      id="transferencia"
                      onChange={handleChange}
                    ></RadioButton>
                    <Label htmlFor="transferencia">
                      <span>Transferencia bancaria/depósito </span>
                      <span>
                        <AiFillCreditCard
                          style={{ fontSize: "2.5rem", marginRight: "5px" }}
                        />
                      </span>
                    </Label>
                  </SingleFormaPago>
                </ContainerFormasPago>
              </SubContainer>
              <SubContainer>
                <ContainerFormasPago>
                  <HeaderItem>Mensaje para el vendedor</HeaderItem>

                  {isEditing ? (
                    <FormNota
                      rows={2}
                      autoFocus
                      onChange={(e) => setNewMsg(e.target.value)}
                      value={newMsg}
                    ></FormNota>
                  ) : (
                    <SubContainer as="i">{newMsg || "---"}</SubContainer>
                  )}
                  <Modifier onClick={() => setIsEditing((v) => !v)}>
                    MODIFICAR
                  </Modifier>
                </ContainerFormasPago>
              </SubContainer>
              {/* ----------------DATOS ENVIO--------------------------------------------- */}
              <SubContainer>
                <ContainerFormasPago>
                  <HeaderItem>Dirección de envío</HeaderItem>

                  <SubContainer>
                    <DisplayAddress datosEnvio={datosEnvio} />
                  </SubContainer>

                  <Modifier onClick={() => setEditAddress((v) => !v)}>
                    MODIFICAR
                  </Modifier>
                </ContainerFormasPago>
              </SubContainer>
              {/* ---------BOTTOM PART / BUTTONS SECTION---------------------------------- */}
              <Line />
              <SubContainer flexIt>
                <Btn
                  danger
                  onClick={(e) => {
                    e.stopPropagation();
                    backToNormal();
                  }}
                  disabled={indLoading}
                >
                  Cancelar
                </Btn>
                <Btn onClick={handleSubmit} disabled={indLoading}>
                  {indLoading ? <Spinner fz="var(--fontSmall)" /> : "Confirmar"}
                </Btn>
              </SubContainer>
            </Section>
            <Section editAddress={editAddress}>
              <SubContainer>
                <HeaderItem>Modificar dirección de envío</HeaderItem>
                <FlexColumn>
                  {fullData.map((i) => {
                    //armar la fn de handleChange + useState envio

                    return (
                      <AddressInputLine
                        {...i}
                        key={i.id}
                        changeInput={changeInput}
                        inputsEnvio={inputsEnvio}
                        prov={prov}
                      />
                    );
                  })}
                  <SubContainer flexIt>
                    <Btn
                      danger
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditAddress((v) => !v);
                      }}
                      disabled={indLoading}
                    >
                      Volver
                    </Btn>
                    <Btn onClick={handleSubmit} disabled={indLoading}>
                      {indLoading ? (
                        <Spinner fz="var(--fontSmall)" />
                      ) : (
                        "Confirmar"
                      )}
                    </Btn>
                  </SubContainer>
                </FlexColumn>
              </SubContainer>
            </Section>
          </FlexIt>
        </OverlayStructure>
      </td>
    </tr>
  );
};
