import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import styled, { css } from "styled-components";
import { SideBar } from "components/Admin/SideBar";
import { ErrorNotification } from "components/styles/smallComponents";
import { HASH_LINKS } from "constants";
import { useRef, useState } from "react";
import { navHeight } from "components/Nav/Nav";
import { AiOutlineArrowRight } from "react-icons/ai";
export const WidthStyles = css`
  max-width: 1500px;
  margin: auto;
`;

const ContainerGeneral = styled.div`
  padding: 0 10px;
  ${WidthStyles};
`;
/* -------------------LO USO PARA EL LOGIN, REGISTER, CONTACTO, FORGOT--------- */
export const OutletContainerGral1500 = () => {
  return (
    <ContainerGeneral id={HASH_LINKS.TOP}>
      <Outlet />
    </ContainerGeneral>
  );
};
/* --------------------lo uso en individual components varios----------------- */
export const ContainerGral1500 = ({ children }) => {
  return <ContainerGeneral id={HASH_LINKS.TOP}>{children}</ContainerGeneral>;
};

/* -----------------------------ADMIN SIDE----------------------------------- */
export const ContainerAncho = styled.div`
  margin: 0 auto;
  position: relative;
`;
const Center = styled.div`
  /*  ${WidthStyles} */
  /* p/q no choque con la right bar */
  transition: 0.3s;
  margin-left: ${(props) => props.showSideBar && props.asideAdminWidth + 2}px;
`;
const OpenTab = styled.div`
  position: fixed;
  cursor: pointer;
  width: fit-content;
  z-index: 50;
  left: 0;
  top: ${navHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  /*   padding: 10px; */
  background-color: ${(props) => props.theme.secondaryBody};
  border: 1px solid ${(props) => props.theme.border};
  display: ${(props) => props.showSideBar && "none"};
`;
export const ContainerAdmin = () => {
  const { error } = useSelector((state) => state.user);

  /* uso esta ref p/poder obtener la width de la asideBar, y en base a eso aplicar margin-left al Center content de ADMIN */
  const asideBarRef = useRef();
  const [showSideBar, setShowSideBar] = useState(true);

  return (
    <ContainerAncho>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <OpenTab
        showSideBar={showSideBar}
        onClick={() => setShowSideBar((v) => !v)}
      >
        <AiOutlineArrowRight />
      </OpenTab>
      <SideBar
        showSideBar={showSideBar}
        asideBarRef={asideBarRef}
        setShowSideBar={setShowSideBar}
      />
      <Center
        showSideBar={showSideBar}
        asideAdminWidth={asideBarRef?.current?.offsetWidth}
      >
        <Outlet />
      </Center>
    </ContainerAncho>
  );
};
