import styled from "styled-components";
import EditDescriptionOverlay from "pages/admin/auxiliaries/EditDescriptionOverlay";
import {
  confirmAction,
  hideOrShowEditPhotoOverlay,
} from "context/generalSlice";
import {
  /* ErrorNotification, */
  Spinner,
} from "components/styles/smallComponents";
import SingleTamañoAdmin from "pages/admin/EditProducts/auxiliaries/SingleTamañoEsp";
import { productosAPI } from "API/productos";
import { ImWoman, ImMan, ImAndroid } from "react-icons/im";
import {
  AiFillDelete,
  AiOutlinePlus,
  AiFillEdit,
  AiFillSave,
} from "react-icons/ai";
import { MdAddAPhoto } from "react-icons/md";
import {
  Col,
  ReactIcon,
  SingleRow,
  Input,
  Table,
  CheckBoxGroupEdits,
  SubTableHeader,
} from "components/Admin/MiscelaneousComponents";
import { useDispatch } from "react-redux";
import { useState } from "react";
import PotencialTamañoNuevo from "./PotencialTamañoNuevo";
import { subtableColumnsEsp, tableColumns } from "pages/admin/data";
import { Link } from "react-router-dom";
import { FRONTEND_ENDPOINTS } from "constants";

const ImgOverlay = styled.div`
  background-color: ${(props) => props.theme.overlay};
  color: ${(props) => props.theme.body};
  font-weight: bold;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > :first-child {
    font-size: 2rem;
  }
`;
const ImgContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  &:hover ${ImgOverlay} {
    opacity: 1;
  }
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  border: ${(props) => props.borderDestacado && "2px solid var(--mainViolet)"};
`;
const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;
const TamañosSection = styled(SingleRow)``;
const Disappear = styled.div`
  max-height: ${(props) => (props.show ? "1000px" : "0")};
  transition: 0.3s;
  overflow: hidden;
  position: relative;
  &:before {
    height: ${(props) => (props.show ? "100%" : "0")};
    width: 5px;
    background-color: ${(props) => props.theme.blue};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s;
    content: "";
  }
`;
const SubTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.border};
`;

const Descripcion = styled.span`
  font-weight: bold;
`;
const Select = styled.select`
  padding: 10px;
  font-size: var(--fontSmall);
`;
const EditDescr = styled.button`
  padding: 10px;
  font-size: var(--fontSmall);
  cursor: pointer;
  background-color: inherit;
  color: ${(props) => props.theme.text};
  border: 1px solid ${(props) => props.theme.red};
  margin-left: 10px;
`;
const BorderDestacado = styled.span`
  border: 1px solid var(--mainViolet);
  width: 20px;
  border-radius: 5px;

  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;

const MAINProductDisplay = ({
  prod,
  marcas,
  isGroupEditing,
  groupEdit,
  setGroupEdit,
}) => {
  const dispatch = useDispatch();
  /*----------INFO GRAL (nombre,marca,sexo)------------------------------------------*/
  const [loadingEDITING, setLoadingEDITING] = useState(false); //lo uso p/mostrar loading state cuando llamo a la API
  const [isEditing, setIsEditing] = useState(false); //Determina si muestro un input (editMode) o un div-VER handleEditInputChange
  const [showDescrModal, setShowDescrModal] = useState(false);
  const { nombre, sexo, marca, esp, img, _id, descripcion, destacado } = prod;
  //no poner esp xq cuando comparo p/saber si hago edit-api-call me da siempre diferencia al comparar arrays
  const [inputs, setInputs] = useState({
    nombre,
    sexo,
    marca,
    img,
    _id,
    descripcion,
    //destacado puede ser undefined, x las dudas I force it into a boolean
    destacado: !!destacado,
  });
  /*FUNCIONES RELATIVAS A LA INFO.Gral */
  // handleChange
  function handleEditInputChange(e) {
    if (e.target.name === "destacado") {
      return setInputs({ ...inputs, [e.target.name]: e.target.checked });
    }

    return setInputs({ ...inputs, [e.target.name]: e.target.value });
  }
  //handleSubmit
  async function saveEdit(e) {
    e.stopPropagation();
    let hasItChanged = false;

    for (const key in inputs) {
      if (typeof inputs.key === "string") {
        if (JSON.stringify(prod[key]) !== JSON.stringify(inputs[key].trim())) {
          hasItChanged = true;
        }
      } else if (prod[key] !== inputs[key]) {
        hasItChanged = true;
      }
    }
    if (hasItChanged) {
      setLoadingEDITING(true);
      await productosAPI.updateProd(dispatch, inputs, _id);
      setLoadingEDITING(false);
      /* el modal p/editar la descr, en caso de q estuviera abierto y editando eso */
      setShowDescrModal(false);
      return setIsEditing(false);
    }
    setIsEditing(false);
    /* el modal p/editar la descr, en caso de q estuviera abierto y editando eso */
    setShowDescrModal(false);
  }
  /*----------------------------------------------------------------------------------*/
  //ABRE LA SOLAPA/SUBDIVISION con la info de c/ESP
  const [show, setShow] = useState(false);
  const [inputsTamañosNuevos, setInputsTamañosNuevos] = useState([]);

  function addNewInputForPotencialTamaño(e) {
    if (show) e.stopPropagation();
    //A FUTURO
    //ver si puedo comparar y dejar tamaño q no esté agregado aun
    setInputsTamañosNuevos([
      ...inputsTamañosNuevos,
      {
        _id: Date.now(),
        tamaño: 100,
        precio: 15800,
        stock: 1,
      },
    ]);
  }

  function handleChange(e, _id) {
    return setInputsTamañosNuevos(
      inputsTamañosNuevos.map((i) =>
        i._id === _id ? { ...i, [e.target.name]: e.target.value } : i
      )
    );
  }

  function handleDeleteTamañoNuevo(_id) {
    setInputsTamañosNuevos(inputsTamañosNuevos.filter((i) => i._id !== _id));
  }

  function changeINChildrenState() {
    const foundProd = groupEdit.find((i) => i._id === _id);
    //para el caso q find no encuentre nada (creo q no deberia pasar, ver bien dsp. Pero x las dudas, si en el 1er renderizado ocurre ponemos un safeguard)
    if (!foundProd) {
      return false;
    }

    const isChecked =
      foundProd.checked &&
      foundProd.esp.every((tamañoItem) => tamañoItem.checked);
    return isChecked;
  }

  function handleCheckInEspChildCbox(_idProd, _idTamaño, e) {
    //tengo 3, o 5, 1, tengo q chequear q todos esten o a. checked
    //                                                  b. unchecked
    //el tema es q si lo manejo de aca, la logica parece ser complicada
    //creo q lo mejor es manejarlo directamente en el MainProdCbox

    //es complicado, pero es lo q corresponde, ya q el cambio lo produce el child, x ende el child se tiene q encargar
    //sino x ej tendria q poner un useEffect y eso ya es problematico
    const foundProd = groupEdit.find((i) => i._id === _idProd);
    let howManyChecked = 0;

    //actualizamos esp y aprovechamos p/ contar la cantidad de items checked
    foundProd.esp.forEach((espItem) => {
      if (espItem?.checked) {
        howManyChecked++;
      }
      if (espItem._idTamaño === _idTamaño) {
        espItem.checked = e.target.checked;
      }
    });

    const isChecking = e.target.checked;
    //LOGICA P/LO Q SIGUE, es un poco tedioso, pero se debería entender

    //si isChecking is true, contamos 2 checked, y prod.Esp tiene 3 length: cambiamos parent
    //si isChecking is false, contamos 3 checked, y prod.Esp tiene 3 length: cambiamos parent
    //else nothing
    if (
      (isChecking && howManyChecked === foundProd.esp.length - 1) ||
      (!isChecking && howManyChecked === foundProd.esp.length)
    ) {
      foundProd.checked = isChecking;
    }

    setGroupEdit((arr) => arr.map((i) => (i._id === _idProd ? foundProd : i)));
  }

  return (
    <>
      {/* PARTE SUPERIOR DONDE ESTA LA INFO GRAL DEL SINGLE PROD : nombre, marca, sexo, y acciones (borrar + editar + Agregar tamaño) */}
      {/* isEditing es p/resaltar SingleRow cuando el admin esta editando */}
      <SingleRow onClick={() => setShow(!show)} isEditing={isEditing}>
        <Col
          onClick={(e) => e.stopPropagation()}
          className="multiedit"
          isGroupEditing={isGroupEditing}
          //metodo all
          //el array incluye la _id, o all?? checked : false

          //cambio el MASTER CHECK a false, tengo q vaciar
        >
          <CheckBoxGroupEdits
            onChange={({ target }) =>
              setGroupEdit((arr) =>
                arr.map((i) =>
                  i._id === _id
                    ? {
                        ...i,
                        checked: target.checked,
                        esp: i.esp.map((tamañoItem) => ({
                          ...tamañoItem,
                          checked: target.checked,
                        })),
                      }
                    : i
                )
              )
            }
            checked={changeINChildrenState()}
          />
        </Col>
        <Col className="nombre">
          <ImgContainer borderDestacado={!!destacado}>
            <ImgOverlay
              onClick={(e) => {
                e.stopPropagation();
                dispatch(hideOrShowEditPhotoOverlay({ show: true, _id }));
              }}
            >
              <MdAddAPhoto />
              Editar foto
            </ImgOverlay>
            <Img src={img}></Img>
          </ImgContainer>
          {isEditing ? (
            <>
              <Input
                value={inputs.nombre}
                onChange={handleEditInputChange}
                onClick={(e) => e.stopPropagation()}
                name="nombre"
              />
              <BorderDestacado>
                <Input
                  type="checkbox"
                  checked={inputs.destacado}
                  style={{ height: "2rem" }}
                  onChange={handleEditInputChange}
                  onClick={(e) => e.stopPropagation()}
                  name="destacado"
                />
              </BorderDestacado>
            </>
          ) : (
            <Descripcion>
              <Link to={FRONTEND_ENDPOINTS.dynamicSingleProduct(_id)}>
                {nombre}
              </Link>

              <EditDescr
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDescrModal((v) => !v);
                }}
              >
                Editar descripción
              </EditDescr>
            </Descripcion>
          )}
        </Col>
        <Col>
          {isEditing ? (
            <Select
              onChange={handleEditInputChange}
              onClick={(e) => e.stopPropagation()}
              name="marca"
              value={inputs.marca}
            >
              {marcas.length > 0 &&
                marcas.map((marca) => (
                  <option key={marca} value={marca}>
                    {marca}
                  </option>
                ))}
            </Select>
          ) : (
            marca
          )}
        </Col>
        <Col className="sexo">
          {isEditing ? (
            <Select
              value={inputs.sexo}
              onChange={handleEditInputChange}
              onClick={(e) => e.stopPropagation()}
              name="sexo"
            >
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
              <option value="Unisex">Unisex</option>
            </Select>
          ) : sexo === "Femenino" ? (
            <ImWoman className="fem" />
          ) : sexo === "Masculino" ? (
            <ImMan className="man" />
          ) : (
            <ImAndroid className="uni" />
          )}
        </Col>
        <Col>
          <div className="acciones">
            {/*------------------------ BORRAR FULL PRODUCTO -------------------------*/}
            <>
              <ReactIcon
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    confirmAction({
                      show: true,
                      message: "Borrar producto.",
                      _id,
                    })
                  );
                }}
              >
                <AiFillDelete />
              </ReactIcon>
            </>
            {/*------------------------ EDIT INFO.GRAL PRODUCTO -------------------------*/}
            <>
              {loadingEDITING ? (
                <ReactIcon>
                  <Spinner fz="1.5rem" />
                </ReactIcon>
              ) : isEditing ? (
                <ReactIcon as="button" className="save" onClick={saveEdit}>
                  <AiFillSave />
                </ReactIcon>
              ) : (
                <ReactIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow(false);
                    setIsEditing(!isEditing);
                  }}
                >
                  <AiFillEdit />
                </ReactIcon>
              )}
            </>
            {/*------------------------ AGREGAR TAMAñO NUEVO A ESP -------------------------*/}
            <>
              <ReactIcon onClick={addNewInputForPotencialTamaño}>
                Agregar tamaño
                <AiOutlinePlus />
              </ReactIcon>
            </>
          </div>
        </Col>
      </SingleRow>
      {/* PARTE INFERIOR EN DONDE LOOPEO ESP ARRAY */}
      <TamañosSection>
        <Col
          /* cuando no estoy groupEditing muestro 1 Col menos arriba, y x ende el colSpan disminuye 1 tmb */
          colSpan={
            isGroupEditing ? tableColumns.length : tableColumns.length - 1
          }
        >
          <Disappear show={show}>
            <SubTable>
              <SubTableHeader
                allColumns={
                  isGroupEditing
                    ? subtableColumnsEsp
                    : subtableColumnsEsp.slice(1)
                }
              />
              <tbody>
                {esp.map((unidad) => (
                  <SingleTamañoAdmin
                    singleEspProd={unidad}
                    prodId={_id}
                    key={unidad._id}
                    isGroupEditing={isGroupEditing}
                    /* mando solo el parent prod, no todo el object */
                    groupEdit={groupEdit.find((i) => i._id === _id)}
                    handleCboxChange={handleCheckInEspChildCbox}
                  />
                ))}
                {/* -------------------------PARTE P/EDITAR EL POTENCIAL TAMAñO NUEVO Y AGREGARLO A LA DB---------- */}
                {inputsTamañosNuevos.length > 0 &&
                  inputsTamañosNuevos.map((potencialTamañoNuevo) => (
                    <PotencialTamañoNuevo
                      key={potencialTamañoNuevo?._id}
                      potencialTamañoNuevo={potencialTamañoNuevo}
                      handleChange={handleChange}
                      handleDelete={handleDeleteTamañoNuevo}
                      prodID={prod._id}
                    />
                  ))}
                {/* -------------------------------------------------------------------------------------------------- */}
              </tbody>
            </SubTable>
          </Disappear>
        </Col>
      </TamañosSection>

      {showDescrModal && (
        <EditDescriptionOverlay
          handleChange={handleEditInputChange}
          descripcion={inputs.descripcion}
          show={showDescrModal}
          close={setShowDescrModal}
          handleSubmit={saveEdit}
        />
      )}
    </>
  );
};

export default MAINProductDisplay;
