import React from "react";
import styled from "styled-components";
import { animateOpacityFadeIn } from "components/styles/general";
import ParseDescripcion from "utils/ParseDescripcion";
import { Columna } from "./styled";

const BorderContainers = styled.div`
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
  background-color: ${(props) => props.theme.secondaryBody};
`;
const Header = styled.div`
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.border};
  padding: 10px;
  font-size: var(--fontMed);
`;
const Content = styled.div`
  padding: 10px;
  font-size: calc(var(--fontSmall) - 0.1rem);
`;
export const ImgContainer = styled(BorderContainers)`
  height: 70vh;
  @media (max-width: 450px) {
    height: 50vh;
  }
`;

export const Img = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /*--------- OPACITY ANIMATION --------*/
  opacity: 0;
  animation: ${animateOpacityFadeIn} 2s forwards;
`;
export const Left = (props) => {
  const { /* nombre, esp,  marca,*/ img, /* _id, */ descripcion } = props;

  return (
    <Columna>
      <ImgContainer>
        <Img src={img}></Img>
      </ImgContainer>
      {descripcion && (
        <BorderContainers>
          <Header>Descripción</Header>
          <Content>
            <ParseDescripcion descripcion={descripcion} />
          </Content>
        </BorderContainers>
      )}
    </Columna>
  );
};
