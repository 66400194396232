import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAPI } from "API/auth";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import {
  Bottom,
  Form,
  Input,
  InputControl,
  Label,
  forgotInputs,
  Submit,
  SuccessRegister,
  Header,
} from "./data";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";

export const ForgotPwd = () => {
  const { error, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [emailUsuario, setEmailUsuario] = useState("");
  const [success, setSuccess] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();
    setSuccess(false);

    authAPI.forgotPwd(dispatch, emailUsuario, setSuccess);
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Header style={{ padding: "var(--fontLarge) 0" }}>
        ¿Te olvidaste tu contraseña?
      </Header>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      {success && <SuccessRegister>{success}</SuccessRegister>}
      {forgotInputs?.map((i) => {
        const { type, id, label } = i;

        return (
          <InputControl key={id}>
            <Label htmlFor={id}>{label}</Label>
            <Input
              as="input"
              type={type}
              id={id}
              placeholder={label}
              name={id}
              value={emailUsuario}
              onChange={(e) => setEmailUsuario(e.target.value)}
            ></Input>
          </InputControl>
        );
      })}
      <Submit disabled={loading}>
        {loading ? <Spinner /> : "Enviar mail"}
      </Submit>
      <Bottom>
        Te vamos a enviar un mail. Seguí los pasos ahí indicados y vas a poder
        recuperar tu cuenta.
      </Bottom>
      <br />
      <AnimatedLink
        to={`${FRONTEND_ENDPOINTS.LOGIN}#${HASH_LINKS.TOP}`}
        style={{ width: "max-content" }}
      >
        Volver a inicio de sesión.
      </AnimatedLink>
    </Form>
  );
};
