import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "RESUMEN VENTAS",
    },
  },

  scales: {
    y: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return "$" + value;
        },
        font: {
          size: 20,
        },
      },
    },
    x: {
      ticks: {
        font: {
          size: 20,
        },
      },
    },
  },
};

export function SalesBarChart({ sales }) {
  //LOOP

  function getSales(sales) {
    const cleanSales = [];
    const date = new Date();
    for (let i = 5; i >= 0; i--) {
      const currentDate = new Date(date.getFullYear(), date.getMonth() - i, 1);
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();

      const found = sales.find((i) => {
        return i._id.year === year && i._id.month === month;
      });

      if (!found) {
        cleanSales.push({ _id: { month, year }, total: 0 });
      } else {
        cleanSales.push(found);
      }
    }
    return cleanSales;
  }
  function getCleanChartData(fullSales) {
    const labels = fullSales.map((i) => `${i._id.month}/${i._id.year}`);
    const salesTotal = fullSales.map((i) => i.total);
    return [labels, salesTotal];
  }
  /*  */
  const fullSales = getSales(sales);
  const [labels, salesTotal] = getCleanChartData(fullSales);
  const data = {
    labels,
    datasets: [
      {
        label: "Ventas últimos 6 meses.",
        data: salesTotal,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div style={{ width: "1200px", paddingTop: "50px" }}>
      <Bar options={options} data={data} />
    </div>
  );
}
