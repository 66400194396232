import { productosAPI } from "API/productos";
import {
  OverlayStructure,
  /*  Spinner, */
} from "components/styles/smallComponents";
import { BULK_EDIT_OPTIONS } from "constants";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Btn,
  Line,
  MainHeader,
  SubContainer,
} from "pages/admin/auxiliaries/OverlayStyledComp";

const FlexIt = styled.div`
  display: flex;
  align-items: center;
  /* styling stock, price and promo inputs/select */
  & input,
  & select {
    padding: 10px;
  }
`;
const PromoControllers = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  & input[type="number"] {
    width: 100%;
  }
`;
const FlexColumn = styled(SubContainer)`
  flex-direction: column;
  display: flex;
  gap: 5px;
`;

const Capitalize = styled.b`
  text-transform: capitalize;
  font-size: var(--fontMed);
`;

const BulkEditOverlay = ({ close, show, bulkData, type }) => {
  const dispatch = useDispatch();
  let text = "";
  if (type.includes(BULK_EDIT_OPTIONS.PRECIO)) {
    text = type.includes(BULK_EDIT_OPTIONS.PRECIO_ADD)
      ? "aumentar el precio"
      : "disminuir el precio";
  } else if (type.includes(BULK_EDIT_OPTIONS.PROMO)) {
    text = "modificar la promo";
  } else if (type.includes(BULK_EDIT_OPTIONS.DESTACADO)) {
    text = "modificar el 'destacado'";
  } else {
    text = "modificar el stock";
  }
  const [value, setValue] = useState();
  const [promo, setPromo] = useState(true);
  const [priceEditType, setPriceEditType] = useState(
    BULK_EDIT_OPTIONS.PRECIO_PERCENTAGE
  );

  useEffect(() => {
    //pongo el value como true, asi el input puede estar checked
    if (type.includes(BULK_EDIT_OPTIONS.DESTACADO)) {
      setValue(true);
    }
  }, [type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    productosAPI
      .bulkUpdate(dispatch, bulkData, {
        type: type.includes(BULK_EDIT_OPTIONS.PRECIO)
          ? type + priceEditType
          : type, //si la promo esta en modo false con el null value le aviso al backend q quite la promo
        value: type.includes(BULK_EDIT_OPTIONS.PROMO) && !promo ? null : value,
      })
      .then(() => {
        setValue("");
        close();
      })
      .catch(() => {});
  };
  return (
    <OverlayStructure close={close} show={show}>
      <MainHeader>EDICION MASIVA DE PRODUCTOS</MainHeader>
      <Line />
      <form onSubmit={handleSubmit}>
        <FlexColumn>
          <Capitalize>{type}</Capitalize>
          <div>{`Vas a ${text} en ${
            bulkData.filter(
              (i) => i.checked || i.esp.some((espSingle) => espSingle.checked)
            ).length
          } producto/s.`}</div>
          <FlexIt>
            {type.includes(BULK_EDIT_OPTIONS.PRECIO) ? (
              <>
                <select
                  value={priceEditType}
                  onChange={(e) => {
                    setPriceEditType(e.target.value);
                  }}
                >
                  <option value={BULK_EDIT_OPTIONS.PRECIO_PERCENTAGE}>
                    {BULK_EDIT_OPTIONS.PRECIO_PERCENTAGE}
                  </option>
                  <option value={BULK_EDIT_OPTIONS.PRECIO$}>
                    {BULK_EDIT_OPTIONS.PRECIO$}
                  </option>
                </select>

                <input
                  type={"number"}
                  min={1}
                  value={value}
                  placeholder="Agregar precio..."
                  onChange={({ target }) => setValue(target.value)}
                />
              </>
            ) : type.includes(BULK_EDIT_OPTIONS.STOCK) ? (
              <>
                <span>+</span>{" "}
                <input
                  type={"number"}
                  value={value}
                  //minimo stock 0
                  min={0}
                  placeholder="Establecer nuevo stock..."
                  onChange={({ target }) => setValue(target.value)}
                />
              </>
            ) : type.includes(BULK_EDIT_OPTIONS.DESTACADO) ? (
              <div style={{ width: "100%" }}>
                <h3>Marcá la casilla para destacar el producto.</h3>
                <br />
                <PromoControllers>
                  <input
                    autoFocus
                    type="checkbox"
                    checked={value}
                    style={{ height: "2rem" }}
                    onChange={() => setValue((v) => !v)}
                  ></input>

                  <b>{value ? "DESTACADO" : "Sin destacar"}</b>
                </PromoControllers>
              </div>
            ) : (
              /*----------------- PROMO------------------- */
              <div style={{ width: "100%" }}>
                <h3>Marcá la casilla para poner en promoción</h3>
                <br />
                <PromoControllers>
                  <input
                    autoFocus
                    type="checkbox"
                    checked={promo}
                    style={{ height: "2rem" }}
                    onChange={() => setPromo((v) => !v)}
                  ></input>

                  <b>{promo ? "En promoción" : "Sin promoción"}</b>
                  {promo && (
                    <input
                      type="number"
                      value={value}
                      min={1}
                      max={100}
                      onChange={({ target }) => setValue(target.value)}
                      placeholder="% de la promo"
                    ></input>
                  )}
                </PromoControllers>
              </div>
            )}
          </FlexIt>
        </FlexColumn>
        <Line />
        <SubContainer flexIt>
          <Btn
            danger
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
            /*   disabled={indLoading} */
          >
            Cancelar
          </Btn>
          <Btn
          /* disabled={indLoading} */
          >
            CONFIRMAR
            {/*   {indLoading ? <Spinner fz="var(--fontSmall)" /> : "Confirmar"} */}
          </Btn>
        </SubContainer>
      </form>
    </OverlayStructure>
  );
};

export default BulkEditOverlay;
