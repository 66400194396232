import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    showCarrito: false,
    letThereBeLight: false,
    notification: false,
    confirmá: false,
    openEditPhoto: { show: false, _id: "" },
  },
  reducers: {
    changeShowCarrito(state, action) {
      //al apretar comprar en Main le paso un true, p/q se abra el carrito
      state.showCarrito = action.payload || !state.showCarrito;
    },
    changeTheme(state) {
      state.letThereBeLight = !state.letThereBeLight;
    },
    showOrCloseNotification(state, action) {
      if (action.payload.show) {
        state.notification = action.payload.message;
        return;
      }
      state.notification = false;
    },
    confirmAction(state, action) {
      if (action.payload.show) {
        state.confirmá = action.payload;
        return;
      }
      state.confirmá = false;
    },
    hideOrShowEditPhotoOverlay(state, action) {
      if (action.payload.show) {
        state.openEditPhoto = action.payload;
        return;
      }
      state.openEditPhoto = { show: false, _id: "" };
    },
  },
});

export const {
  changeTheme,
  changeShowCarrito,
  showOrCloseNotification,
  confirmAction,
  hideOrShowEditPhotoOverlay,
} = generalSlice.actions;

export default generalSlice.reducer;
