import styled, { css } from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SingleCheckOutItem } from "components/Single/SingleCheckOut";
import { carritoController } from "utils/carritoControllers";
/* steps */
import { AddressStep } from "./auxiliaries/steps/AddressStep";
import { PaymentDetails } from "./auxiliaries/steps/PaymentDetails";
import { ReviewOrder } from "./auxiliaries/steps/ReviewOrder";
import { NotaCliente } from "./auxiliaries/NotaCliente/NotaCliente";
/*  */
import { ErrorNotification } from "components/styles/smallComponents";
import {
  Aside,
  FlexIt,
  Main,
  TopAside,
  BottomAside,
  HeaderAside,
  Subtotal,
  Envio,
  Total,
} from "./styles";
import { addDatosCompra } from "context/userSlice";
import { useDispatch } from "react-redux";
import { ContainerGral1500 } from "components/SmallComponents/Containers";
import { navHeight } from "components/Nav/Nav";

export const CheckOutTopNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Header = styled.h1`
  border-bottom: ${(props) => props.theme.border} 1px solid;
  padding: 10px 0;
  font-size: var(--fontMed);
  font-weight: 500;
  letter-spacing: 2px;
`;
const CheckOutSteps = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: calc(var(--fontSmall) + 0.1rem);
  letter-spacing: 1px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SingleStep = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  /* muestro verde si ya completé el step */
  filter: ${(props) => (props.step > props.order ? "none" : "grayscale(1)")};
  /* si estoy en el step activo, muestro el texto full color, sino grisaceo */

  & span:nth-child(2) {
    color: ${(props) =>
      props.step === props.order ? "inherit" : props.theme.border};
  }
  border-bottom: 1px solid
    ${(props) => (props.step === props.order ? props.theme.border : "none")};
  padding: calc(var(--fontSmall) - 0.7rem);
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.secondaryBody};
  }
  @media (max-width: 600px) {
    justify-content: start;
  }
`;
const CommonStyles = css`
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  display: grid;
  place-items: center;
`;
const Order = styled.span`
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  ${CommonStyles}
`;
const ReactIcon = styled.div`
  background-color: var(--mainGreen);
  color: white;
  ${CommonStyles}
`;

//ex MAIN y ASIDE (top y bottom aside)

export const CheckOut = () => {
  const [openMsjeClient, setOpenMsjeClient] = useState(false);

  const { carrito, error, compra } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const scrollUpRef = useRef();
  const { step, borrar } = compra;
  const loopSteps = [
    { n: 0, t: "Dirección de envío" },
    { n: 1, t: "Detalles del pago" },
    { n: 2, t: "Confirmar compra" },
  ];

  useEffect(() => {
    //usé esto en vez de scrollToView porque necesita hacer un offset x el navbar
    function scrollToTargetAdjusted() {
      var element = scrollUpRef.current;
      if (!element) return;

      var headerOffset = Number(navHeight.substring(0, 2));
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    scrollToTargetAdjusted();
  }, [step]);

  return (
    <ContainerGral1500>
      <CheckOutTopNav ref={scrollUpRef}>
        <Header>FINALIZAR COMPRA</Header>
        <CheckOutSteps>
          {loopSteps.map((i) => {
            let { n, t } = i;

            return (
              <SingleStep
                step={step}
                order={n}
                onClick={() =>
                  //queremos q en steps ultimo y anteultimo solo se pueda adelantar una vez q hayas completado la 1era etapa, y borrar pase a false
                  i && borrar ? null : dispatch(addDatosCompra({ step: n }))
                }
                key={n}
              >
                {step !== n ? (
                  <ReactIcon>
                    <AiOutlineCheck />
                  </ReactIcon>
                ) : (
                  <Order>{n + 1}</Order>
                )}

                <span>{t}</span>
              </SingleStep>
            );
          })}
        </CheckOutSteps>
      </CheckOutTopNav>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <FlexIt>
        <Main>
          {step === 0 ? (
            <AddressStep />
          ) : step === 1 ? (
            <PaymentDetails step={step} />
          ) : step === 2 ? (
            <ReviewOrder setOpenMsjeClient={setOpenMsjeClient} />
          ) : undefined}
        </Main>
        <Aside>
          <HeaderAside>RESUMEN COMPRA</HeaderAside>
          <TopAside>
            {carrito?.map((i) => (
              <SingleCheckOutItem {...i} key={i._idTamaño} />
            ))}
          </TopAside>
          <BottomAside>
            <Subtotal>
              <span>Subtotal</span>
              <span>${carritoController.getTotal(carrito)}</span>
            </Subtotal>
            <Envio>
              <span>Envío</span>
              <span>GRATIS</span>
            </Envio>
            <Total>
              <span>Total</span>
              <span>${carritoController.getTotal(carrito)}</span>
            </Total>
            <NotaCliente
              setOpenMsjeClient={setOpenMsjeClient}
              openMsjeClient={openMsjeClient}
            />
          </BottomAside>
        </Aside>
      </FlexIt>
    </ContainerGral1500>
  );
};
