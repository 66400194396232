import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authAPI } from "API/auth";
import {
  AnimatedLink,
  ErrorNotification,
  Spinner,
} from "components/styles/smallComponents";
import {
  Bottom,
  Form,
  Input,
  InputControl,
  Label,
  loginInputs,
  Submit,
  Title,
} from "./data";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";
import { dispatchNotification } from "utils/dispatchNotification";

export const Login = () => {
  const { error, loading, carrito } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const from =
    location.state?.from?.pathname ||
    `${FRONTEND_ENDPOINTS.USER_PROFILE}#${HASH_LINKS.TOP}`;

  useEffect(() => {
    if (from === FRONTEND_ENDPOINTS.CHECKOUT && carrito.length) {
      dispatchNotification(
        dispatch,
        "Iniciá sesión o creá una cuenta antes de realizar la compra. "
      );
    }
  }, [from, carrito.length, dispatch]);

  const [logInput, setLogInput] = useState({
    emailUsuario: "",
    contraseña: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    authAPI.login(dispatch, logInput, navigate, from);
  }
  return (
    <Form>
      <ErrorNotification show={error}>{error}</ErrorNotification>
      <Title>Iniciar sesión</Title>

      {loginInputs?.map((i) => {
        const { type, id, label } = i;

        return (
          <InputControl key={id}>
            <Label htmlFor={id}>{label}</Label>
            <Input
              as="input"
              type={type}
              id={id}
              placeholder={label}
              name={id}
              value={logInput[id]}
              onChange={(e) =>
                setLogInput({ ...logInput, [e.target.name]: e.target.value })
              }
            ></Input>
          </InputControl>
        );
      })}
      <Submit onClick={handleSubmit} disabled={loading}>
        {loading ? <Spinner /> : "Iniciar sesión"}
      </Submit>
      <Bottom>
        <AnimatedLink to={`${FRONTEND_ENDPOINTS.REGISTER}#${HASH_LINKS.TOP}`}>
          ¿No tenés cuenta? Registrate
        </AnimatedLink>{" "}
        <AnimatedLink
          to={`${FRONTEND_ENDPOINTS.FORGOT_PASSWORD}#${HASH_LINKS.TOP}`}
          c="var(--mainGreen)"
          ch="var(--mainGreen)"
          style={{ textAlign: "right" }}
        >
          Me olvidé la contraseña
        </AnimatedLink>
      </Bottom>
    </Form>
  );
};
