import { profileAPI } from "API/profile";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderTableClientes from "./ClientsTable";

export default function AdminClientes() {
  const dispatch = useDispatch();
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    profileAPI.getAllClients(dispatch).then((data) => setClientes(data));
  }, [dispatch]);

  return (
    <div>
      <h2>Total usuarios: {clientes?.length}</h2>
      {clientes?.length > 0 ? (
        <table style={{ width: "100%" }}>
          <HeaderTableClientes />
          <tbody>
            {clientes.map((i) => {
              console.log(i);
              const { _id, emailUsuario, nombre, createdAt, admin } = i;
              return (
                <tr key={_id}>
                  <td style={{ width: "50px" }}></td>
                  <td>{emailUsuario}</td>
                  <td>{nombre}</td>
                  <td>
                    {new Date(createdAt).toLocaleString("es-AR", {
                      year: "numeric",
                      month: "long",
                    })}
                  </td>
                  <td>{admin ? "YES" : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <h2>NO HAY CLIENTES</h2>
      )}
      <br />
    </div>
  );
}
