import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productosAPI } from "API/productos";
import { CloseButton, Spinner } from "components/styles/smallComponents";
import { Form, Input, InputControl, Label, Submit } from "../auth/data";
import { createProdInputs } from "./data";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { MdAddAPhoto } from "react-icons/md";
import styled from "styled-components";
import { marcasAPI } from "API/marcas";
import { showOrCloseNotification } from "context/generalSlice";
import { Link } from "react-router-dom";
import ParseDescripcion from "utils/ParseDescripcion";

const Select = styled.select`
  padding: 15px 10px;
  font-size: 1.3rem;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.blue};
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.body};
`;
const SectionPhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LabelImg = styled(Label)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  & > * {
    font-size: 6rem;
  }
  & ${Input} {
    display: none;
  }
`;
const ContainerImg = styled.div`
  position: relative;
  height: 250px;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const ReactIcon = styled.span`
  font-size: 1.5rem;
  color: ${(props) => (props.delete ? "crimson" : "white")};
  background-color: ${(props) =>
    props.delete ? "black" : "rgba(0, 0, 0, 0.8);"};
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const SingleEsp = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid black;
  gap: 5px;
  & ${InputControl} {
    min-width: 120px;
  }
  & ${ReactIcon} {
    align-self: flex-end;
    padding: 12px;
    display: flex;
    cursor: pointer;
  }
`;
const RelativeInputControl = styled(InputControl)`
  position: relative;
`;

const PreviewPerfumesContainer = styled.div`
  position: absolute;
  top: 101%;
  width: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.body};
  border: 1px solid ${(props) => props.theme.border};
  opacity: 0;
  //SI SACO ESTA TRANSICION DESAPARECE TAN RAPIDO EL CONTAINER DE SUGGESTIONS Q NO LLEGA A ANDAR LOS LINKS A LOS PRODUCTOS, OJAZO
  transition: 0.5s;
  z-index: -1;
`;
const InputNombreSearch = styled(Input)`
  &:focus + ${PreviewPerfumesContainer} {
    opacity: 1;
    z-index: 1;
  }
`;
const SingleSuggestion = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  & > img {
    height: 50px;
    width: 50px;
  }
  &:hover {
    font-weight: bold;
    background-color: ${(props) => props.theme.border};
  }
`;
export default function AdminCreateProd() {
  const { loading } = useSelector((state) => state.user);
  const [inputs, setInputs] = useState({
    nombre: "",
    img: "",
    esp: [
      {
        //cambié a _id xq sino cuando los mapeaba tenía conflicto de nombres con el otro id
        _id: 0,
        espInputs: [
          { type: "number", id: "tamaño", label: "Tamaño", value: 100 },
          { type: "number", id: "precio", label: "Precio", value: 15800 },
          { type: "number", id: "stock", label: "Stock", value: 1 },
        ],
      },
    ],
    marca: "Seleccioná marca",
    sexo: "Seleccioná sexo",
    descripcion: "",
  });
  const [marcas, setMarcas] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    marcasAPI.getMarcas(dispatch, setMarcas);
  }, [dispatch, setMarcas]);
  function addNewEspItem() {
    return {
      _id: inputs.esp.length + Date.now(),
      espInputs: [
        { type: "number", id: "tamaño", label: "Tamaño", value: 100 },
        { type: "number", id: "precio", label: "Precio", value: 15800 },
        { type: "number", id: "stock", label: "Stock", value: 1 },
      ],
    };
  }
  async function handleSubmit(e) {
    e.preventDefault();
    productosAPI.createProd(dispatch, inputs);
  }
  function handleValueChange(e, espInd) {
    console.log(espInd);
    console.log(e.target.name, ":", e.target.value);
    if (espInd >= 0 && typeof espInd === "number") {
      return setInputs({
        ...inputs,
        esp: inputs.esp.map((i, ind) => {
          return ind === espInd
            ? {
                ...i,
                espInputs: i.espInputs.map((finalItem) =>
                  finalItem.id === e.target.name
                    ? { ...finalItem, value: e.target.value }
                    : finalItem
                ),
              }
            : i;
        }),
      });
    }
    if (e.target.name === "img") {
      console.log(e.target.name, e.target?.files[0]);
      return setInputs({ ...inputs, [e.target.name]: e.target.files[0] });
    }
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }
  async function showSuggestionsProds(inp) {
    console.log("llamamos API");
    setSuggestions(await productosAPI.getProdsToShowSuggestions(inp));
  }
  useEffect(() => {
    if (!inputs.nombre.length) {
      return setSuggestions([]);
    }
    if (inputs.nombre.length < 2) {
      return;
    }
    let id;
    if (inputs.nombre.length > 2) {
      console.log(
        "estamos en danger zone, ver si se llama a la api siempre o solo cuando dejo de escribir"
      );
      id = setTimeout(() => showSuggestionsProds(inputs.nombre), 500);
    }
    return () => {
      console.log("clearing id:" + id);
      if (id) clearTimeout(id);
    };
  }, [inputs.nombre]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Select value={inputs.marca} name="marca" onChange={handleValueChange}>
          <option disabled hidden>
            Seleccioná marca
          </option>
          {marcas?.length > 0 &&
            marcas.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
        </Select>
        {createProdInputs?.map((i) => {
          const { type, id, label, option = null } = i;
          if (id === "img") {
            return (
              <SectionPhoto key={id}>
                <InputControl>
                  <LabelImg htmlFor={id}>
                    <MdAddAPhoto />
                    {label}
                    <Input
                      as="input"
                      type={type}
                      id={id}
                      name={id}
                      onChange={handleValueChange}
                    ></Input>
                  </LabelImg>
                </InputControl>
                {inputs.img && (
                  <ContainerImg>
                    <Img src={URL.createObjectURL(inputs.img)}></Img>
                    <CloseButton
                      f={() => setInputs({ ...inputs, img: null })}
                    />
                  </ContainerImg>
                )}
              </SectionPhoto>
            );
          }
          if (id === "sexo") {
            return (
              <InputControl key={id}>
                <Label htmlFor={id}>{label}</Label>
                <Select
                  name={id}
                  onChange={handleValueChange}
                  value={inputs[id]}
                >
                  <option disabled hidden>
                    Seleccioná sexo
                  </option>
                  {option?.length > 0 &&
                    option.map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                </Select>
              </InputControl>
            );
          }
          return (
            <RelativeInputControl key={id}>
              <Label htmlFor={id}>{label}</Label>
              <InputNombreSearch
                as="input"
                type={type}
                id={id}
                placeholder={label}
                name={id}
                value={inputs[id]}
                onChange={(e) => {
                  handleValueChange(e);
                }}
                onFocus={(e) => console.log(e.target)}
              ></InputNombreSearch>
              <PreviewPerfumesContainer>
                {suggestions.length > 0 &&
                  suggestions.map((i) => (
                    <SingleSuggestion
                      to={`productos/${i._id}`}
                      key={i._id}
                      onClick={(e) => console.log(e.target)}
                    >
                      <img src={`${i.img}`} alt={i.nombre}></img>
                      <span>{i.nombre}</span>
                    </SingleSuggestion>
                  ))}
              </PreviewPerfumesContainer>
            </RelativeInputControl>
          );
        })}
        <ReactIcon
          onClick={() =>
            setInputs({ ...inputs, esp: [...inputs.esp, addNewEspItem()] })
          }
        >
          Agregar tamaño
          <AiOutlinePlus />
        </ReactIcon>
        {inputs.esp.map((i, ind) => {
          const { _id, espInputs } = i;
          return (
            <SingleEsp key={_id}>
              {espInputs.map((item, index) => {
                const { type, id, label } = item;
                return (
                  <InputControl key={id}>
                    <Label htmlFor={id}>{label}</Label>
                    <Input
                      as="input"
                      type={type}
                      id={id}
                      placeholder={label}
                      name={id}
                      value={inputs.esp[ind].espInputs[index].value}
                      onChange={(e) => handleValueChange(e, ind)}
                    ></Input>
                  </InputControl>
                );
              })}
              <ReactIcon
                delete
                onClick={() => {
                  inputs.esp.length === 1
                    ? dispatch(
                        showOrCloseNotification({
                          show: true,
                          message: "Como mínimo debe haber un tamaño.",
                        })
                      )
                    : setInputs({
                        ...inputs,
                        esp: inputs.esp.filter((i) => i._id !== _id),
                      });
                }}
              >
                <AiFillDelete />
              </ReactIcon>
            </SingleEsp>
          );
        })}
        <RelativeInputControl>
          <Label htmlFor="descripcion">
            Descripción (separar párrafos con "-")
          </Label>
          <InputNombreSearch
            as="textarea"
            rows={4}
            id="descripcion"
            placeholder="Ej: Flower es un perfume muy lindo.-Flower is life."
            name="descripcion"
            value={inputs.descripcion}
            onChange={(e) => {
              handleValueChange(e);
            }}
          ></InputNombreSearch>
          <ParseDescripcion descripcion={inputs.descripcion} />
        </RelativeInputControl>
        {loading ? (
          <Submit>
            <Spinner fz="2.2rem" />
          </Submit>
        ) : (
          <Submit as="input" type="submit" value={"Crear producto"} />
        )}
      </Form>
    </>
  );
}
