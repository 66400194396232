import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authAPI } from "API/auth";
import {
  AnimatedLink,
  ErrorNotification,
} from "components/styles/smallComponents";
import {
  Bottom,
  Form,
  Input,
  InputControl,
  Label,
  Submit,
  Title,
} from "pages/auth/data";
import { FRONTEND_ENDPOINTS } from "constants";
import { contactoInputs } from "./data";
import styled from "styled-components";
import { ContainerGral1500 } from "components/SmallComponents/Containers";
export const Textarea = styled.textarea`
  width: 100%;
  transition: 0.3s ease-out;
  padding: 10px;
  font-size: inherit;
`;

export const Contacto = () => {
  const [contactoInput, setContactoInput] = useState({});
  const { loading, error } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function handleSubmit(e) {
    e.preventDefault();
    //CALL BACKEND TO SEND EMAIL, loading we can use the gral one, success we just dispatch a notification
    await authAPI.contactSeller(dispatch, contactoInput);
    //poner boton de volver a atras e ir a inicio, seguir de compras, etc
  }

  return (
    <ContainerGral1500>
      <Form onSubmit={handleSubmit}>
        <ErrorNotification show={error}>{error}</ErrorNotification>
        <Title>FORMULARIO CONTACTO</Title>
        {contactoInputs.map((i) => {
          const { id, label } = i;
          return (
            <InputControl key={id}>
              <Label htmlFor={id}>{label}</Label>
              <Input
                as="input"
                id={id}
                placeholder={label}
                name={id}
                value={contactoInput[id]}
                onChange={(e) =>
                  setContactoInput({
                    ...contactoInput,
                    [e.target.name]: e.target.value,
                  })
                }
              ></Input>
            </InputControl>
          );
        })}
        <InputControl>
          <Label htmlFor="asunto">Asunto *</Label>
          <Textarea
            rows={4}
            value={contactoInput.asunto}
            onChange={(e) =>
              setContactoInput({
                ...contactoInput,
                asunto: e.target.value,
              })
            }
          ></Textarea>
        </InputControl>
        <InputControl>
          <Submit
            as="input"
            type="submit"
            value={loading ? "Enviando mensaje..." : "Enviar mensaje"}
            disabled={loading}
          />
        </InputControl>

        <InputControl style={{ letterSpacing: "2px" }}>
          <br />
          <em>Envianos tu consulta y a la brevedad te vamos a responder.</em>
        </InputControl>
        <Bottom>
          <AnimatedLink as={"div"} onClick={() => navigate(-1)}>
            Volver
          </AnimatedLink>{" "}
          <AnimatedLink
            to={FRONTEND_ENDPOINTS.HOME}
            c="var(--mainGreen)"
            ch="var(--mainGreen)"
          >
            Inicio
          </AnimatedLink>
        </Bottom>
      </Form>
    </ContainerGral1500>
  );
};
