import styled from "styled-components";
import { AnimatedLink, BtnLinked } from "components/styles/smallComponents";
import { FRONTEND_ENDPOINTS } from "constants";
import { HASH_LINKS } from "constants";
import { HashLink } from "react-router-hash-link";

export const SecondBox = styled.div`
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 5px;
  padding: 10px;
`;
export function TextConfirmed() {
  return (
    <SecondBox>
      Asimismo, en
      <AnimatedLink
        to={`${FRONTEND_ENDPOINTS.USER_PROFILE}#${HASH_LINKS.TOP}`}
        c={(props) => props.theme.blue}
        ch={(props) => props.theme.blue}
      >
        tu perfil
      </AnimatedLink>{" "}
      podrás ver y editar los detalles de tu compra (podés cambiar la dirección
      de envío, editar el mensaje personalizado para el vendedor, o la forma de
      pago, etc.).
    </SecondBox>
  );
}
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding-left: 10px;
    align-items: flex-start;
    gap: 20px;
  }
`;
const KeepBuying = styled(BtnLinked)`
  min-width: 200px;
  font-size: inherit;
`;
const Contact = styled.div``;
export const LinkMe = styled(HashLink)`
  color: ${(props) => props.theme.blue};
  font-weight: 600;
`;
export function BottomTextConfirmed() {
  return (
    <Bottom>
      <KeepBuying
        bc={(props) => props.theme.blue}
        border={(props) => props.theme.blue}
        to={`${FRONTEND_ENDPOINTS.HOME}#${HASH_LINKS.PROD}`}
      >
        Seguir comprando
      </KeepBuying>
      <Contact>
        ¿Necesitás ayuda?{" "}
        <LinkMe to={`${FRONTEND_ENDPOINTS.CONTACT}#${HASH_LINKS.TOP}`}>
          Ponete en contacto con nosotros
        </LinkMe>
      </Contact>
    </Bottom>
  );
}
