import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { showOrCloseNotification } from "context/generalSlice";

export const ContainerNot = styled.div`
  z-index: 1000;
  max-width: 300px;
  position: fixed;
  left: 15%;
  right: 0;
  bottom: 5%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  transform: ${(props) =>
    props.show ? "translateX(0)" : "translateX(-1000vw)"};
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  border: 1px solid ${(props) => props.theme.border};
  @media (max-width: 500px) {
    max-width: calc(100% - 20px);
    left: 10px;
    bottom: 30vh;
  }
`;
export const ReactIconClose = styled.div`
  border-radius: 50%;
  cursor: pointer;
  font-size: var(--fontSmall);
  padding: 10px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => props.theme.secondaryText};
  }
`;
export const Texto = styled.div`
  flex: 1;
  font-size: var(--fontSmall);
`;
export const Notification = () => {
  const { notification } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  return (
    <ContainerNot show={notification}>
      <Texto>{notification}</Texto>
      <ReactIconClose
        onClick={() => {
          dispatch(showOrCloseNotification({ show: false }));
        }}
      >
        <AiOutlineClose />
      </ReactIconClose>
    </ContainerNot>
  );
};
