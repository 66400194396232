import { FRONTEND_ENDPOINTS } from "constants";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const CheckUser = ({ admin }) => {
  /*   console.log("check user, POS check storage"); */
  const location = useLocation();
  /*   console.log(location, "LOCATION VER Q SALE"); */
  const { user } = useSelector((state) => state.user);

  return !user ? (
    <Navigate
      to={`${FRONTEND_ENDPOINTS.LOGIN}#`}
      state={{ from: location }}
      replace
    />
  ) : user.admin === admin || user.admin ? (
    <Outlet />
  ) : (
    <Navigate to={`${FRONTEND_ENDPOINTS.USER_PROFILE}#`} />
  );
};
