import React from "react";
import { useSelector } from "react-redux";
import { SingleCartItem } from "components/Single/SingleCartItem";
import styled from "styled-components";
import { Total } from "components/Carrito/Total";
import { AnimatedLink, ScrollStyles } from "components/styles/smallComponents";
import { HASH_LINKS } from "constants";
import { FRONTEND_ENDPOINTS } from "constants";
import { ContainerGral1500 } from "components/SmallComponents/Containers";

const SeccionCarrito = styled.section`
  @media (min-width: 1170px) {
    padding-top: 40px;
    display: flex;
    gap: 10px;
  }
`;
const Izq = styled.div`
  flex: 1;
`;
const TitleCarrito = styled.h1`
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;
const Dcha = styled.div`
  min-width: 450px;
  @media (max-width: 450px) {
    min-width: 0;
  }
`;
const ScrollContainer = styled.div`
  ${ScrollStyles}
  max-height: 70vh;
  position: relative;
  @media (max-width: 450px) {
    height: 60vh;
  }
`;
const TableItemsCarrito = styled.table`
  width: 100%;
`;
const TableContent = styled.tbody``;
/*-------------TABLE HEADER--------------------*/
const TableHeader = styled.thead`
  font-size: var(--fonMed);
  position: sticky;
  top: 0;
  z-index: 300;
  background-color: ${(props) => props.theme.body};
  line-height: 2rem;
`;

const MainHeader = styled.th``;
const FlexIt = styled.div`
  display: flex;
  gap: 10px;
`;
const UnidadHeader = styled.div`
  flex: 1;
`;
const CantidadHeader = styled.div`
  text-align: center;
  /* esto esta ligado a SingleCartItem width OJO */
  width: 120px;
  @media (max-width: 700px) {
    display: none;
  }
`;
const TotalHeader = styled.th`
  width: 100px;
  text-align: center;
`;
const DeleteHeader = styled.th`
  width: 35px;
`;
/*---------------------------------------------*/
const NoItems = styled.tr`
  font-size: var(--fontSmall);
`;

export const Carrito = () => {
  const { carrito } = useSelector((state) => state.user);
  return (
    <ContainerGral1500>
      <SeccionCarrito>
        <Izq>
          <TitleCarrito className="header">Carrito</TitleCarrito>
          <ScrollContainer>
            <TableItemsCarrito>
              {carrito.length > 0 ? (
                <>
                  <TableHeader>
                    <tr>
                      <MainHeader>
                        <FlexIt>
                          <UnidadHeader>Producto/unidad</UnidadHeader>
                          <CantidadHeader>Cantidad</CantidadHeader>
                        </FlexIt>
                      </MainHeader>

                      <TotalHeader>Total</TotalHeader>
                      <DeleteHeader />
                    </tr>
                  </TableHeader>
                  <TableContent>
                    {carrito.map((i, ind) => (
                      <SingleCartItem {...i} key={ind} />
                    ))}
                  </TableContent>
                </>
              ) : (
                <tbody>
                  <NoItems>
                    <td style={{ padding: "20px 0" }}>
                      No hay items en tu carrito aún.
                      <AnimatedLink
                        to={`${FRONTEND_ENDPOINTS.HOME}#${HASH_LINKS.PROD}`}
                        c="var(--mainBlue)"
                        ch="var(--mainBlueHover)"
                      >
                        Mirá nuestro productos.
                      </AnimatedLink>
                    </td>
                  </NoItems>
                </tbody>
              )}
            </TableItemsCarrito>
          </ScrollContainer>
        </Izq>
        <Dcha>{carrito.length > 0 && <Total carrito={carrito} />}</Dcha>
      </SeccionCarrito>
    </ContainerGral1500>
  );
};
