export const tableColumns = [
  "" /* multigroupEdit Cbox */,
  "NOMBRE",
  "MARCA",
  "SEXO",
  "" /* functionality controllers */,
];
export const subtableColumnsEsp = [
  "" /* multigroupEdit Cbox */,
  "PROMO",
  "TAMAÑO",
  "PRECIO",
  "STOCK",
  "" /* functionality controllers */,
];

export const createProdInputs = [
  { type: "text", id: "nombre", label: "Nombre producto" },
  {
    id: "sexo",
    label: "Seleccionar sexo",
    option: ["Femenino", "Masculino", "Unisex"],
  },
  { type: "file", id: "img", label: "Cargar imagen" },
];
