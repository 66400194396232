import { WidthStyles } from "components/SmallComponents/Containers";
import { Line } from "pages/admin/auxiliaries/OverlayStyledComp";
import React, { useRef } from "react";
import styled from "styled-components";
import { Control } from "../Carousel/Carousel";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { moverDcha, moverIzq } from "utils/sliderCarousel";
import useApiCall from "hooks/useApiCall";
import { productosAPI } from "API/productos";
import { SingleDestacado } from "components/Single/SingleDestacado";

//https://stackoverflow.com/questions/54724693/horizontal-scroll-with-css-grid

const Header = styled.h2`
  font-size: var(--fontLarge);
  font-weight: 500;
  text-align: center;
  letter-spacing: 5px;
`;

const SlideShow = styled.section`
  display: grid;

  //si no ponía esto, cuando hay un solo item en los resultados se estira full-height ya q los grid children tienen align-self:stretch. Intenté corregirlos con align-self start, y funca, pero paso a tener un 2do problema, cuando hay varios items estan asimetricos en la height y queda feo
  grid-template-rows: min-content;

  grid-auto-flow: column;

  /* clave sacarle el padding creo, q desvirtua todo */
  padding: 0px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-columns: minmax(250px, 1fr);

  /* --------------MINI CARDS EN MODO RESPONSIVE, sin padding ni gap, apelmazadas------------- */

  gap: initial;
`;

const ContainerCarousel = styled.div`
  position: relative;
  overflow: hidden;
`;

const ContainerGeneral = styled.section`
  ${WidthStyles}
  padding:10px;
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const Destacados = () => {
  const slideShow = useRef();

  const {
    data: { productos: prodDestacados = [] },
  } = useApiCall({
    customApiCall: productosAPI.getDestacadoSection,
  });

  return prodDestacados.length > 0 ? (
    <ContainerGeneral>
      <Header>Destacados</Header>
      <Line />
      <br />
      <ContainerCarousel>
        <SlideShow ref={slideShow}>
          {prodDestacados.map((i) => (
            <SingleDestacado {...i} key={i._id} />
          ))}
        </SlideShow>
        <Control onClick={() => moverIzq(slideShow)}>
          {" "}
          <AiOutlineArrowLeft />
        </Control>
        <Control dcha onClick={() => moverDcha(slideShow)}>
          <AiOutlineArrowRight />
        </Control>
      </ContainerCarousel>
    </ContainerGeneral>
  ) : null;
};

export default Destacados;
